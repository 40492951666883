import { CloseOutlined } from "@ant-design/icons";
import { Box, DialogTitle, Grid, IconButton, Modal, Typography, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useAuth from "hooks/useAuth";
import ClientDetailsViewModel from "pages/clients/model/ClientDetailsViewModel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export default({model, clientId, onClose, open, item}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const [t, i18n] = useTranslation();
    const theme = useTheme();
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        overflowY: 'scroll',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getFieldAndDirFilter(200, "number", "asc"));

    useEffect(() => {
        if(data && item) {
          setLoaded(false);
          model.fetchClientSessionLogs(dispatcher, clientId, item.sessionId, filter).unwrap().then(res => {
            setLoaded(true);
          });
        }
      }, [data, dispatcher, clientId, item, filter]);
    if(!data) {
        return "";
    }
    const logsData = useSelector(state => state.client.clientSessionLogs);
    if(!logsData) {
      return "";
    }
    return(
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ borderBottom: `0px solid ${theme.palette.divider}` }}>
                    <Grid item>
                        <DialogTitle>{t('session_logs')}</DialogTitle>
                    </Grid>
                    <Grid item sx={{ mr: 1.5 }}>
                    <IconButton color="secondary" onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                    </Grid>
                </Grid>
                <Box sx={{p: 2}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell sx={{width: '5%'}}>#</TableCell>
                            <TableCell sx={{width: '20%'}}>{t('id')}</TableCell>
                            <TableCell>{t('log')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logsData?.data?.items?.map(i => {
                                return (
                                    <TableRow key={'log'+i.id}>
                                        <TableCell sx={{verticalAlign: 'top'}}>{i.number}</TableCell>
                                        <TableCell sx={{verticalAlign: 'top'}}>{i.id}</TableCell>
                                        <TableCell sx={{verticalAlign: 'top', width: '75%'}}>
                                            <Box sx={{width: '100%', height: '300px', overflowX: 'hidden', overflowY: 'scroll', m: 0, p: 0}}>{i.log}</Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                            }
                            {logsData && logsData.data?.items?.length == 0 && 
                            <TableRow key={'logs_empty'}>
                                <TableCell colSpan={4}>{t('no_data')}</TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </Modal>
    );
}