import { Chip } from "@mui/material";

class StringUtilsC {

    stringSplitter(myString, chunkSize) {
        let splitString = [];
        for (let i = 0; i < myString.length; i = i + chunkSize) {
            splitString.push(myString.slice(i, i + chunkSize));
        }
        return splitString;
    }

    isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    formatDate(dt, options) {
        if(!dt) return "-";
        var date = new Date(dt);
        return date.toLocaleString('hu-HU', options);
    }

    clientStatus(t, status) {
        let statusText = "-";
        if(status == "NEW") {
            statusText = t('status_new');
        }
        else if(status == "SOCIAL") {
            statusText = t('status_social');
        }
        else if(status == "LINK_GENERATED") {
            statusText = t('status_link_generated');
        }
        else if(status == "PORTAL_OPENED") {
            statusText = t('status_portal_opened');
        }
        else if(status == "CONSENT_ADDED") {
            statusText = t('status_consent_added');
        }
        else if(status == "IN_PROGRESS") {
            statusText = t('status_in_progress');
        }
        else if(status == "ERROR") {
            statusText = t('status_error');
        }
        else if(status == "FINISHED") {
            statusText = t('status_finished');
        }
        else if(status == "CLOSED") {
            statusText = t('status_closed');
        }
        return statusText;
    }

    clientStatusChip(t, status, adminComment, onClick) {
        if(adminComment == "status_client_reason_did_not_want_consent") {
            adminComment = t(adminComment);
        }
        else if(adminComment == "status_client_reason_could_not_give_consent_missing_login_data") {
            adminComment = t(adminComment);
        }
        else if(adminComment == "status_client_reason_could_not_give_consent_bank_error") {
            adminComment = t(adminComment);
        }
        else if(adminComment == "status_client_reason_did_not_want_contract") {
            adminComment = t(adminComment);
        }
        let statusTitleClosed = t('status_closed_desc');
        if(status == "CLOSED" && adminComment) {
            statusTitleClosed = t('status_closed_desc')+": "+adminComment;
        }
        let statusTitleFinished = t('status_finished_desc');
        if(status == "FINISHED" && adminComment) {
            statusTitleFinished = t('status_finished_desc')+": "+adminComment;
        }
        switch (status) {
            case 'CLOSED':
                return <Chip color="secondary" label={t('status_closed')} size="small" variant="light" title={statusTitleClosed} onClick={onClick} />;
            case 'ERROR':
                return <Chip color="error" label={t('status_error')} size="small" variant="light" title={t('status_error_desc')} onClick={onClick} />;
            case 'FINISHED':
                return <Chip color="secondary" label={t('status_finished')} size="small" variant="light" title={statusTitleFinished} onClick={onClick} />;
            case 'APPROVED':
                return <Chip color="success" label={t('status_approved')} size="small" variant="light" title={t('status_approved_desc')} onClick={onClick} />;
            case 'REJECTED':
                return <Chip color="error" label={t('status_rejected')} size="small" variant="light" title={t('status_rejected_desc')} onClick={onClick} />;
            case 'IN_PROGRESS':
                return <Chip color="info" label={t('status_in_progress')} size="small" variant="light" title={t('status_in_progress_desc')} onClick={onClick} />;
            case 'CONSENT_ADDED':
                return <Chip color="warning" label={t('status_consent_added')} size="small" variant="light" title={t('status_consent_added_desc')} onClick={onClick} />;
            case 'PORTAL_OPENED':
                return <Chip color="warning" label={t('status_portal_opened')} size="small" variant="light" title={t('status_portal_opened_desc')} onClick={onClick} />;
            case 'LINK_GENERATED':
                return <Chip color="info" label={t('status_link_generated')} size="small" variant="light" title={t('status_link_generated_desc')} onClick={onClick} />;
            case 'SOCIAL':
                return <Chip color="warning" label={t('status_social')} size="small" variant="light" title={t('status_social_desc')} onClick={onClick} />;
            case 'NEW':
                return <Chip color="primary" label={t('status_new')} size="small" variant="light" title={t('status_new_desc')} onClick={onClick} />;
            default:
                return <Chip color="primary" label={t('status_new')} size="small" variant="light" onClick={onClick} />;
        }
    }

    consentStatus(t, status) {
        let statusText = "-";
        if(status == "APPROVED") {
            return <Chip color="success" label={t('status_approved')} size="small" variant="light" />;
        }
        else if(status == "REQUIRED") {
            return <Chip color="error" label={t('status_required')} size="small" variant="light" />;
        }
        else if(status == "AUTH_ERROR") {
            return <Chip color="error" label={t('status_auth_error')} size="small" variant="light" />;
        }
        else if(status == "PENDING") {
            return <Chip color="primary" label={t('status_pending')} size="small" variant="light" />;
        }
        return statusText;
    }

    scanStatus(t, status) {
        let statusText = "-";
        if(status == "NEW") {
            return <Chip color="secondary" label={t('status_new_scan')} size="small" variant="light" />;
        }
        else if(status == "UPLOADED") {
            return <Chip color="primary" label={t('status_uploaded_scan')} size="small" variant="light" />;
        }
        else if(status == "CONVERTED") {
            return <Chip color="primary" label={t('status_converted_scan')} size="small" variant="light" />;
        }
        else if(status == "PROCESSED") {
            return <Chip color="success" label={t('status_processed_scan')} size="small" variant="light" />;
        }
        else if(status == "DELETED") {
            return <Chip color="error" label={t('status_deleted_scan')} size="small" variant="light" />;
        }
        return statusText;
    }

    scanUploadStatus(t, status) {
        let statusText = "-";
        if(status == "UPLOADED_OK") {
            return <Chip color="success" label={t('status_scan_uploaded_ok')} size="small" variant="light" />;
        }
        else if(status == "UPLOADED_ERROR") {
            return <Chip color="error" label={t('status_scan_uploaded_error')} size="small" variant="light" />;
        }
        return statusText;
    }

    scanConversionStatus(t, status) {
        let statusText = "-";
        if(status == "NONE") {
            return <Chip color="primary" label={t('status_scan_converted_none')} size="small" variant="light" />;
        }
        else if(status == "CONVERTED_OK") {
            return <Chip color="success" label={t('status_scan_converted_ok')} size="small" variant="light" />;
        }
        else if(status == "CONVERTED_ERROR") {
            return <Chip color="error" label={t('status_scan_converted_error')} size="small" variant="light" />;
        }
        else if(status == "CONVERTED_WARN") {
            return <Chip color="secondary" label={t('status_scan_converted_warn')} size="small" variant="light" />;
        }
        return statusText;
    }

    scanProcessedStatus(t, status) {
        let statusText = "-";
        if(status == "NONE") {
            return <Chip color="primary" label={t('status_scan_processed_none')} size="small" variant="light" />;
        }
        else if(status == "PROCESSED_OK") {
            return <Chip color="success" label={t('status_scan_processed_ok')} size="small" variant="light" />;
        }
        else if(status == "PROCESSED_ERROR") {
            return <Chip color="error" label={t('status_scan_processed_error')} size="small" variant="light" />;
        }
        else if(status == "PROCESSED_WARN") {
            return <Chip color="secondary" label={t('status_scan_processed_warn')} size="small" variant="light" />;
        }
        return statusText;
    }

    transactionCategoryChip(t, category) {
        switch(category) {
            default:
            case "DEBIT":
                return <Chip color="error" label={t('transaction_category_debit')} size="small" variant="light" />;
            case "CREDIT":
                return <Chip color="success" label={t('transaction_category_credit')} size="small" variant="light" />;
        }
    }

    transactionTypeChip(t, type) {
        switch(type) {
            default:
                return <Chip color="secondary" label={t('transaction_type_'+type.toLowerCase())} size="small" variant="light" />;
            case "TRANSFER":
                return <Chip color="success" label={t('transaction_type_'+type.toLowerCase())} size="small" variant="light" />;
            case "CARD":
                return <Chip color="primary" label={t('transaction_type_'+type.toLowerCase())} size="small" variant="light" />;
        }
    }

    convertFileSize(t, fileSizeBytes) {
        let fileSize = fileSizeBytes / 1024;
        if(fileSize > 1024) {
            fileSize = fileSize / 1024;
            fileSize = fileSize.toFixed(2);
            fileSize += ' MB';
        }
        else {
            fileSize = fileSize.toFixed(2);
            fileSize += ' kB';
        }
        return fileSize;
    }

    languageText(t, data) {
        let language = data;
        if(data == "en") {
            language = t('lang_en');
        }
        else if(data == "cz") {
            language = t('lang_cz');
        }
        else if(data == "hr") {
            language = t('lang_hr');
        }
        else if(data == "hu") {
            language = t('lang_hu');
        }
        return language;
    }

    clientTypeText(t, data) {
        if(!data) return "-";
        return (data && data == "PERSONAL") ? t('client_type_personal') : t('client_type_business');
    }

    getRiskRating(t, scoreClass) {
        if(!scoreClass) {
            return "-";
        }
        let parsed = parseInt(scoreClass);
        let color = "success";
        let val = "-";
        if(parsed >= 5) {
            val = t('client_risk_level_excellent')+" ("+parsed+")";
        }
        else if(parsed >= 4) {
            val = t('client_risk_level_safe')+" ("+parsed+")";
        }
        else if(parsed >= 3) {
            val = t('client_risk_level_medium')+" ("+parsed+")";
            color = "warning";
        }
        else if(parsed >= 2) {
            val = t('client_risk_level_low')+" ("+parsed+")";
            color = "warning";
        }
        else {
            val = t('client_risk_level_risky')+" ("+parsed+")";
            color = "error";
        }
        return <Chip color={color} label={val} size="small" variant="light" />
    }

    getRiskRatingString(t, scoreClass) {
        if(!scoreClass) {
            return "-";
        }
        let parsed = parseInt(scoreClass);
        let val = "-";
        if(parsed >= 5) {
            val = t('client_risk_level_excellent')+" ("+parsed+")";
        }
        else if(parsed >= 4) {
            val = t('client_risk_level_safe')+" ("+parsed+")";
        }
        else if(parsed >= 3) {
            val = t('client_risk_level_medium')+" ("+parsed+")";
        }
        else if(parsed >= 2) {
            val = t('client_risk_level_low')+" ("+parsed+")";
        }
        else {
            val = t('client_risk_level_risky')+" ("+parsed+")";
        }
        return val;
    }

    getCurrencyShortName(value, currencyCode) {
        if(!currencyCode) {
            return "";
        }
        if(currencyCode == "HUF") {
            return value+" Ft";
        }
        else if(currencyCode == "EUR") {
            return value+" €";
        }
        else if(currencyCode == "USD") {
            return "$"+value;
        }
        else if(currencyCode == "CZK") {
            return value+" Kč";
        }
    }

    getCurrencyShortNameFromCode(currencyCode) {
        if(!currencyCode) {
            return "";
        }
        if(currencyCode == "HUF") {
            return "Ft";
        }
        else if(currencyCode == "EUR") {
            return "€";
        }
        else if(currencyCode == "USD") {
            return "$";
        }
        else if(currencyCode == "CZK") {
            return "Kč";
        }
    }

}

const StringUtils = new StringUtilsC();

export {
    StringUtils
}