import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({model, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    if(!chartData || !chartData.data) {
      return "";
    }
    //Question 3
    const smallestMonthlyCreditAmount = chartData.data.score.smallestMonthlyCreditAmount;
    const smallestMonthlyCreditToAllCreditRatio = chartData.data.score.smallestMonthlyCreditToAllCreditRatio;
    const smallestMonthlyCreditScore = chartData.data.score.smallestMonthlyCreditScore;
    //Question 6
    const totalDebitToCreditRatio = chartData.data.score.totalDebitToCreditRatio;
    const totalDebitToCreditScore = chartData.data.score.totalDebitToCreditScore;
    //Question 7
    const numOfPositiveBalances = chartData.data.score.numOfPositiveBalances;
    const positiveBalancesScore = chartData.data.score.positiveBalancesScore;
    //Question 8
    const monthlyPaymentRatioPercentage = chartData.data.score.monthlyPaymentRatioPercentage;
    const monthlyPaymentBaselineAmount = chartData.data.score.monthlyPaymentBaselineAmount;
    const monthlyPaymentBaselineCurrency = chartData.data.score.monthlyPaymentBaselineCurrency;
    const monthlyPaymentRatioScore = chartData.data.score.monthlyPaymentRatioScore;
    //Question 1 - Debit
    const smallestMonthlyDebitAmount = chartData.data.score.smallestMonthlyDebitAmount;
    const smallestMonthlyDebitToAllDebitRatio = chartData.data.score.smallestMonthlyDebitToAllDebitRatio;
    const smallestMonthlyDebitScore = chartData.data.score.smallestMonthlyDebitScore;
    //Question 5
    const numOfNavTransfers = chartData.data.score.numOfNavTransfers;
    const navTransferScore = chartData.data.score.navTransferScore;
    //Question 6
    const numOfBadNavTransfers = chartData.data.score.numOfBadNavTransactions;
    const badNavTransferScore = chartData.data.score.navBadScore;
    //Other
    const scoreCalculatorClass = chartData.data.score.scoreCalculatorClass;
    return(
        <MainCard title="Scoring (Business)" contentSX={{padding: 0}}>
                <Table>
                    <TableBody>
                    <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">Beállítások</Typography>
                    </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Használt score osztály</TableCell>
                        <TableCell>{model.dataOrEmpty(scoreCalculatorClass)}</TableCell>
                    </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                        <Typography variant="h5">Question 1</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell id="smallestMonthlyCreditAmount">Havonta bejövők legkisebb összeg</TableCell>
                        <TableCell>{model.formatCurrency(smallestMonthlyCreditAmount, personalData?.currency)}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell id="smallestMonthlyCreditToAllCreditRatio">Legkisebb havi bejövő és összes bejövő aránya</TableCell>
                        <TableCell>{smallestMonthlyCreditToAllCreditRatio}%</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell id="smallestMonthlyCreditScore">Legkisebb havi bejövő és összes bejövő aránya score</TableCell>
                        <TableCell>{smallestMonthlyCreditScore}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell id="smallestMonthlyDebitAmount">Havonta kimenők legkisebb összeg</TableCell>
                        <TableCell>{model.formatCurrency(smallestMonthlyDebitAmount, personalData?.currency)}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell id="smallestMonthlyDebitToAllDebitRatio">Legkisebb havi kimenő és összes kimenő aránya</TableCell>
                        <TableCell>{smallestMonthlyDebitToAllDebitRatio}%</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell id="smallestMonthlyDebitScore">Legkisebb havi kimenő és összes kimenő aránya score</TableCell>
                        <TableCell>{smallestMonthlyDebitScore}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2}>
                        <Typography variant="h5">Question 2</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Összes bejövő és összes kimenő arány (100% == csak bejövő, 0% == csak kimenő)</TableCell>
                        <TableCell>{totalDebitToCreditRatio}%</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Összes bejövő és összes kimenő arány score</TableCell>
                        <TableCell>{totalDebitToCreditScore}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2}>
                        <Typography variant="h5">Question 3</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Pozitív havi egyenlegek száma</TableCell>
                        <TableCell>{numOfPositiveBalances}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Pozitív havi egyenlegek száma score</TableCell>
                        <TableCell>{positiveBalancesScore}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={5}>
                        <Typography variant="h5">Question 4</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Net flow összeg osztva hárommal aránya</TableCell>
                        <TableCell>{monthlyPaymentRatioPercentage}%</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Havi törlesztő viszonyítási érték</TableCell>
                        <TableCell>{model.formatCurrency(monthlyPaymentBaselineAmount, personalData?.currency)}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>Havi törlesztési score</TableCell>
                        <TableCell>{monthlyPaymentRatioScore}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2}>
                        <Typography variant="h5">Question 5</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>NAV kimenő utalások száma</TableCell>
                        <TableCell>{numOfNavTransfers}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>NAV kimenő utalások score</TableCell>
                        <TableCell>{navTransferScore}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={2}>
                        <Typography variant="h5">Question 6</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>NAV &quot;rossz&quot; utalások száma</TableCell>
                        <TableCell>{numOfBadNavTransfers}</TableCell>
                      </TableRow>
                      <TableRow>    
                        <TableCell>NAV &quot;rossz&quot; utalások score</TableCell>
                        <TableCell>{badNavTransferScore}</TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </MainCard>
    );
}