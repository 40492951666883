import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const common = createSlice({
    name: 'common',
    initialState: {
        loading: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export default common.reducer;

export const { setLoading } = common.actions;