import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({id, title, model, transactions, personalData}) => {
    const [t, i18n] = useTranslation();
    return(
        <MainCard title={title} contentSX={{padding: 0}}>
            <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>{t('information')}</TableCell>
                    <TableCell sx={{width: '15%'}}>{t('count')}</TableCell>
                    <TableCell sx={{width: '15%'}}>{t('sum_amount')}</TableCell>
                    <TableCell sx={{width: '15%'}}>{t('average')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map(i => {
                    return (
                        <TableRow key={id+'_transaction_'+i.index}>
                            <TableCell>{model.dataOrEmpty(i.name)}</TableCell>
                            <TableCell>{i.count}</TableCell>
                            <TableCell>{model.formatCurrency(Math.abs(i.sum), personalData?.currency)}</TableCell>
                            <TableCell>{model.formatCurrency(Math.ceil(Math.abs(i.sum) / i.count), personalData?.currency)}</TableCell>
                        </TableRow>
                    )
                    })
                    }
                    {transactions && transactions.length == 0 &&
                    <TableRow key={id+'_empty'}>
                        <TableCell colSpan={4}>{t('no_data')}</TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </MainCard>
    );
}