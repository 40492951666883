import BaseViewModel from "./BaseViewModel";

class TableViewModel extends BaseViewModel {

    constructor(userModel) {
        super(userModel);
    }

}

export default TableViewModel;