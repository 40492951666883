import { Grid, Stack, Typography } from "@mui/material";

export default({title, data, xs = 12, md = 12, addParent = false, sx}) => {
    return(
        <Grid item xs={xs} md={md} sx={sx}>
            <Stack spacing={0.5}>
                <Typography color="secondary">{title}</Typography>
                <Typography>{data}</Typography>
            </Stack>
        </Grid>
    );
}