import { EyeOutlined } from "@ant-design/icons";
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default({model, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    const [showState, setShowState] = useState(false);
    if(!chartData) {
        return "";
    }
    let hasAssessmentData = true;
    if(!chartData.data) {
        hasAssessmentData = false;
    }
    let hasSocioData = true;
    if(!chartData.socioScoreData) {
        hasSocioData = false;
    }
    //Question 1
    const totalCreditTransactionAmount = chartData.data?.score?.totalCreditTransactionAmount;
    const creditTransactionAmountScore = chartData.data?.score?.creditTransactionAmountScore;
    //Question 2
    const topCreditToAllCreditRatio = chartData.data?.score?.topCreditToAllCreditRatio;
    const creditToAllCreditRatioScore = chartData.data?.score?.creditToAllCreditRatioScore;
    //Question 3
    const smallestMonthlyCreditAmount = chartData.data?.score?.smallestMonthlyCreditAmount;
    const smallestMonthlyCreditToAllCreditRatio = chartData.data?.score?.smallestMonthlyCreditToAllCreditRatio;
    const smallestMonthlyCreditScore = chartData.data?.score?.smallestMonthlyCreditScore;
    //Question 4
    const biggestDebtorTransactionNum = chartData.data?.score?.biggestDebtorTransactionNum;
    const biggestDebtorAccountNumber = chartData.data?.score?.biggestDebtorAccountNumber;
    const biggestDebtorName = chartData.data?.score?.biggestDebtorName;
    const biggestDebtorTransactionNumScore = chartData.data?.score?.biggestDebtorTransactionNumScore;
    //Question 5
    const mainCreditorAccount = chartData.data?.score?.mainCreditorAccount;
    const mainCreditorName = chartData.data?.score?.mainCreditorName;
    const mainCreditorRatio = chartData.data?.score?.mainCreditorRatio;
    const mainCreditorScore = chartData.data?.score?.mainCreditorScore;
    //Question 6
    const totalDebitToCreditRatio = chartData.data?.score?.totalDebitToCreditRatio;
    const totalDebitToCreditScore = chartData.data?.score?.totalDebitToCreditScore;
    //Question 7
    const numOfPositiveBalances = chartData.data?.score?.numOfPositiveBalances;
    const positiveBalancesScore = chartData.data?.score?.positiveBalancesScore;
    //Question 8
    const monthlyPaymentRatioPercentage = chartData.data?.score?.monthlyPaymentRatioPercentage;
    const monthlyPaymentBaselineAmount = chartData.data?.score?.monthlyPaymentBaselineAmount;
    const monthlyPaymentBaselineCurrency = chartData.data?.score?.monthlyPaymentBaselineCurrency;
    const monthlyPaymentRatioScore = chartData.data?.score?.monthlyPaymentRatioScore;
    //Question 9
    const lastAvailableBalanceAmount = chartData.data?.score?.lastAvailableBalanceAmount;
    const lastBalanceScore = chartData.data?.score?.lastBalanceScore;
    //Question 10
    const fraudRiskPartners = chartData.data?.score?.fraudRiskPartners;
    const fraudRiskPartnerScore = chartData.data?.score?.fraudRiskPartnerScore;
    const buttonText = !showState? "Show" : "Hide";
    //Other
    const scoreCalculatorClass = chartData.data?.score?.scoreCalculatorClass;
    return(
        <MainCard title="Scoring" contentSX={{padding: 0}}>
            <Button onClick={() => setShowState(!showState)} sx={{marginTop: 2, marginLeft: 2}} variant="shadow" color="info" endIcon={<EyeOutlined />}>{buttonText}</Button>
            <Box sx={{display: showState ? 'block' : 'none'}}>
            <Table>
                <TableBody>
                    <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">Score komponensek</Typography>
                    </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell sx={{width: '50%'}}>Teljes score</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.scoreComponentsData?.finalScore)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Banking API score</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.scoreComponentsData?.bankingApiScore)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Banking API score súly</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.scoreComponentsData?.bankingApiScoreWeight)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Socio score</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.scoreComponentsData?.socioScore)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Socio score súly</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.scoreComponentsData?.socioScoreWeight)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Fraud score</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.scoreComponentsData?.fraudScore)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            {hasAssessmentData && 
            <Table>
                <TableBody>
                    <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">Beállítások</Typography>
                    </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell sx={{width: '50%'}}>Használt score osztály</TableCell>
                        <TableCell>{model.dataOrEmpty(scoreCalculatorClass)}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">Question 1</Typography>
                    </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="totalCreditTransactionAmount">Összes bejövő összeg</TableCell>
                        <TableCell>{model.formatCurrency(totalCreditTransactionAmount, personalData?.currency)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="creditTransactionAmountScore">Összes bejövő összeg score</TableCell>
                        <TableCell>{creditTransactionAmountScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography variant="h5">Question 2</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="topCreditToAllCreditRatio">4 legnagyobb bejövő aránya az összes bejövőhöz</TableCell>
                        <TableCell>{topCreditToAllCreditRatio}%</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="creditToAllCreditRatioScore">4 legnagyobb bejövő aránya score</TableCell>
                        <TableCell>{creditToAllCreditRatioScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={3}>
                            <Typography variant="h5">Question 3</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="smallestMonthlyCreditAmount">Havonta bejövők legkisebb összeg</TableCell>
                        <TableCell>{model.formatCurrency(smallestMonthlyCreditAmount, personalData?.currency)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="smallestMonthlyCreditToAllCreditRatio">Legkisebb havi bejövő és összes bejövő aránya</TableCell>
                        <TableCell>{smallestMonthlyCreditToAllCreditRatio}%</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="smallestMonthlyCreditScore">Legkisebb havi bejövő és összes bejövő aránya score</TableCell>
                        <TableCell>{smallestMonthlyCreditScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={3}>
                            <Typography variant="h5">Question 4</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="biggestDebtorTransactionNum">Legtöbbet küldő tranzakció szám</TableCell>
                        <TableCell>{biggestDebtorTransactionNum} db</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="biggestDebtorAccountNumber">Legtöbbet küldő számlaszáma (ha van)</TableCell>
                        <TableCell>{biggestDebtorAccountNumber}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="biggestDebtorName">Legtöbbet küldő neve (ha van)</TableCell>
                        <TableCell>{biggestDebtorName}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell id="smallestMonthlyCreditScore">Legtöbbet küldő score</TableCell>
                        <TableCell>{biggestDebtorTransactionNumScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={3}>
                            <Typography variant="h5">Question 5</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Leggyakoribb bejövő küldő számlaszám</TableCell>
                        <TableCell>{mainCreditorAccount}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Leggyakoribb bejövő küldő név</TableCell>
                        <TableCell>{mainCreditorName}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Leggyakoribb bejövő küldő és összes bejövő küldő aránya</TableCell>
                        <TableCell>{mainCreditorRatio}%</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Leggyakoribb bejövő küldő és összes bejövő küldő aránya score</TableCell>
                        <TableCell>{mainCreditorScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography variant="h5">Question 6</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Összes bejövő és összes kimenő arány (100% == csak bejövő, 0% == csak kimenő)</TableCell>
                        <TableCell>{totalDebitToCreditRatio}%</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Összes bejövő és összes kimenő arány score</TableCell>
                        <TableCell>{totalDebitToCreditScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography variant="h5">Question 7</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Pozitív havi egyenlegek száma</TableCell>
                        <TableCell>{numOfPositiveBalances}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Pozitív havi egyenlegek száma score</TableCell>
                        <TableCell>{positiveBalancesScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={5}>
                            <Typography variant="h5">Question 8</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Bejövő medián és kimenő átlag aránya</TableCell>
                        <TableCell>{monthlyPaymentRatioPercentage}%</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Havi törlesztő viszonyítási érték</TableCell>
                        <TableCell>{model.formatCurrency(monthlyPaymentBaselineAmount, personalData?.currency)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Havi törlesztési score</TableCell>
                        <TableCell>{monthlyPaymentRatioScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={3}>
                            <Typography variant="h5">Question 9</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Utolsó elérhető balance (minden számla, Forint)</TableCell>
                        <TableCell>{model.formatCurrency(lastAvailableBalanceAmount, personalData?.currency)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Utolsó elérhető balance score</TableCell>
                        <TableCell>{lastBalanceScore}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography variant="h5">Question 10</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Fraud gyanús tranzakciók score</TableCell>
                        <TableCell>{fraudRiskPartnerScore}</TableCell>
                    </TableRow>

                </TableBody>  
            </Table>
            }
            {hasSocioData && 
            <Table>
                <TableBody>
                    <TableRow>
                    <TableCell colSpan={2}>
                        <Typography variant="h5">Socio score</Typography>
                    </TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell sx={{width: '50%'}}>Teljes socio score</TableCell>
                        <TableCell>{chartData.socioScoreData?.totalScore}</TableCell>
                    </TableRow>

                    <TableRow>    
                        <TableCell>Jövedelem score</TableCell>
                        <TableCell>{chartData.socioScoreData?.incomeScore}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Jövedelem</TableCell>
                        <TableCell>{model.formatCurrency(chartData.socioScoreData?.netMonthlyIncome, chartData.socioScoreData?.originalCurrency)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Átváltott jövedelem</TableCell>
                        <TableCell>{model.formatCurrency(chartData.socioScoreData?.netMonthlyIncomeExchanged, chartData.socioScoreData?.exchangedCurrency)}</TableCell>
                    </TableRow>

                    <TableRow>    
                        <TableCell>Lakcím score</TableCell>
                        <TableCell>{chartData.socioScoreData?.addressScore}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>ZIP</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.zip)}</TableCell>
                    </TableRow>

                    <TableRow>    
                        <TableCell>Életkor score</TableCell>
                        <TableCell>{chartData.socioScoreData?.ageScore}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Születésnap</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.birthDate)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Életkor</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.age)}</TableCell>
                    </TableRow>

                    <TableRow>    
                        <TableCell>Iskolai végzettség score</TableCell>
                        <TableCell>{chartData.socioScoreData?.educationLevelScore}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Iskolai végzettség</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.educationLevel)}</TableCell>
                    </TableRow>

                    <TableRow>    
                        <TableCell>Családi állapot score</TableCell>
                        <TableCell>{chartData.socioScoreData?.familyStatusScore}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Családi állapot</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.familyStatus)}</TableCell>
                    </TableRow>

                    <TableRow>    
                        <TableCell>Havi jövedelem score</TableCell>
                        <TableCell>{chartData.socioScoreData?.balanceScore}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Kalkulált minimum havi jövedelem</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.householdMinIncome)}</TableCell>
                    </TableRow>
                    <TableRow>    
                        <TableCell>Kalkulált havi jövedelem</TableCell>
                        <TableCell>{model.dataOrEmpty(chartData.socioScoreData?.monthlyBalance)}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
            }
            </Box>
        </MainCard>
    );
}