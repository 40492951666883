import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({model, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    let netTotal = model.getNetTotal();
    return(
        <MainCard title={t('totals_period')} contentSX={{padding: 0}}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell sx={{width: '20%'}}>{t('net_total')}</TableCell>
                    <TableCell sx={{width: '20%'}}>{t('outflow')}</TableCell>
                    <TableCell sx={{width: '20%'}}>{t('inflow')}</TableCell>
                    <TableCell>{t('outflow_count')}</TableCell>
                    <TableCell>{t('inflow_count')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow key={'total_globals'}>
                    <TableCell>
                    {model.formatCurrency(netTotal, personalData?.currency)}
                    </TableCell>
                    <TableCell>
                    {model.formatCurrency(Math.abs(chartData?.data?.totals?.statistics?.totalDebitAmount), personalData?.currency)}
                    </TableCell>
                    <TableCell>
                    {model.formatCurrency(Math.abs(chartData?.data?.totals?.statistics?.totalCreditAmount), personalData?.currency)}
                    </TableCell>
                    <TableCell>
                    {chartData?.data?.totals?.statistics?.debitCount}
                    </TableCell>
                    <TableCell>
                    {chartData?.data?.totals?.statistics?.creditCount}
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </MainCard>        
    );
}