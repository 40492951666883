import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'axios';
import { forgetPassword, getDashboardConfiguration, getProfileBasic, loginAdmin } from '_api';
import { useTranslation } from 'react-i18next';
import { I18_LANGUAGE } from 'config/config';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [t, i18n] = useTranslation();
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const profile = await getProfileBasic({token: serviceToken});
          const user = profile?.data?.data;
          user.token = serviceToken;
          const configuration = await getDashboardConfiguration({token: serviceToken});
          user.configuration = configuration?.data?.data;
          const currentLanguage = i18n.language;
          const userLanguage = user?.language;
          if(currentLanguage != userLanguage) {
            i18n.changeLanguage(userLanguage);
            localStorage.setItem(I18_LANGUAGE, userLanguage);
          }
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await loginAdmin(email, password);
    const {success, data, messages} = response.data;
    if(!success) {
      throw new Error(messages?.[0]);
    }
    const profile = await getProfileBasic({token: data.access_token});
    const user = profile.data.data;
    user.token = data.access_token;
    user.scopes = data.scope.split(" ");
    const configuration = await getDashboardConfiguration({token: data.access_token});
    user.configuration = configuration?.data?.data;
    setSession(data.access_token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email, lang) => {
    if(lang != "en" && lang != "hu") {
      lang = "en";
    }
    const response = await forgetPassword(email, lang);
    const {success, data, messages} = response.data;
    if(!success) {
      throw new Error(messages?.[0]);
    }
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
