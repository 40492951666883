import { Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({model, personalData}) => {
    const [t, i18n] = useTranslation();
    return(
        <MainCard title={t('period_totals')} contentSX={{padding: 0}}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell sx={{width: '20%'}}>{t('period')}</TableCell>
                    <TableCell sx={{width: '20%'}}>{t('net_total')}</TableCell>
                    <TableCell sx={{width: '20%'}}>{t('outflow')}</TableCell>
                    <TableCell sx={{width: '20%'}}>{t('inflow')}</TableCell>
                    <TableCell>{t('outflow_count')}</TableCell>
                    <TableCell>{t('inflow_count')}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {model.getPeriodTotals().map(i => {
                    return(<TableRow key={'period_global_'+i.index}>
                    <TableCell>
                        <Stack direction="row">
                            <Chip variant='outlined' label={model.formatShorterDate(i.from)}/>
                            <Chip variant='outlined' sx={{marginLeft: 2}} label={model.formatShorterDate(i.to)}/>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        {model.formatCurrency(i.netTotal, personalData?.currency)}
                    </TableCell>
                    <TableCell>
                        {model.formatCurrency(i.debitAmount, personalData?.currency)}
                    </TableCell>
                    <TableCell>
                        {model.formatCurrency(i.creditAmount, personalData?.currency)}
                    </TableCell>
                    <TableCell>
                        {i.debitCount}
                    </TableCell>
                    <TableCell>
                        {i.creditCount}
                    </TableCell>
                    </TableRow>)
                })}
                
                </TableBody>
            </Table>
        </MainCard>        
    );
}