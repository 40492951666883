import { EyeOutlined } from "@ant-design/icons";
import { Box, Button, Grid, IconButton, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default({model, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    const [showState, setShowState] = useState(false);
    if(!chartData || !chartData.data || !chartData.data.incomeData) {
      return "";
    }
    const greenPairs = chartData.data.incomeData.greenPairs;
    const yellowPairs = chartData.data.incomeData.yellowPairs;
    const redTransactions = chartData.data.incomeData.redTransactions;
    const underLimitTransactions  = chartData.data.incomeData.underLimitTransactions;
    const getPairListItem = (item) => {
        if(!item) {
          return "-";
        }
        return (
          <List>
            <ListItem>
              {model.formatShortDate(item.originalTransactionDate)}
            </ListItem>
            <ListItem>
              {item.originalTransactionPsd2Name}
            </ListItem>
            <ListItem>
              {model.formatCurrency(item.amount, personalData?.currency)}
            </ListItem>
          </List>
        )
      }
      const getPairItem = (item, index, type) => {
        if(!item) {
          return "-";
        }
        return(
          <TableRow key={'transaction_income_'+type+"_"+index}>
            <TableCell>
              {model.formatShortDate(item.originalTransactionDate)}
            </TableCell>
            <TableCell>
              {item.originalTransactionPsd2Name}
            </TableCell>
            <TableCell>
              {model.formatCurrency(item.amount, personalData?.currency)}
            </TableCell>
          </TableRow>
        );
      }
    const buttonText = !showState? "Show" : "Hide";
    return(
        <MainCard title="Income analysis" contentSX={{padding: 0}}>
          <Button onClick={() => setShowState(!showState)} sx={{marginTop: 2, marginLeft: 2}} variant="shadow" color="info" endIcon={<EyeOutlined />}>{buttonText}</Button>
          <Box sx={{display: showState ? 'block' : 'none'}}>
          <Table sx={{marginTop: 2}}>
            <TableHead>
              <TableRow>
                <TableCell>Green pairs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {greenPairs?.map((i,k) => {
                let firstItem = getPairListItem(i[0]);
                let secItem = getPairListItem(i[1]);
                let thirdItem = getPairListItem(i[2]);
                return (
                  <TableRow key={'green_pair_'+k}>
                    <TableCell sx={{width: "30%"}}>
                      {firstItem}
                    </TableCell>
                    <TableCell sx={{width: "30%"}}>
                      {secItem}
                    </TableCell>
                    <TableCell sx={{width: "30%"}}>
                      {thirdItem}
                    </TableCell>
                  </TableRow>
                )
              })}
              {greenPairs?.length == 0 && 
                <TableRow key={'green_pairs_empty'}>
                  <TableCell>{t('no_data')}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Yellow pairs</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {yellowPairs?.map((i,k) => {
                let firstItem = getPairListItem(i[0]);
                let secItem = getPairListItem(i[1]);
                let thirdItem = getPairListItem(i[2]);
                return (
                  <TableRow key={'yellow_pair_'+k}>
                    <TableCell sx={{width: "30%"}}>
                      {firstItem}
                    </TableCell>
                    <TableCell sx={{width: "30%"}}>
                      {secItem}
                    </TableCell>
                    <TableCell sx={{width: "30%"}}>
                      {thirdItem}
                    </TableCell>
                  </TableRow>
                )
              })}
              {yellowPairs?.length == 0 && 
                <TableRow key={'yellow_pairs_empty'}>
                  <TableCell>{t('no_data')}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Unpaired transactions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {redTransactions.map((i, k) => {
                return getPairItem(i, k, "red")
              })}
              {redTransactions?.length == 0 && 
                <TableRow key={'red_pairs_empty'}>
                  <TableCell>{t('no_data')}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Under limit transactions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {underLimitTransactions.map((i, k) => {
                return getPairItem(i, k, "underlimit")
              })}
              {underLimitTransactions?.length == 0 && 
                <TableRow key={'under_limit_empty'}>
                  <TableCell>{t('no_data')}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </Box>
      </MainCard>        
    );
}