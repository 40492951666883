import { InfoCircleOutlined, MailOutlined, RightOutlined, SendOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Divider, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ClientDetailsViewModel from "../model/ClientDetailsViewModel";

export default({clientId, setSelectedTab, clientLink}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();
    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const model = new ClientDetailsViewModel(null, context.user);
    let tokenData = data?.data?.token;
    if(clientLink && clientLink.clientId === clientId) {
        tokenData = clientLink;
    }
    const personal = data?.data?.personal;
    const startProcess = () => {
        setSelectedTab("tab-bank-access");
    }
    const clientType = (data?.data?.personal?.clientType == "PERSONAL") ? t('client_type_personal') : t('client_type_business');
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={4} lg={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <MainCard>
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={2.5} alignItems="center">
                            <Stack spacing={0.5} alignItems="center">
                                <Avatar alt="Basic"><UserOutlined /></Avatar>
                                <Typography variant="h5">{t('risk_scoring')}</Typography>
                                <Typography color="secondary"></Typography>
                            </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-around" alignItems="center">
                            <Stack spacing={0.5} alignItems="center">
                                <Typography variant="h5">n.a.</Typography>
                                <Typography color="secondary">{t('score')}</Typography>
                            </Stack>
                            <Divider orientation="vertical" flexItem />
                            <Stack spacing={0.5} alignItems="center">
                                <Typography variant="h5">n.a.</Typography>
                                <Typography color="secondary">{t('risk')}</Typography>
                            </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <List component="nav" aria-label="main folders" sx={{ py: 0, '& .MuiListItem-root': { p: 0, py: 1 } }}>
                            <ListItem>
                                <ListItemIcon>
                                    <SettingOutlined />
                                </ListItemIcon>
                                <ListItemSecondaryAction sx={{left: '24px'}}>
                                    <Typography sx={{fontWeight: 'bold'}} color="primary">{clientType}</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                <UserOutlined />
                                </ListItemIcon>
                                <ListItemSecondaryAction sx={{left: '24px'}}>
                                <Typography>{model.limitData(personal?.lastName+' '+personal?.firstName, 32)}</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                <MailOutlined />
                                </ListItemIcon>
                                <ListItemSecondaryAction sx={{left: '24px'}}>
                                <Typography>{model.limitData(personal?.email, 32)}</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>
                            </List>
                        </Grid>
                        </Grid>
                        
                    </MainCard>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} sm={7} md={8} lg={9}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MainCard title={t('bank_access_info')} sx={{background: '#EDFFEF'}}>
                            <Stack direction="row" alignContent="center" alignItems="center">
                                <InfoCircleOutlined style={{ fontSize: '150%'}} />
                                {!tokenData && 
                                    <Typography sx={{marginLeft: 1}}>{t('go_to_bank_access')}</Typography>
                                }
                                {tokenData && 
                                    <Typography sx={{marginLeft: 1}}>{t('client_link_was_generated')}</Typography>
                                }
                            </Stack>
                            {!tokenData && 
                            <Grid container spacing={1.25}>
                                <Grid container spacing={0} direction="row" alignItems="right" sx={{marginTop: 4, marginLeft: 1}} justifyContent="flex-start">
                                    <Button variant="shadow" color="info" onClick={startProcess} endIcon={<RightOutlined />}>{t('go')}</Button>
                                </Grid>
                            </Grid>
                            }
                        </MainCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}