class ClientListPaginationData {

    static from(item) {
        return new ClientListPaginationData(item.page, item.limit, item.order, item.orderBy, item.search, item.listType, item.statusType);
    }

    constructor(page, limit, order, orderBy, search, listType, statusType) {
        this.page = page;
        this.limit = limit;
        this.order = order;
        this.orderBy = orderBy;
        this.search = search;
        this.reload = false;
        this.listType = listType;
        this.statusType = statusType;
    }

    getQueryData() {
        let url = "?" + "page=" + (this.page+1) + "&limit=" + this.limit + "&order=" + this.order + "&sortBy=" + this.orderBy;
        if(this.search) {
            url += "&search=" + this.search;
        }
        if(this.listType) {
            url += "&listType=" + this.listType;
        }
        if(this.statusType) {
            url += "&statusType=" + this.statusType;
        }
        return url;
    }

    getHeaderData() {
        return {
            page: this.page,
            limit: this.limit,
            search: this.search,
            order: this.order,
            orderBy: this.orderBy,
            listType: this.listType,
            statusType: this.statusType
        };
    }

}

export default ClientListPaginationData;