import { BellOutlined, ContainerOutlined, FileTextOutlined, LockOutlined, MailOutlined, SettingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Box, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import MainCard from "components/MainCard";
import useAuth from "hooks/useAuth";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, Outlet } from 'react-router-dom';
import ProfileViewModel from "./model/ProfileViewModel";

export default() => {
    const theme = useTheme();
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const profileData = useSelector(state => ProfileViewModel.loadFromStore(state));
    const model = new ProfileViewModel(profileData, context.user);
    useMemo(() => {
        model?.getData(dispatcher);
      }, [dispatcher]);
    const data = model.getProfileData();
    
    const [value, setValue] = useState(0);

    const { pathname } = useLocation();
    let selectedTab = 0;
    switch (pathname) {
        case '/profile/profile':
            selectedTab = 0;
        break;
        case '/profile/personal':
            selectedTab = 1;
        break;
        case '/profile/password':
            selectedTab = 2;
        break;
        case '/profile/communications':
            selectedTab = 3;
        break;
        case '/profile/events':
            selectedTab = 4;
        break;
        default:
            selectedTab = 0;
    }
    if(value != selectedTab) {
        setValue(selectedTab);
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    if(!data) {
        return "";
    }
    return (
        <Box>
          <Grid item sx={{ mt: 0.25, mb: 2 }}>
              <Typography variant="h2">{t('user_profile')}</Typography>
          </Grid>
          <MainCard boxShadow>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="account profile tab">
                    <Tab label={t('profile_basic')} component={Link} to="/profile/profile" icon={<UserOutlined />} iconPosition="start" />
                    <Tab label={t('profile_personal')} component={Link} to="/profile/personal" icon={<FileTextOutlined />} iconPosition="start" />
                    <Tab label={t('profile_change_password')} component={Link} to="/profile/password" icon={<LockOutlined />} iconPosition="start" />
                    <Tab label={t('profile_communications')} component={Link} to="/profile/communications" icon={<MailOutlined />} iconPosition="start" />
                    <Tab label={t('profile_events')} component={Link} to="/profile/events" icon={<BellOutlined />} iconPosition="start" />
                </Tabs>
            </Box>
            <Box sx={{ mt: 2.5 }}>
                <Outlet />
            </Box>
          </MainCard>
        </Box>
    );
}