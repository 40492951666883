import { LinkOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Grid, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default({model, link, tokenData, restartProcess}) => {
    const [t, i18n] = useTranslation();
    const seconds = useRef(0);
    const [expiringText, setExpiringText] = useState(t('expiring_at', {date: 'n.a.'}));
    const updateExpirationText = (model, date, expire) => {
        if(!date || !expire) return;
        let now = Date.now();
        let msDiff = now - date.getTime();
        let diffInSeconds = expire - (msDiff / 1000);
        let minutes = 0;
        let hours = 0;
        let seconds = 0;
        if(diffInSeconds >= 60) {
            minutes = parseInt(diffInSeconds / 60);
            seconds = Math.ceil(diffInSeconds - (minutes * 60));
        }
        if(minutes >= 60) {
            hours = parseInt(minutes / 60);
            minutes = Math.ceil(minutes - (hours * 60));
        }
        let time = model.addLeadingZero(hours)+":"+model.addLeadingZero(minutes)+":"+model.addLeadingZero(seconds);
        setExpiringText(t('expiring_at', {date: time}));
    }
    const onCalledInterval = (current) => {
        if(link) {
            if(link.expiration < 0) {
                setExpiringText(t('link_expired'));
            }
            else {
                updateExpirationText(model, new Date(link.timestamp), link.expiration);
            }
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            seconds.current = seconds.current + 1;
            onCalledInterval(seconds.current);
        }, 1000);
        return () => clearInterval(interval);
    });
    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard title={t('client_link')} sx={{background: '#EDFFEF'}}>
                    <Typography variant="body2" sx={{marginBottom: 2}}>{t('client_link_was_generated')}</Typography>
                    <Typography variant="body2" sx={{marginTop: 2, fontWeight: 'bold'}}>{expiringText}</Typography>
                    <Button variant="shadow" sx={{marginTop: 2}} color="info" onClick={restartProcess} endIcon={<RedoOutlined />}>{t('regenerate_link')}</Button>
                  </MainCard>
                </Grid>
            </Grid>
        </Grid>
    );
}