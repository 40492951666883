import { Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";

export default({id, label, placeholder = "", fieldProps = {}, touched = false, errors = null, xs = 12, sx, disabled = false}) => {
    return(
        <Grid item xs={xs} sx={sx}>
            <Stack spacing={1.25}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                {disabled && 
                    <TextField
                        fullWidth
                        id={id}
                        placeholder={placeholder}
                        {...fieldProps}
                        disabled={true}
                        error={Boolean(touched && errors)}
                        helperText={touched && errors}
                    />
                }
                {!disabled && 
                    <TextField
                        fullWidth
                        id={id}
                        placeholder={placeholder}
                        {...fieldProps}
                        error={Boolean(touched && errors)}
                        helperText={touched && errors}
                    />
                }
            </Stack>
        </Grid>
    );
}