import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({model, chartData}) => {
    const [t, i18n] = useTranslation();
    if(!chartData?.data?.accounts) {
        return "";
    }
    const accounts = chartData?.data?.accounts?.accounts;
    return(
        <MainCard title={t('bank_accounts')} contentSX={{padding: 0}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '20%'}}>
                            <Typography variant="div">{t('holder')}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="div">{t('account_number')}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="div">{t('currency')}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {accounts?.filter(i => i.accountNumber).map(i => {
                    return (
                        <TableRow key={'account_'+i.id}>
                            <TableCell>{model.limitData(i.accountHolderName, 32)}</TableCell>
                            <TableCell>{i.accountNumber}</TableCell>
                            <TableCell>{t('currency_long_'+i.currency.toLowerCase())}</TableCell>
                        </TableRow>
                    )
                    })
                    }
                </TableBody>
            </Table>
        </MainCard>
    );
}