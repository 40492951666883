import { SearchOutlined } from "@ant-design/icons";
import { OutlinedInput } from "@mui/material";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";

export default({title, handleSearch}) => {
    const [search, setSearch] = useState("");
    const onSearch =  useAsyncDebounce((value) => {
        handleSearch(value);
      }, 300);
    return(
        <OutlinedInput
            sx={{width: '30%'}}
            onChange={(e) => {
                setSearch(e.target.value);
                onSearch(e.target.value);
            }}
            value={search}
            placeholder={title}
            startAdornment={<SearchOutlined />}
        />
    );
}