import { Box, DialogContent, DialogTitle, Divider, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material"
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export default({model, clientId, data, type}) => {
    const theme = useTheme();
    const context = useAuth();
    const dispatcher = useDispatch();
    const [t, i18n] = useTranslation();
    const modalStyle = {
        overflowY: 'scroll',
    };
    let errorList = (type == "errors") ? data?.data[0].errors : data?.data[0].warnings;
    return(
        <Box sx={modalStyle}>
            <DialogTitle>{t('scan_logs')}</DialogTitle>
            <Divider />
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: '5%'}}>#</TableCell>
                            <TableCell>{t('log')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {errorList.map((i, j) => {
                        return (
                            <TableRow key={'log'+j}>
                                <TableCell>{j+1}</TableCell>
                                <TableCell>
                                {i}
                                </TableCell>
                            </TableRow>
                        )
                    })
                    }
                    {errorList && errorList.length == 0 && 
                    <TableRow key={'logs_empty'}>
                        <TableCell colSpan={2}>{t('no_data')}</TableCell>
                    </TableRow>
                    }
                    </TableBody>
                </Table>
            </DialogContent>
        </Box>
    );
}