import PaginationData from "model/dto/PaginationData";
import TableViewModel from "model/viewmodel/TableViewModel";
import { changePasswordRequest, getDashboardProfileRequest, getMyCommunicationsRequest, getMyEventsRequest, updateProfileRequest } from "store/reducers/dashboard";

class ProfileViewModel extends TableViewModel {

    static loadFromStore(state) {
        return state.dashboard.profile;
    }

    constructor(profileData, userModel) {
        super(userModel);
        this.data = profileData;
    }

    getData(dispatcher) {
        if(!this.data) {
            const request = {token: this.user.token, timestamp: Date.now()};
            return dispatcher(getDashboardProfileRequest(request));
        }
    }

    getProfileData() {
        return this.data?.data;
    }

    updateProfile(dispatcher, data) {
        return dispatcher(updateProfileRequest(data)).unwrap();
    }

    changePassword(dispatcher, data) {
        return dispatcher(changePasswordRequest(data)).unwrap();
    }

    getEvents(dispatcher, paginationData) {
        return dispatcher(getMyEventsRequest({token: this.user.token, paginationData})).unwrap();
    }

    getCommunications(dispatcher, paginationData) {
        return dispatcher(getMyCommunicationsRequest({token: this.user.token, paginationData})).unwrap();
    }

    getFieldFilter(defaultPageLength = 10, field) {
        return new PaginationData(0, defaultPageLength, "desc", field, null);
    }

    getFieldAndDirFilter(defaultPageLength = 10, field, dir) {
        return new PaginationData(0, defaultPageLength, dir, field, null);
    }

}

export default ProfileViewModel;