import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { StringUtils } from "utils/StringUtils";

export default({title, model, height, type, section, periodType, loaded}) => {
    const theme = useTheme();
    const numberFormat = new Intl.NumberFormat('hu-HU', {
      maximumFractionDigits: 0
    });
    const currency = model.getChartCurrency(section, type);
    let currencyCode = StringUtils.getCurrencyShortNameFromCode(currency);
    const columnChartOptions = {
      chart: {
        type: 'bar',
        height: 200,
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
          tools:{
            download:false
          }
        },
      },
      noData: {  
        text: "Loading...",  
        align: 'center',  
        verticalAlign: 'middle',  
        offsetX: 0,  
        offsetY: 0,  
        style: {  
          color: "#000000",  
          fontSize: '14px',  
          fontFamily: "Helvetica"  
        }  
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'bottom', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return numberFormat.format(val)+" "+currencyCode;
        },
        offsetY: 5,
        style: {
          fontSize: '11px',
          colors: ["#304758"]
        }
      },
      stroke: {
        show: false,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: [],
        title: {
          text: title,
          floating: true,
          offsetY: 0,
          align: 'center',
          style: {
            color: '#444',
            fontFamily: `'Roboto', sans-serif`,
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return numberFormat.format(val);
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ['#9C27B0']
      },
      tooltip: {
        enabled: false
      },
      legend: {
        show: false,
        fontFamily: `'Roboto', sans-serif`,
        position: 'bottom',
        offsetX: 10,
        offsetY: 10,
        labels: {
          useSeriesColors: false
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8
        }
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            yaxis: {
              show: false
            }
          }
        }
      ]
    };
    if(type == "count") {
      columnChartOptions.dataLabels.formatter = function(val) {
        return val+" db";
      }
    }
    switch(type) {
      default:
      case "total": 
          columnChartOptions.fill.colors = ['#4EE38F'];
      break;
      case "count": 
          columnChartOptions.fill.colors = ['#F2EA44'];
      break;
      case "average": 
          columnChartOptions.fill.colors = ['#E34EBB'];
      break;
      case "median": 
          columnChartOptions.fill.colors = ['#4EC5E3'];
      break;
    }
    const [chartOptions, setChartOptions] = useState(columnChartOptions);
    const [data, setData] = useState([{data: []}]);
    const [chartCanLoad, setChartCanLoad] = useState(false);
    useEffect(() => {
      if(loaded) {
        const calculated = model.getChartData(section, type);
        columnChartOptions.xaxis.categories = model.getChartHeaders(section, type, periodType);
        setChartOptions(columnChartOptions);
        setData([{data: calculated}]);
        setChartCanLoad(true);
      }
    }, [periodType, loaded]);
    if(!chartCanLoad) {
      return "";
    }
    return(
        <ReactApexChart options={chartOptions} series={data} type="bar" height={height} />
    );
}