import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { changePassword, getBanks, getCommunications, getDashboard, getEvents, getProfile, updateProfile } from '_api';

const getDashboardRequest = createAsyncThunk("dashboard/list", async (payload) => {
    const result = await getDashboard(payload);
    return result.data;
});

const getDashboardProfileRequest = createAsyncThunk("dashboard/profile", async (payload) => {
    const result = await getProfile(payload);
    return result.data;
});

const getBankListRequest = createAsyncThunk("dashboard/bank", async (payload) => {
    const result = await getBanks(payload);
    return result.data;
});

const updateProfileRequest = createAsyncThunk("dashboard/profile/update", async (payload) => {
    const data = Object.assign({}, payload);
    const token = data.token;
    delete data.token;
    const result = await updateProfile(token, data);
    return result.data;
});

const changePasswordRequest = createAsyncThunk("dashboard/profile/password", async (payload) => {
    const data = Object.assign({}, payload);
    const token = data.token;
    delete data.token;
    const result = await changePassword(token, data);
    return result.data;
});

const getMyEventsRequest = createAsyncThunk("dashboard/events", async (payload) => {
    const result = await getEvents(payload);
    return result.data;
});

const getMyCommunicationsRequest = createAsyncThunk("dashboard/communications", async (payload) => {
    const result = await getCommunications(payload);
    return result.data;
});

const dashboard = createSlice({
    name: 'dashboard',
    initialState: {
        dashboard: null,
        profile: null,
        events: null,
        communications: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDashboardRequest.fulfilled, (state, action) => {
            console.log("Get dashboard result: ", action.payload);
            state.dashboard = action.payload;
        });
        builder.addCase(getDashboardProfileRequest.fulfilled, (state, action) => {
            console.log("Get dashboard profile result: ", action.payload);
            state.profile = action.payload;
        });
        builder.addCase(getMyEventsRequest.fulfilled, (state, action) => {
            console.log("Get dashboard events result: ", action.payload);
            state.events = action.payload;
        });
        builder.addCase(getMyCommunicationsRequest.fulfilled, (state, action) => {
            console.log("Get dashboard communications result: ", action.payload);
            state.communications = action.payload;
        });
    }
});

export default dashboard.reducer;

export {
    getDashboardRequest,
    getDashboardProfileRequest,
    getBankListRequest,
    updateProfileRequest,
    changePasswordRequest,
    getMyEventsRequest,
    getMyCommunicationsRequest
}

export const { selectUser } = dashboard.actions;