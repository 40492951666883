import { useMemo, useState, useCallback } from 'react';
import { scopes, hasScope } from '../../config/scopes';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Chip,
  Dialog,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Box,
  Button
} from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';

// project import
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

// assets
import { CloseOutlined, PlusOutlined, EyeTwoTone, EditTwoTone, DeleteTwoTone, UserDeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { useSelector } from 'store';
import { useDispatch } from 'store';
import PaginationData from 'model/dto/PaginationData';
import { useNavigate } from 'react-router';
import ReactTable from 'components/statosfera/table/ReactTable';
import useAuth from 'hooks/useAuth';
import { format, parseISO } from 'date-fns';
import GroupListViewModel from './model/GroupListViewModel';
import AddGroup from './AddGroup';
import DeleteGroup from './DeleteGroup';
import { useTranslation } from 'react-i18next';

export default() => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const groupList = useSelector(state => GroupListViewModel.loadFromStore(state));
    const model = new GroupListViewModel(groupList, context.user);
    const [filter, setFilter] = useState(model.getDefaultFilter(10));
  
    const canManageUsers = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_GROUPS);
    const canDeleteUsers = model.hasScope(scopes.SCOPE_MITIGATE_DELETE_GROUPS);

    useMemo(() => {
      model?.getData(dispatcher, true, filter);
    }, [dispatcher, filter]);
    const data = model.getDefaultList();

    const [customer, setCustomer] = useState(null);
  const [add, setAdd] = useState(false);
  const [del, setDel] = useState(false);

  let handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };
  if(!canManageUsers) {
    handleAdd = null;
  }

  const handleDelete = () => {
    setDel(!del);
    if (customer && !del) setCustomer(null);
  }

  const handleDeleteCancel = () => {
    setDel(!del);
    if (customer && !del) setCustomer(null);
    filter.page = 0;
    setFilter(PaginationData.from(filter));
  }

  const handleAddCancel = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setAdd(!add);
    if (customer && !add) setCustomer(null);
    filter.page = 0;
    setFilter(PaginationData.from(filter));
  }

  const handlePageChange = (data) => {
    filter.page = data;
    setFilter(PaginationData.from(filter));
  }

  const handlePageSizeChange = (data) => {
    filter.limit = data;
    setFilter(PaginationData.from(filter));
  }

  const handleSort = (id, desc) => {
    let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
    if(filter.orderBy == id && sameDir) {
      return;
    }
    filter.orderBy = id;
    filter.order = (desc) ? "desc" : "asc";
    setFilter(PaginationData.from(filter));
  }

  const handleSearch = (data) => {
    filter.search = data;
    setFilter(PaginationData.from(filter));
  }

  const columns = useMemo(
    () => [
      /*{
        title: 'Row Selection',
        // eslint-disable-next-line
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'selection',
        // eslint-disable-next-line
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },*/
      {
        Header: '#',
        accessor: 'id',
        className: 'cell-center'
      },
      {
        Header: t('group'),
        accessor: 'name'
      },
      {
        Header: t('members'),
        disableSortBy: true,
        accessor: 'numOfMembers'
      },
      {
        Header: t('created'),
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return format(parseISO(value), "yyyy. MM. dd. HH:mm:ss")
        }
      },
      {
        Header: t('actions'),
        className: 'cell-center',
        disableSortBy: true,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          const theme = useTheme();
          // eslint-disable-next-line
          const { values, isExpanded, toggleRowExpanded } = row;
          const deleteEnabled = (values.numOfMembers == 0) ? true : false;
          const deleteButtonColor = (values.numOfMembers == 0) ? theme.palette.error.main : theme.palette.secondary.main;
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              {canManageUsers && 
              <Tooltip title={t('edit')}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomer(row.original);
                    handleAdd(values.id);
                  }}
                >
                  <EditTwoTone twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              }
              {canDeleteUsers && 
              <Tooltip title={t('delete')}>
                <IconButton
                  color="error"
                  disabled={!deleteEnabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomer(row.original);
                    handleDelete(values.id);
                  }}
                >
                  <DeleteTwoTone twoToneColor={deleteButtonColor} />
                </IconButton>
              </Tooltip>
              }
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );
  const sortBy = { id: 'createdAt', desc: true };

  const buttonArea = function() {
    return(<>
      <Stack direction={'row'} alignItems="center" spacing={1}>
        {canManageUsers && 
        <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAdd}>
            {t('add_group')}
        </Button>
        }
      </Stack>
    </>);
  }

  return (
    <Box>
      <Grid item sx={{ mt: 0.25, mb: 2 }}>
          <Typography variant="h2">{t('groups')}</Typography>
      </Grid>
      <MainCard content={false}>
      <ScrollX>
        <ReactTable
          columns={columns}
          data={data}
          page={filter.page}
          pageSize={filter.limit}
          sortBy={sortBy}
          search={''}
          rowCountTotal={model.getNumOfItems()} 
          handleAdd={null}
          buttonArea={buttonArea}
          getHeaderProps={(column) => column.getSortByToggleProps()}
          renderRowSubComponent={null}
          handlePageChange={handlePageChange} 
          handlePageSizeChange={handlePageSizeChange}
          handleSort={handleSort}
          handleSearch={handleSearch} />
      </ScrollX>

      {/* add customer dialog */}
      <Dialog maxWidth="sm" fullWidth onClose={handleAddCancel} open={add} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {add && <AddGroup model={model} customer={customer} onCancel={handleAddCancel} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleDeleteCancel} open={del} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {del && <DeleteGroup customer={customer} onCancel={handleDeleteCancel} />}
      </Dialog>
    </MainCard>
    </Box>
  );
}