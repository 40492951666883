import { useSelector } from "react-redux";
import ClientDetailsAssessment from "./ClientDetailsAssessment";
import ClientDetailsAssessmentNoConsent from "./ClientDetailsAssessmentNoConsent";

export default({model, clientId, consentData, hasValidConsent, selectedAssessment, lastAssessment, selectedClientPeriod, setSelectedTab, setSelectedAssessment}) => {
    let clientLink = useSelector(state => state.client.clientLink);
    let assessmentId = selectedAssessment ? selectedAssessment.id : null;
    return(
        <>
            {lastAssessment && 
                <ClientDetailsAssessment model={model} clientId={clientId} period={selectedClientPeriod} assessmentId={assessmentId} setSelectedAssessment={setSelectedAssessment} />
            }
            {!lastAssessment && !hasValidConsent && 
                <ClientDetailsAssessmentNoConsent model={model} clientId={clientId} setSelectedTab={setSelectedTab} clientLink={clientLink} />
            }
        </>
    );
}