import { FormHelperText } from "@mui/material";

export default({formik, fieldId}) => {
    if(formik.touched[fieldId] && formik.errors[fieldId]) {
        return(
            <FormHelperText sx={{ color: "#F04134", marginLeft: "14px !important", marginTop: "3px !important" }}>
                {formik.touched[fieldId] && formik.errors[fieldId]}
            </FormHelperText>
        );
    }
    return null;
}