import { FileSearchOutlined } from "@ant-design/icons";
import { Box, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import ScrollX from "components/ScrollX";
import ReactTable from "components/statosfera/table/ReactTable";
import useAuth from "hooks/useAuth";
import PaginationData from "model/dto/PaginationData";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StringUtils } from "utils/StringUtils";
import TransactionDetails from "../components/TransactionDetails";
import ClientDetailsViewModel from "../model/ClientDetailsViewModel";

export default({clientId}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const model = new ClientDetailsViewModel(null, context.user);
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getDefaultFilter(10));
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const showDetails = (item) => {
        setSelectedTransaction(item);
        setOpenDetails(true);
    }
    const hideDetails = () => {
        setOpenDetails(false);
    }
    useEffect(() => {
        if(data) {
          setLoaded(false);
          model.fetchClientTransactions(dispatcher, clientId, filter).unwrap().then(res => {
            setLoaded(true);
          });
        }
      }, [data, dispatcher, clientId, filter]);
    if(!data) {
        return "";
    }
    const columns = useMemo(
      () => [
        {
          Header: t('transaction_info'),
          accessor: 'transactionInfo',
          disableSortBy: true
        },
        {
          Header: t('type'),
          accessor: 'type'
        },
        {
          Header: t('category'),
          accessor: 'category'
        },
        {
          Header: t('amount'),
          accessor: 'amount'
        },
        {
          Header: t('date'),
          accessor: 'transactionDate'
        }
      ]);
    const handlePageChange = (data) => {
      filter.page = data;
      setFilter(PaginationData.from(filter));
    }

    const handlePageSizeChange = (data) => {
      filter.limit = data;
      setFilter(PaginationData.from(filter));
    }
  
    const handleSearch = (data) => {
      filter.search = data;
      setFilter(PaginationData.from(filter));
    }

    const handleSort = (id, desc) => {
      let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
      if(filter.orderBy == id && sameDir) {
        return;
      }
      filter.orderBy = id;
      filter.order = (desc) ? "desc" : "asc";
      setFilter(PaginationData.from(filter));
    }

    const transactionsData = useSelector(state => state.client.clientTransactions);
    if(!transactionsData) {
      return "";
    }
    let items = [];
    transactionsData?.data?.items.forEach(i => {
      let type = i.type;
      if(!type) {
        type = t('transaction_type_unknown');
      }
      let cat = i.category;
      if(!cat) {
        cat = t('transaction_category_unknown');
      }
      let information = i.unstructuredInformation;
      if(!information && type == "TRANSFER") {
        if(i.category == "DEBIT" && i.creditorName) {
          information = i.creditorName;
        }
        else if(i.category == "CREDIT" && i.debtorName) {
          information = i.debtorName;
        }
      }
      items.push({
        id: i.id,
        transactionInfo: model.dataOrEmpty(information),
        type: StringUtils.transactionTypeChip(t, type),
        category: StringUtils.transactionCategoryChip(t, cat),
        amount: model.formatCurrency(Math.abs(i.amount), i.currency),
        transactionDate: model.formatShortDate(i.transactionDate),
        actions: <IconButton color="secondary" onClick={() => {showDetails(i)}}><Tooltip title={t('information')}><FileSearchOutlined /></Tooltip></IconButton>
      });
    });
    const sortBy = { id: 'transactionDate', desc: true };
    return(
      <>
        <ScrollX>
          <ReactTable
            columns={columns}
            data={items}
            page={filter.page}
            pageSize={filter.limit}
            sortBy={sortBy}
            search={''}
            rowCountTotal={transactionsData?.data?.numOfItems} 
            getHeaderProps={(column) => column.getSortByToggleProps()}
            handlePageChange={handlePageChange} 
            handlePageSizeChange={handlePageSizeChange}
            handleSort={handleSort}
            handleSearch={handleSearch}
            topSpacing={0} />
        </ScrollX>
        <TransactionDetails model={model} open={openDetails} onClose={hideDetails} transaction={selectedTransaction} />
      </>
    );
}