import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import { openSnackbar } from 'store/reducers/snackbar';

import useAuth from 'hooks/useAuth';
import { addGroupRequest, editGroupRequest } from 'store/reducers/access';
import { useTranslation } from 'react-i18next';

// constant
const getInitialValues = (customer) => {
  const newCustomer = {
    name: ''
  };

  if (customer) {
    newCustomer.id = customer.id;
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

const AddGroup = ({ model, customer, onCancel, onSaved }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const isCreating = !customer;
  
  const ClientSchema = Yup.object().shape({
    name: Yup.string().min(4, t('group_name_length_error')).max(128).required(t('group_name_is_required'))
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer),
    validationSchema: ClientSchema,
    onSubmit: (values, { setSubmitting }) => {
        values.token = context.user?.token;
        try {
          if (customer) {
            values.id = customer.id;
            dispatch(editGroupRequest(values)).unwrap().then(res => {
              setSubmitting(false);
              if(res.success) {
                dispatch(openSnackbar({open: true, message: t('group_edited'), variant: 'alert', alert: {color: 'success'}, close: false}));
                if(onSaved) {
                  onSaved();
                }
                onCancel();
              }
              else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
              }
            });
          } else {
            dispatch(addGroupRequest(values)).unwrap().then(res => {
              setSubmitting(false);
              if(res.success) {
                dispatch(openSnackbar({open: true, message: t('group_added'), variant: 'alert', alert: {color: 'success'}, close: false}));
                if(onSaved) {
                  onSaved();
                }
                onCancel();
              }
              else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
              }
            });
          }
        } catch (error) {
          console.error(error);
        }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{customer ? t('edit_group') : t('new_group')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="client-name">{t('name')} *</InputLabel>
                      <TextField
                        fullWidth
                        id="client-name"
                        placeholder={t('enter_group_name')}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {customer ? t('edit') : t('add')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

AddGroup.propTypes = {
  customer: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddGroup;
