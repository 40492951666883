import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Grid, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";

export default({title, button, buttonEnabled, buttonHandler, buttonText, label, sx}) => {
    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MainCard title={label} sx={sx}>
                        <Stack direction="row" alignContent="center" alignItems="center">
                            <InfoCircleOutlined style={{ fontSize: '150%'}} />
                            <Typography sx={{marginLeft: 1}}>{title}</Typography>
                        </Stack>
                        <Grid container spacing={1.25}>
                            <Grid container spacing={0} direction="row" alignItems="right" sx={{marginTop: 4, marginLeft: 1}} justifyContent="flex-start">
                                <Button variant="shadow" disabled={!buttonEnabled} color="info" onClick={buttonHandler} endIcon={button}>{buttonText}</Button>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </Grid>
    );
}