import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18_LANGUAGE } from 'config/config';
import en from 'config/locales/en';
import hu from 'config/locales/hu';

let enData = en();
let huData = hu();
/*
let enKeys = Object.keys(en());
let huKeys = Object.keys(hu());
let missingKeysInHu = [];
for(let i in enKeys) {
  if(!huKeys.includes(enKeys[i])) {
    missingKeysInHu.push(enKeys[i]);
  }
}
let missingKeysInEn = [];
for(let i in huKeys) {
  if(!enKeys.includes(huKeys[i])) {
    missingKeysInEn.push(huKeys[i]);
  }
}
*/
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: localStorage.getItem(I18_LANGUAGE) || "en",
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      hu: {
        translation: huData
      },
      en: {
        translation: enData
      }
    }
  });

export default i18n;