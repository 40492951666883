import { Grid, List, ListItem } from "@mui/material";
import MainCard from "components/MainCard";
import GridTextItem from "components/statosfera/common/GridTextItem";
import { useTranslation } from "react-i18next";

export default({model, data}) => {
    const [t, i18n] = useTranslation();
    return(
        <MainCard title={t('welcome')+' '+data?.lastName+' '+data.firstName+'!'} sx={{minHeight: '300px'}}>
        <List sx={{ py: 0 }}>

            <ListItem divider={true}>
                <GridTextItem title={t('logged_in_as')} data={data?.email} />
            </ListItem>

            <ListItem divider={false}>
                <Grid container spacing={3}>
                    <GridTextItem title={t('system_name')} data={model.dataOrEmpty(data.clientName)} md={6} />
                    <GridTextItem title={t('system_version')} data={model.dataOrEmpty(data.version)} md={6} />
                </Grid>
            </ListItem>
            
        </List>
        </MainCard>       
    );
}