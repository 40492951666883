import { Grid, useMediaQuery } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import ClientDetailChart from "../ClientDetailChart";

export default({model, period, loaded}) => {
    const [t, i18n] = useTranslation();
    /*if(!chartData?.data) {
        return "";
    }*/
    const chartHeight = 250;
    const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const chartGridXs = (matchDownMD || period == 'WEEKLY') ? 12 : 6;
    return(
        <MainCard title={t('inflow_stats')}>
            <Grid container spacing={3}>
                <Grid item xs={chartGridXs}>
                    <ClientDetailChart model={model} title={t('total')} type="total" section="inflow" height={chartHeight} periodType={period} loaded={loaded} />
                </Grid>
                <Grid item xs={chartGridXs}>
                    <ClientDetailChart model={model} title={t('count')} type="count" section="inflow" height={chartHeight} periodType={period} loaded={loaded} />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={chartGridXs}>
                    <ClientDetailChart model={model} title={t('average')} type="average" section="inflow" height={chartHeight} periodType={period} loaded={loaded} />
                </Grid>
                <Grid item xs={chartGridXs}>
                    <ClientDetailChart model={model} title={t('median')} type="median" section="inflow" height={chartHeight} periodType={period} loaded={loaded} />
                </Grid>
            </Grid>
        </MainCard>        
    );
}