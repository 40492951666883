import ScrollX from "components/ScrollX";
import ReactTable from "components/statosfera/table/ReactTable";
import useAuth from "hooks/useAuth";
import PaginationData from "model/dto/PaginationData";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ClientDetailsViewModel from "../model/ClientDetailsViewModel";
import ClientAccounts from "./assessment/ClientAccounts";

export default({clientId}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const model = new ClientDetailsViewModel(null, context.user);
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getDefaultFilter(10));
    useEffect(() => {
        if(data) {
          setLoaded(false);
          model.fetchClientAccounts(dispatcher, clientId, filter).unwrap().then(res => {
            setLoaded(true);
          });
        }
      }, [data, dispatcher, clientId, filter]);
    if(!data) {
        return "";
    }
    const accountsData = useSelector(state => state.client.clientAccounts);
    const [t, i18n] = useTranslation();
    let accountsByBank = {};
    accountsData?.data?.accounts.forEach(i => {
      if(!accountsByBank[i.bankId]) {
        accountsByBank[i.bankId] = [];
      }
      accountsByBank[i.bankId].push(i);
    })
    let bankKeys = Object.keys(accountsByBank);
    if(bankKeys.length == 0) {
      return "No accounts found"; 
    }
    return(
      <>
      {
      Object.keys(accountsByBank).map(i => {
        let accounts = accountsByBank[i];
        let label = accounts[0].bankName;
        return <ClientAccounts key={"bank_accounts_"+i} model={model} bankId={i} accounts={accounts} label={label} />
      })
      }
      </>
    );
}