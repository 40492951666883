// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';

// assets
import { RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import ClientDetailsViewModel from '../model/ClientDetailsViewModel';
import { setClientLink } from 'store/reducers/client';
import { openSnackbar } from 'store/reducers/snackbar';
import { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import { config as apiConfig, selectedProfile } from '../../../config/config';
import { parseISO } from 'date-fns';
import InfoBoxWithButton from '../components/InfoBoxWithButton';
import ClientBankAccessToken from './bank_access/ClientBankAccessToken';
import ClientBankConsents from './bank_access/ClientBankConsents';
import { useTranslation } from 'react-i18next';
import BankList from 'pages/dashboard/ui/BankList';
import { scopes } from 'config/scopes';

let debunkTimeout = null;

export default({clientId, tokenData, clientData}) => {
  const dispatcher = useDispatch();
  const theme = useTheme();
  const context = useAuth();
  const [t, i18n] = useTranslation();
  const [banks, setBanks] = useState();

  useEffect(() => {
    if(!banks) {
      model.fetchBankList(dispatcher).unwrap().then(res => {
        if(res.success && res.data?.banks) {
          setBanks(res.data.banks);
        }
      });
    }
  }, [clientId]);

  const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
  let clientLink = useSelector(state => state.client.clientLink);
  if(!clientId) {
    clientLink = null;
  }
  if(clientLink && clientId && clientLink?.clientId !== clientId) {
    clientLink = null;
  }
  const model = new ClientDetailsViewModel(data, context.user);
  const consents = data?.data?.consents;
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [link, setLink] = useState(clientLink);
  const [restart, setRestart] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [commMethod, setCommMethod] = useState('email');
  if(!link && tokenData && !restart && clientId && clientData?.data?.clientId && clientData?.data?.clientId == clientId) {
    let expireDate = parseISO(tokenData.expiration);
    let createdDate = parseISO(tokenData.created);
    let now = new Date();
    let expirationMs = Math.ceil((expireDate.getTime() - now.getTime()) / 1000);
    const portalLink = apiConfig[selectedProfile].MITIGATE_CLIENT_PORTAL_URL+"?client="+tokenData.accessToken;
    setLink({timestamp: createdDate.getTime(), expiration: expirationMs, link: portalLink});
  }
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const showClientCommunicationSettingsDialog = () => {
    setDialogOpen(true);
  }

  const startProcess = () => {
    if(!data || !data.data || !data.data.clientId) {
      return;
    }
    setButtonEnabled(false);
    const sendEmail = commMethod == "email";
    const sendSms = commMethod == "sms";
    model.fetchClientAccessToken(dispatcher, clientId, sendEmail, sendSms).unwrap().then(res => {
      setButtonEnabled(true);
      setDialogOpen(false);
      if(res.success) {
        if(restart) {
          dispatcher(openSnackbar({open: true, message: t('link_regenerated'), variant: 'alert', alert: {color: 'success'}}));
        }
        else {
          dispatcher(openSnackbar({open: true, message: t('link_generated'), variant: 'alert', alert: {color: 'success'}}));
        }
        const accessToken = res.data.access_token;
        const expiration = parseFloat(res.data.expires_in);
        let timestamp = new Date();
        const portalLink = apiConfig[selectedProfile].MITIGATE_CLIENT_PORTAL_URL+"?client="+accessToken;
        if(commMethod == "clipboard") {
          navigator.clipboard.writeText(portalLink);
        }
        const linkData = {clientId: clientId,timestamp: timestamp.getTime(), expiration, link: portalLink};
        dispatcher(setClientLink(linkData));
        setLink(linkData);
        setRestart(false);
      }
    }, e => {
      setButtonEnabled(true);
      setDialogOpen(false);
      dispatcher(openSnackbar({open: true, message: t('link_generation_error'), variant: 'alert', alert: {color: 'error'}}));
    });
  }
  const restartProcess = () => {
    setRestart(true);
    if(debunkTimeout) {
      clearTimeout(debunkTimeout);
    }
    debunkTimeout = setTimeout(() => {
      showClientCommunicationSettingsDialog();
    }, 400);
  }
  const handleSendCancel = () => {
    setDialogOpen(false);
  }

  const handleCommMethodChange = (event) => {
    setCommMethod(event.target.value);
  }

  const handleSubmit = () => {
    startProcess();
  }

  const canSendEmail = data?.data?.canSendEmail;
  const canSendSms = data?.data?.canSendSms;
  const emailAddress = data?.data?.personal.email;
  const phoneNumber = data?.data?.personal.phone;

  const canSeeScoringData = model.hasScope(scopes.SCOPE_MITIGATE_SCORE_DETAILS);

  return (
    <>
    <Grid container spacing={3}>
          {!link && 
            <InfoBoxWithButton 
              label={t('data_access_link')}
              title={t('data_access_link_desc')} 
              button={<RightOutlined />}
              buttonEnabled={buttonEnabled}
              buttonHandler={showClientCommunicationSettingsDialog}
              buttonText={t('start')} 
              sx={{background: '#EDFFEF'}}
              />
          }
          {link && 
            <ClientBankAccessToken model={model} link={link} tokenData={tokenData} restartProcess={restartProcess} />
          }
          {canSeeScoringData &&
          <ClientBankConsents model={model} consents={consents} />
          }
          <BankList model={model} banks={banks} />
    </Grid>
      <Dialog maxWidth="sm" fullWidth onClose={handleSendCancel} open={dialogOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          <DialogTitle>{t('generate_access_link')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <FormControl fullWidth>
              <InputLabel id="communication-label" htmlFor="communication">{t('send_link_to')}</InputLabel>
              <Select
                labelId="communication-label"
                id="communication"
                onChange={handleCommMethodChange}
                value={commMethod}
                label={t('method_of_communication')}>
                {canSendEmail && emailAddress && 
                  <MenuItem value={'email'}>{t('email_to_address', {'emailAddress': emailAddress})}</MenuItem>
                }
                {canSendSms && phoneNumber && 
                  <MenuItem value={'sms'}>{t('sms_to_number', {'number': phoneNumber})}</MenuItem>
                }
                  <MenuItem value={'clipboard'}>{t('copy_to_clipboard')}</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={handleSendCancel}>
                    {t('cancel')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={!buttonEnabled} onClick={handleSubmit}>
                    {t('save')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
    </Dialog>
    </>
    );
}