import { Grid, List, ListItem } from "@mui/material";
import MainCard from "components/MainCard";
import GridTextItem from "components/statosfera/common/GridTextItem";
import { useTranslation } from "react-i18next";

export default({model, chartData, assessmentData, personalData}) => {
    const [t, i18n] = useTranslation();
    if(!chartData?.data) {
      return "";
    }
    let totalCount = model.getTotalCount();
    let periodFromTo = model.getPeriod();
    let netTotal = model.getNetTotal();
    return(
      <MainCard title={t('statistics')} sx={{minHeight:'360px'}}>
        <List sx={{ py: 0 }}>
          <ListItem divider={true}>
            <Grid container spacing={3}>
              <GridTextItem title={t('period_from')} data={periodFromTo?.from} md={6} />
              <GridTextItem title={t('period_to')} data={periodFromTo?.to} md={6} />
            </Grid>
          </ListItem>
          <ListItem divider={true}>
            <Grid container spacing={3}>
            <GridTextItem title={t('date_fetched')} data={model.formatShortDate(assessmentData?.createdAt)} />
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3}>
              <GridTextItem title={t('total_transactions')} data={totalCount} md={6} />
              <GridTextItem title={t('net_flow')} data={model.formatCurrency(netTotal, personalData?.currency)} md={6} />
            </Grid>
          </ListItem>
        </List>
      </MainCard>        
    );
}