import { LinkOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { config as apiConfig, selectedProfile } from '../../../../config/config';

export default({model, chartData}) => {
    const [t, i18n] = useTranslation();
    let certificate = '-';
    if(chartData?.data?.assessmentData?.certificateId) {
        const certId = chartData?.data?.assessmentData?.certificateId;
        const certificateUrl = apiConfig[selectedProfile].PUBLIC_API_BASE_URL+"/mitigate/certificate/hu/"+certId;
        certificate = <>{certId}<IconButton href={certificateUrl} target="_blank" rel="noopener noreferrer" color="secondary"><LinkOutlined /></IconButton></>
    }
    return(
        <MainCard title={t('client_certificate')}>
            {certificate}
        </MainCard>
    );
}