import { Grid, Table, TableBody, TableCell, TableRow, TableHead } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import { StringUtils } from "utils/StringUtils";

export default({model, consents}) => {
    const [t, i18n] = useTranslation();
    const hasAnyConsents = consents.length > 0;
    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard title={t('bank_consents')}>
                    {!hasAnyConsents && 
                        <>{t('no_data')}</>
                    }
                    {hasAnyConsents && 
                         <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t('bank')}</TableCell>
                                    <TableCell>{t('status')}</TableCell>
                                    <TableCell>{t('last_run')}</TableCell>
                                    <TableCell>{t('last_fail')}</TableCell>
                                    <TableCell>{t('created')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {consents.map(i => {
                                return(
                                    <TableRow key={'consent_'+i.id}>
                                        <TableCell>{i.id}</TableCell>
                                        <TableCell>{i.bankName}</TableCell>
                                        <TableCell>{StringUtils.consentStatus(t, i.status)}</TableCell>
                                        <TableCell>{model.formatShortDate(i.lastRun)}</TableCell>
                                        <TableCell>{model.formatShortDate(i.lastFail)}</TableCell>
                                        <TableCell>{model.formatShortDate(i.created)}</TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    }
                  </MainCard>
                </Grid>
            </Grid>
        </Grid>
    );
}