import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';

import { openSnackbar } from 'store/reducers/snackbar';


import { deleteClientRequest } from 'store/reducers/client';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const DeleteClient = ({ customer, onCancel, onSaved }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    const token = context.user?.token;
    const values = {token: token, id: customer.id};
    dispatch(deleteClientRequest(values)).unwrap().then(res => {
      setSubmitting(false);
      if(res.success) {
        dispatch(openSnackbar({open: true, message: t('client_deleted'), variant: 'alert', alert: {color: 'success'}, close: false}));
        onCancel();
        if(onSaved) {
          onSaved();
        }
      }
      else {
        dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
      }
    });
  }

  const confirmText = <div dangerouslySetInnerHTML={
    {__html: t('delete_client_confirm', {interpolation: {escapeValue: false}})}
  } />;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogTitle>{t('delete_client')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant='body'>{confirmText}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting} onClick={handleSubmit}>{t('delete')}</Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>

      </LocalizationProvider>
  );
};

export default DeleteClient;
