import { format, parseISO } from "date-fns";
import TableViewModel from "model/viewmodel/TableViewModel";
import PaginationData from "model/dto/PaginationData";
import Group from "model/entity/Group";

const { getGroupsRequest } = require("store/reducers/access");

class GroupListViewModel extends TableViewModel {

    static loadFromStore(state) {
        return state.access.groups;
    }

    constructor(listData, userModel) {
        super(userModel);
        this.data = listData;
        this.numOfItems = 0;
        this.groups = [];
        if(listData && listData.data) {
            this.initGroups(listData.data.items);
            this.numOfItems = listData.data.numOfItems;
        }
    }

    initGroups(data) {
        data.forEach(i => {
            this.groups.push(new Group(i));
        });
    }

    getData(dispatcher, reset, paginationData) {
        if(!this.data || reset) {
            const request = {token: this.user.token, paginationData, timestamp: Date.now()};
            return dispatcher(getGroupsRequest(request));
        }
    }

    getDefaultList() {
        return this.groups;
    }

    getNumOfItems() {
        return this.numOfItems;
    }

    getDefaultFilter(defaultPageLength = 50) {
        return new PaginationData(0, defaultPageLength, "desc", "id", null);
    }

}

export default GroupListViewModel;