// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  UserOutlined,
  DotChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  DotChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const recash = {
  id: 'recash',
  title: <FormattedMessage id="recash" />,
  type: 'group',
  children: [
    {
      id: 'recash_dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/recash/dashboard',
      icon: icons.DotChartOutlined
    },
    {
        id: 'recash_settings',
        title: <FormattedMessage id="settings" />,
        type: 'item',
        url: '/recash/settings',
        icon: icons.UserOutlined
      },
      {
        id: 'recash_transactions',
        title: <FormattedMessage id="transactions" />,
        type: 'item',
        url: '/recash/transactions',
        icon: icons.UserOutlined
      }
  ]
};

export default recash;
