// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  UserOutlined,
  DotChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  DotChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const profound = {
  id: 'profound',
  title: <FormattedMessage id="profound" />,
  type: 'group',
  children: [
    {
      id: 'profound_dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/profound/dashboard',
      icon: icons.DotChartOutlined
    },
    {
        id: 'profound_users',
        title: <FormattedMessage id="users" />,
        type: 'item',
        url: '/profound/users',
        icon: icons.UserOutlined
      }
  ]
};

export default profound;
