import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import { openSnackbar } from 'store/reducers/snackbar';


import { addClientRequest, changeClientStatusRequest, editClientRequest } from 'store/reducers/client';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import { ConnectedFocusError } from 'focus-formik-error'
import { useState } from 'react';

const fixedComments = ["status_client_reason_did_not_want_consent", "status_client_reason_could_not_give_consent_missing_login_data", "status_client_reason_could_not_give_consent_bank_error", "status_client_reason_did_not_want_contract"];
    
// constant
const getInitialValues = (customer) => {
    let status = "";
    if(customer.status == "CLOSED" || customer.status == "ERROR" || customer.status == "FINISHED") {
        status = customer.status;
    }
    let adminComment = customer.adminComment;
    let adminCommentCustom = "";
    if(!fixedComments.includes(adminComment)) {
        adminComment = "status_client_reason_other";
        adminCommentCustom = customer.adminComment;
    }
    const newCustomer = {
      status: status,
      adminComment: adminComment ? adminComment : "",
      adminCommentCustom: adminCommentCustom ? adminCommentCustom : ""
    };
    return newCustomer;
  };

const ChangeClientStatus = ({ customer, onCancel, onSaved }) => {
    const theme = useTheme();
    const context = useAuth();
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation();
    let showCustomComment = false;
    if(!fixedComments.includes(customer.adminComment)) {
        showCustomComment = true;
    }
    const [showCustom, setShowCustom] = useState(showCustomComment);

    const ClientSchema = Yup.object().shape({
        status: Yup.string().required(t('client_status_is_required')),
        adminComment: Yup.string().max(2048),
        adminCommentCustom: Yup.string().max(2048)
    });

    const formik = useFormik({
        initialValues: getInitialValues(customer),
        validationSchema: ClientSchema,
        onSubmit: (values, { setSubmitting }) => {
            const data = Object.assign({}, values);
            if(data.adminComment == "status_client_reason_other") {
                data.adminComment = data.adminCommentCustom;
            }
            delete data.adminCommentCustom;
            data.token = context.user?.token;
            data.id = customer.id;
            dispatch(changeClientStatusRequest(data)).unwrap().then(res => {
                onClientStatusSavedResult(res, setSubmitting);
            });
        }
    });

    const onClientStatusSavedResult = (res, setSubmitting) => {
        setSubmitting(false);
        if(res.success) {
          dispatch(openSnackbar({open: true, message: t('status_changed'), variant: 'alert', alert: {color: 'success'}, close: false}));
          if(onSaved) {
            onSaved(res?.data?.clientId);
          }
          onCancel();
        }
        else {
          dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
        }
      }

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    const onAdminCommentChange = (e) => {
        const newComment = e.target.value;
        if(newComment == "status_client_reason_other") {
            setShowCustom(true);
        }
        else {
            setShowCustom(false);
        }
        formik.setFieldValue("adminComment", newComment);
    }

    const showCustomText = showCustom ? 'block' : 'none';

    const nextStatuses = customer?.nextStatuses;

    return(
        <FormikProvider value={formik}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <DialogTitle>{t('change_client_status')}</DialogTitle>
                    <Divider />
                    <DialogContent sx={{ p: 2.5 }}>
                        <ConnectedFocusError />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1.25}>
                                            <InputLabel id="client-status-label" htmlFor="client-status">{t('status')}</InputLabel>
                                            <Select
                                                labelId="client-status-label"
                                                id="client-status"
                                                label="Status"
                                                {...getFieldProps('status')}
                                                error={Boolean(touched.status && errors.status)}
                                            >
                                            {nextStatuses && nextStatuses.map(s => {
                                                return <MenuItem key={'status_item_'+s.toLowerCase()} value={s}>{t('status_'+s.toLowerCase())}</MenuItem>
                                            })}
                                            </Select>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                <InputLabel htmlFor="client-admin-comment">{t('admin_comment')}</InputLabel>
                                <Select
                                    labelId="client-admin-comment-label"
                                    id="client-admin-comment"
                                    label="Admin comment"
                                    onChange={onAdminCommentChange}
                                    value={formik.values.adminComment}
                                    error={Boolean(touched.adminComment && errors.adminComment)}
                                >
                                    <MenuItem value={'status_client_reason_did_not_want_consent'}>{t('status_client_reason_did_not_want_consent')}</MenuItem>
                                    <MenuItem value={'status_client_reason_could_not_give_consent_missing_login_data'}>{t('status_client_reason_could_not_give_consent_missing_login_data')}</MenuItem>
                                    <MenuItem value={'status_client_reason_could_not_give_consent_bank_error'}>{t('status_client_reason_could_not_give_consent_bank_error')}</MenuItem>
                                    <MenuItem value={'status_client_reason_did_not_want_contract'}>{t('status_client_reason_did_not_want_contract')}</MenuItem>
                                    <MenuItem value={'status_client_reason_other'}>{t('status_client_reason_other')}</MenuItem>
                                </Select>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{display: showCustomText}}>
                                <Stack spacing={1.25}>
                                <InputLabel htmlFor="client-admin-comment-custom">{t('admin_comment')}</InputLabel>
                                <TextField
                                    fullWidth
                                    id="client-admin-comment-custom"
                                    placeholder={t('enter_admin_comment')}
                                    multiline
                                    rows={4}
                                    {...getFieldProps('adminCommentCustom')}
                                    error={Boolean(touched.adminCommentCustom && errors.adminCommentCustom)}
                                    helperText={touched.adminCommentCustom && errors.adminCommentCustom}
                                />
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider />
                    <DialogActions sx={{ p: 2.5 }}>
                        <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item></Grid>
                        <Grid item>
                            <Stack direction="row" spacing={2} alignItems="center">
                            <Button color="error" onClick={onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button type="submit" variant="contained" disabled={isSubmitting}>
                                {t('save')}
                            </Button>
                            </Stack>
                        </Grid>
                        </Grid>
                    </DialogActions>
                </Form>
            </LocalizationProvider>
        </FormikProvider>
    );
}

export default ChangeClientStatus;