import { DollarCircleOutlined, InfoCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Box, Grid, IconButton, Modal, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default({model, bankId, label, accounts}) => {
    const [t, i18n] = useTranslation();
    const [showBalances, setShowBalances] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const modalStyle = {
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const onShowBalances = () => {
        setShowBalances(!showBalances);
    }
    return(
        <>
        <MainCard title={label} contentSX={{padding: 0}} style={{marginTop: '16px'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('account_number')}</TableCell>
                        <TableCell sx={{width: '10%'}}>{t('type')}</TableCell>
                        <TableCell sx={{width: '20%'}}>{t('holder')}</TableCell>
                        <TableCell sx={{width: '10%'}}>{t('currency')}</TableCell>
                        <TableCell sx={{width: '10%'}}>{t('last_balance')}</TableCell>
                        <TableCell sx={{width: '5%'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {accounts?.filter(i => i.accountNumber).map(i => {
                    let balance = "-";
                    let balanceType = "-";
                    let balanceDate = "-";
                    if(i.balances && i.balances.length > 0) {
                        balance = model.formatCurrency(i.balances[0].amount, i.currency);
                        balanceType = t('balance_type_'+i.balances[0].balanceType.toLowerCase());
                        if(i.balances[0].lastFetched) {
                            balanceDate = model.formatShortDate(i.balances[0].lastFetched);            
                        }
                    }
                    return (
                        <TableRow key={'account_'+i.id}>
                            <TableCell>{i.accountNumber}</TableCell>
                            <TableCell>{model.dataOrEmpty(i.bankAccountType)}</TableCell>
                            <TableCell>{model.dataOrEmpty(i.accountHolderName)}</TableCell>
                            <TableCell>{t('currency_long_'+i.currency.toLowerCase())}</TableCell>
                            <TableCell>{balance}</TableCell>
                            <TableCell>
                                <IconButton color="secondary" onClick={() => {
                                    setSelectedAccount(i);
                                    onShowBalances();
                                }}>
                                    <Tooltip title={t('list_balances')}>
                                        <DollarCircleOutlined />
                                    </Tooltip>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                    })
                    }
                </TableBody>
            </Table>
        </MainCard>
        <Modal open={showBalances} onClose={onShowBalances}>
            <Box sx={modalStyle}>
                <Grid item sx={{ mt: 0.25, mb: 2 }}>
                    <Typography variant="h2">{t('balances')}</Typography>
                </Grid>
                <MainCard border={false} boxShadow>
                    {selectedAccount && 
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('date')}</TableCell>
                            <TableCell>{t('last_balance')}</TableCell>
                            <TableCell>{t('type')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {selectedAccount.balances.map((i, k) => {
                        return(
                            <TableRow key={'balance_'+k}>
                                <TableCell sx={{width: '40%'}}>{model.formatShortDate(i.lastFetched)}</TableCell>
                                <TableCell>{model.formatCurrency(i.amount, selectedAccount.currency)}</TableCell>
                                <TableCell>{t('balance_type_'+i.balanceType.toLowerCase())}</TableCell>
                            </TableRow>
                        );
                    })}
                    </TableBody>
                    </Table>
                    }
                </MainCard>
            </Box>
        </Modal>
        </>
    );
}