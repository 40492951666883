import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addGroup, addUser, blockUser, deleteGroup, deleteUser, editGroup, editUser, exportUsers, getGroups, getUsers } from '_api';

const getUsersRequest = createAsyncThunk("users/list", async (payload) => {
    const result = await getUsers(payload);
    return result.data;
});

const addUserRequest = createAsyncThunk("users/add", async (payload) => {
    const data = Object.assign({}, payload);
    const token = data.token;
    delete data.token;
    delete data.passwordagain;
    if(data.groupId == 0) {
        delete data.groupId;
    }
    const result = await addUser(token, data);
    return result.data;
});

const editUserRequest = createAsyncThunk("users/edit", async (payload) => {
    const data = Object.assign({}, payload);
    const clientId = data.id;
    delete data.id;
    const token = data.token;
    delete data.token;
    if(data.groupId == 0) {
        delete data.groupId;
    }
    const result = await editUser(token, clientId, data);
    return result.data;
});

const blockUserRequest = createAsyncThunk("users/block", async (payload) => {
    const clientId = payload.id;
    const token = payload.token;
    const result = await blockUser(token, clientId);
    return result.data;
});

const deleteUserRequest = createAsyncThunk("users/delete", async (payload) => {
    const clientId = payload.id;
    const token = payload.token;
    const result = await deleteUser(token, clientId);
    return result.data;
});

const getGroupsRequest = createAsyncThunk("groups/list", async (payload) => {
    const result = await getGroups(payload);
    return result.data;
});

const addGroupRequest = createAsyncThunk("groups/add", async (payload) => {
    const data = Object.assign({}, payload);
    const token = data.token;
    delete data.token;
    const result = await addGroup(token, data);
    return result.data;
});

const editGroupRequest = createAsyncThunk("groups/edit", async (payload) => {
    const data = Object.assign({}, payload);
    const groupId = data.id;
    delete data.id;
    const token = data.token;
    delete data.token;
    const result = await editGroup(token, groupId, data);
    return result.data;
});

const deleteGroupRequest = createAsyncThunk("groups/delete", async (payload) => {
    const clientId = payload.id;
    const token = payload.token;
    const result = await deleteGroup(token, clientId);
    return result.data;
});

const exportUsersRequest = createAsyncThunk("users/export", async (payload) => {
    const result = await exportUsers(payload);
    return result.data;
});

const access = createSlice({
    name: 'access',
    initialState: {
        users: null,
        selectedUser: null,
        groups: null
    },
    reducers: {
        selectUser: (state, action) => {
            state.selectedUser = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersRequest.fulfilled, (state, action) => {
            console.log("Get users result: ", action.payload);
            state.users = action.payload;
        });
        builder.addCase(getGroupsRequest.fulfilled, (state, action) => {
            console.log("Get groups result: ", action.payload);
            state.groups = action.payload;
        });
    }
});

export default access.reducer;

export {
    getUsersRequest,
    addUserRequest,
    editUserRequest,
    blockUserRequest,
    deleteUserRequest,
    getGroupsRequest,
    addGroupRequest,
    editGroupRequest,
    deleteGroupRequest,
    exportUsersRequest
}

export const { selectUser } = access.actions;