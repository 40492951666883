import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ClientPage from 'pages/clients/ClientPage';
import DashboardPage from 'pages/dashboard/DashboardPage';
import ClientDetails from 'pages/clients/ClientDetails';
import AccessPage from 'pages/access/AccessPage';
import GroupsPage from 'pages/groups/GroupsPage';
import ProfilePage from 'pages/profile/ProfilePage';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

const ProfileProfilePage = Loadable(lazy(() => import('pages/profile/profile/ProfileProfilePage')));
const ProfilePersonalPage = Loadable(lazy(() => import('pages/profile/profile/ProfilePersonalPage')));
const ProfilePasswordPage = Loadable(lazy(() => import('pages/profile/profile/ProfilePasswordPage')));
const ProfileEventsPage = Loadable(lazy(() => import('pages/profile/profile/ProfileEventsPage')));
const ProfileCommunicationsPage = Loadable(lazy(() => import('pages/profile/profile/ProfileCommunicationsPage')));
const ProfileSettingsPage = Loadable(lazy(() => import('pages/profile/profile/ProfileSettingsPage')));

// render - sample page
//const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage />
        },
        {
          path: '/access',
          element: <AccessPage />
        },
        {
          path: '/groups',
          element: <GroupsPage />
        },
        {
          path: '/mitigate/clients',
          element: <ClientPage />
        },
        {
          path: '/mitigate/client/:id',
          element: <ClientDetails />
        },
        {
          path: '/profile',
          element: <ProfilePage />,
          children: [
            {
              path: 'profile',
              element: <ProfileProfilePage />
            },
            {
              path: 'personal',
              element: <ProfilePersonalPage />
            },
            {
              path: 'password',
              element: <ProfilePasswordPage />
            },
            {
              path: 'events',
              element: <ProfileEventsPage />
            },
            {
              path: 'communications',
              element: <ProfileCommunicationsPage />
            },
            {
              path: 'settings',
              element: <ProfileSettingsPage />
            }
          ]
        },
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: 'error',
          element: <MaintenanceError500 />
        },
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
