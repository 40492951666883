import { Chip, Divider, Grid, Stack } from "@mui/material";

export default({label, sx}) => {
    return(
        <Grid item xs={12} sx={sx}>
            <Stack spacing={1.25}>
                <Divider textAlign="left"><Chip label={label} /></Divider>
            </Stack>
        </Grid>
    );
}