// project import
import main from './main';
import mitigate from './mitigate';
import profound from './profound';
import recash from './recash';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [main, recash, mitigate, profound]
};

export default menuItems;
