// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  UserOutlined,
  DotChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  DotChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const mitigate = {
  id: 'mitigate',
  title: <FormattedMessage id="mitigate" />,
  type: 'group',
  children: [
    {
      id: 'mitigate_dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/mitigate/dashboard',
      icon: icons.DotChartOutlined
    },
    {
        id: 'mitigate_clients',
        title: <FormattedMessage id="clients" />,
        type: 'item',
        url: '/mitigate/clients',
        icon: icons.UserOutlined
      }
  ]
};

export default mitigate;
