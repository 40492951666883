import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { scopes, hasScope } from '../../config/scopes';

// material-ui
import { Box, Dialog, Grid, Tab, Tabs, Typography } from '@mui/material';
import MainCard from "components/MainCard";

import { AccountBookOutlined, BellOutlined, ContainerOutlined, DotChartOutlined, FileTextOutlined, HistoryOutlined, MailOutlined, ProfileOutlined, ScanOutlined, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import ClientDetailsProfile from './detail/ClientDetailsProfile';
import ClientDetailBankAccess from './detail/ClientDetailBankAccess';
import ClientDetailsViewModel from './model/ClientDetailsViewModel';
import { useDispatch, useSelector } from 'react-redux';
import ClientDetailsTransactions from './detail/ClientDetailsTransactions';
import useAuth from 'hooks/useAuth';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router';
import ClientDetailsAccounts from './detail/ClientDetailsAccounts';
import ClientDetailsMonitoring from './detail/ClientDetailsMonitoring';
import ClientDetailsAssessmentRoot from './detail/ClientDetailsAssessmentRoot';
import ClientDetailsAssessmentHistory from './detail/ClientDetailsAssessmentHistory';
import ClientDetailsEvents from './detail/ClientDetailsEvents';
import ClientDetailsSessions from './detail/ClientDetailsSessions';
import ClientDetailsCommunications from './detail/ClientDetailsCommunications';
import AddClient from './AddClient';
import DeleteClient from './DeleteClient';
import { useTranslation } from 'react-i18next';
import ClientDetailsBankScans from './detail/scan/ClientDetailsBankScans';
import ChangeClientStatus from './ChangeClientStatus';

export default() => {
    const theme = useTheme();
    const pathName = useLocation();
    const dispatcher = useDispatch();
    const context = useAuth();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation();
    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const clientTokenData = data?.data?.token;
    const consentData = data?.data?.consents;
    const hasValidConsent = data?.data?.hasApprovedConsent;
    const lastAssessment = data?.data?.lastAssessment;
    const model = new ClientDetailsViewModel(data, context.user);
    const selectedClientPeriod = useSelector(state => state.client.clientPeriodType);
    const clientId = pathName.pathname.split("/").reverse()[0];
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [add, setAdd] = useState(false);
    const [del, setDel] = useState(false);
    const [stat, setStat] = useState(false);
    useEffect(() => {
        model.fetchDetails(dispatcher, clientId);
    }, [dispatcher, clientId]);
    const defaultTab = window.location.hash ? window.location.hash.split("#")[1] : "tab-assessment";
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const handleChange = (event, newValue) => {
        window.location.hash = newValue;
        setSelectedTab(newValue);
    };
    const changeAssessment = () => {
        setSelectedAssessment(null);
    }
    const handleEdit = (data) => {
        setAdd(!add);
        if(data) {
            const editData = _.merge({}, data.data?.personal, {id: data.data?.clientId});
            setCustomer(editData);
        }
    }
    const handleDelete = (data) => {
        setDel(!del);
        if(data) {
            const editData = _.merge({}, data.data?.personal, {id: data.data?.clientId});
            setCustomer(editData);
        }
    }
    const handleDeleteCancel = () => {
        setDel(!del);
        if (customer && !del) setCustomer(null);
    }
    const handleAddCancel = (event, reason) => {
        if (reason && reason == "backdropClick") {
            return;
        }
        setAdd(!add);
        if (customer && !add) setCustomer(null);
    }
    const onEditedClient = () => {
        window.location.reload(false);
    }
    const onDeletedClient = () => {
        navigate("/mitigate/clients");
    }

    const handleChangeStatus = (data) => {
        setStat(true);
        if(data) {
            const editData = _.merge({}, data.data?.personal, {id: data.data?.clientId});
            setCustomer(editData);
        }
    }

    const handleChangeStatusCancel = (e) => {
        setStat(false);
    }
    
    const handleChangeStatusSaved = () => {
        window.location.reload();
    }
    if(!data) {
        return "";
    }
    const isAssessmentDisabled = !model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_VIEW);
    if(isAssessmentDisabled && !window.location.hash && selectedTab == 'tab-assessment') {
        setSelectedTab('tab-client-profile');
    }
    let tabs = [];
    tabs.push({id: "tab-assessment", label: t('risk_assessment'), icon: <ContainerOutlined />, disabled: isAssessmentDisabled});
    tabs.push({id: "tab-assessment-history", label: t('assessment_history'), icon: <HistoryOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_ASSESSMENT_HISTORY)});
    tabs.push({id: "tab-accounts", label: t('bank_accounts'), icon: <AccountBookOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_BANK_ACCOUNTS)});
    tabs.push({id: "tab-transactions", label: t('transactions'), icon: <ProfileOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_TRANSACTIONS)});
    tabs.push({id: "tab-client-profile", label: t('client_profile'), icon: <UserOutlined />});
    //tabs.push({id: "tab-client-settings", label: "Client settings", icon: <SettingOutlined />});
    tabs.push({id: "tab-bank-access", label: t('bank_access'), icon: <FileTextOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_BANK_ACCESS)});
    tabs.push({id: "tab-scans", label: t('bank_scans'), icon: <ScanOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_SCANS)});
    //tabs.push({id: "tab-monitoring", label: "Monitoring", icon: <UserOutlined />});
    tabs.push({id: "tab-communications", label: t('communications'), icon: <MailOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_COMMUNICATIONS)});
    tabs.push({id: "tab-events", label: t('events'), icon: <BellOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_EVENTS)});
    tabs.push({id: "tab-logs", label: t('logs'), icon: <UnorderedListOutlined />, disabled: !model.hasScope(scopes.SCOPE_MITIGATE_LOGS)});
    
    let pageTitle = t('client_profile');
    if(data?.data?.personal?.firstName) {
        pageTitle += ' ('+data?.data?.personal?.firstName+' '+data?.data?.personal?.lastName+')';
    }

    return(
        <Box>
            <Grid item sx={{ mt: 0.25, mb: 2 }}>
                <Typography variant="h3">{pageTitle}</Typography>
            </Grid>
            <MainCard border={false} boxShadow>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="account profile tab">
                        {tabs.map((t) => {
                            if(!t.disabled) {
                                t.disabled = false;
                            }
                            return <Tab key={t.id} label={t.label} icon={t.icon} iconPosition="start" value={t.id} disabled={t.disabled} />
                        })}
                    </Tabs>
                </Box>
                <Box sx={{ mt: 2.5 }}>
                    {selectedTab == "tab-assessment" && 
                        <ClientDetailsAssessmentRoot 
                            model={model} 
                            clientId={clientId} 
                            consentData={consentData} 
                            selectedAssessment={selectedAssessment}
                            setSelectedAssessment={changeAssessment}
                            lastAssessment={lastAssessment}
                            hasValidConsent={hasValidConsent} 
                            selectedClientPeriod={selectedClientPeriod} 
                            setSelectedTab={setSelectedTab} />
                    }
                    {selectedTab == "tab-assessment-history" && 
                        <ClientDetailsAssessmentHistory model={model} clientId={clientId} setSelectedTab={setSelectedTab} setSelectedAssessment={setSelectedAssessment} />
                    }
                    {selectedTab == "tab-accounts" && 
                        <ClientDetailsAccounts model={model} clientId={clientId} />
                    }
                    {selectedTab == "tab-transactions" && 
                        <ClientDetailsTransactions model={model} clientId={clientId} />
                    }
                    {selectedTab == "tab-client-profile" &&
                        <ClientDetailsProfile model={model} clientId={clientId} handleEdit={handleEdit} handleDelete={handleDelete} handleStatus={handleChangeStatus} />
                    }
                    {selectedTab == "tab-bank-access" && 
                        <ClientDetailBankAccess model={model} clientId={clientId} tokenData={clientTokenData} clientData={data} />
                    }
                    {selectedTab == "tab-scans" && 
                        <ClientDetailsBankScans model={model} clientId={clientId} />
                    }
                    {selectedTab == "tab-monitoring" &&
                        <ClientDetailsMonitoring model={model} clientId={clientId} />
                    }
                    {selectedTab == "tab-communications" && 
                        <ClientDetailsCommunications model={model} clientId={clientId} />
                    }
                    {selectedTab == "tab-events" && 
                        <ClientDetailsEvents model={model} clientId={clientId} />
                    }
                    {selectedTab == "tab-logs" && 
                        <ClientDetailsSessions model={model} clientId={clientId} />
                    }
                </Box>
                <Dialog maxWidth="sm" fullWidth onClose={handleAddCancel} open={add} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                    {add && <AddClient model={model} customer={customer} onCancel={handleAddCancel} onSaved={onEditedClient} />}
                </Dialog>
                <Dialog maxWidth="sm" fullWidth onClose={handleDeleteCancel} open={del} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                    {del && <DeleteClient customer={customer} onCancel={handleDeleteCancel} onSaved={onDeletedClient} />}
                </Dialog>
                <Dialog maxWidth="sm" fullWidth onClose={handleChangeStatusCancel} open={stat} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
                    {stat && <ChangeClientStatus model={model} customer={customer} onCancel={handleChangeStatusCancel} onSaved={handleChangeStatusSaved} />}
                </Dialog>
            </MainCard>
        </Box>
    );
}