import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useAuth from "hooks/useAuth";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UrlUtils } from "utils/UrlUtils";
import DashboardViewModel from "./model/DashboardViewModel";
import BankList from "./ui/BankList";
import MitigateStats from "./ui/MitigateStats";
import WelcomeBox from "./ui/WelcomeBox";

export default() => {
    const theme = useTheme();
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const dashboardData = useSelector(state => DashboardViewModel.loadFromStore(state));
    const model = new DashboardViewModel(dashboardData, context.user);
    useMemo(() => {
        model?.getData(dispatcher, true);
    }, [dispatcher]);
    const data = model.getDefaultList();
    if(!data) {
        return "";
    }
    const hasMitigateStats = data?.data?.mitigateStats;
    const topDivision = hasMitigateStats ? 6 : 12;
    const logoImageUrl = UrlUtils.getImageUrlFromId(data?.data?.clientLogoId);
    return (
        <Box>
            <Grid container spacing={0} justifyContent="space-between" alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="h2">{t('dashboard_title')}</Typography>
                </Grid>
                {logoImageUrl && 
                <Grid item xs={2}>
                    <Stack direction="row" justifyContent="end">
                        <Box component="img" src={logoImageUrl} alt={"client logo"} sx={{ width: '110px'}} />
                    </Stack>
                </Grid>
                }
            </Grid>
            <Stack sx={{mt: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={topDivision} sm={topDivision} md={topDivision} lg={topDivision}>
                        <WelcomeBox model={model} data={data?.data} />
                    </Grid>
                    {hasMitigateStats && 
                    <>
                    <Grid item xs={topDivision} sm={topDivision} md={topDivision} lg={topDivision}>
                        <MitigateStats model={model} data={data?.data} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <BankList model={model} banks={data?.data?.banks} />
                    </Grid>
                    </>
                    }
                </Grid>
            </Stack>
        </Box>
        );
}