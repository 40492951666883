// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import client from './client';
import access from './access';
import dashboard from './dashboard';
import common from './common';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  common,
  client,
  access,
  dashboard
});

export default reducers;
