import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';

import { openSnackbar } from 'store/reducers/snackbar';

import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { config as apiConfig, selectedProfile } from '../../config/config';
import GridTextItem from 'components/statosfera/common/GridTextItem';

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const ExportUser = ({ model, paginationData, onCancel, onSaved }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [exportData, setExportData] = useState(null);

  useEffect(() => {
    if(!isSubmitting && !isDone) {
        setSubmitting(true);
        model.createExportToken(dispatch, paginationData).unwrap().then(res => {
            setIsDone(true);
            setSubmitting(false);
            if(res.success && res.data?.token) {
                dispatch(openSnackbar({open: true, message: t('export_link_created'), variant: 'alert', alert: {color: 'success'}, close: false}));
                setExportData(res.data);
            }
            else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
            }
        });
    }
  });

  const handleDownload = () => {
    if(exportData?.token) {
        const downloadUrl = apiConfig[selectedProfile].PUBLIC_API_BASE_URL+"/data/export/"+exportData?.token;
        window.open(downloadUrl, '_blank', 'noreferrer');
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogTitle>{t('export_users')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {isSubmitting && 
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <CircularProgress color="primary" sx={{ml: 2, mt: 2}} />
                  <Typography sx={{pt: 2}}>{t('loading')}</Typography>
                </Stack>
                }
                {!isSubmitting && exportData && 
                    <Grid container spacing={3}>
                        <GridTextItem title={t('file_name')} data={model.dataOrEmpty(exportData.fileName)} />
                        <GridTextItem title={t('link_valid_until')} data={model.formatShortDate(exportData.validUntil)} />
                        <Grid item xs={12} md={12}>
                            <Button variant="contained" startIcon={<CloudDownloadOutlined />} onClick={handleDownload}>
                            {t('download')}
                            </Button>
                        </Grid>
                    </Grid>
                }
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="primary" onClick={onCancel}>
                    {t('close')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>

      </LocalizationProvider>
  );
};

export default ExportUser;
