import { UnorderedListOutlined } from "@ant-design/icons";
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import GridTextItem from "components/statosfera/common/GridTextItem";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { StringUtils } from "utils/StringUtils";

export default({model, clientId, selectedScan, onShowErrors}) => {
    const theme = useTheme();
    const context = useAuth();
    const dispatcher = useDispatch();
    const [t, i18n] = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        setLoaded(false);
        setData(null);
        model.fetchClientScanDetails(dispatcher, clientId, selectedScan.id).unwrap().then(res => {
            setLoaded(true);
            if(res?.success) {
                setData(res?.data);
            }
        });
      }, [selectedScan, dispatcher, clientId]);
    if(!loaded || !data) {
        return "";
    }
    const modalStyle = {
        overflowY: 'scroll',
    };
    return(
        <Box sx={modalStyle}>
            <DialogTitle>{t('scan_details')}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
                <Typography variant="h5">{t('scan_basic_data')}</Typography>
                <List sx={{ py: 0 }}>
                    <ListItem divider={true}>
                        <Grid container spacing={3}>
                            <GridTextItem title={t('id')} data={data?.scanData.id} md={6} />
                            <GridTextItem title={t('label')} data={data?.scanData.label} md={6} />
                        </Grid>
                    </ListItem>
                    <ListItem divider={true}>
                        <Grid container spacing={3}>
                            <GridTextItem title={t('bank')} data={data?.scanData.bankName} md={6} />
                            <GridTextItem title={t('status')} data={data?.scanData.status} md={6} />
                        </Grid>
                    </ListItem>
                    <ListItem divider={true}>
                        <GridTextItem title={t('created')} data={model.formatShortDate(data?.scanData.createdAt)} md={12} />
                    </ListItem>
                </List>
                <Typography variant="h5" sx={{mt: 2}}>{t('scan_files')}</Typography>
                {data?.data.map(i => {
                    let numberOfWarnings = i.numberOfWarnings;
                    if(numberOfWarnings === null) {
                        numberOfWarnings = 0;
                    }
                    const showWarningBox = (numberOfWarnings > 0) ? 'visible' : 'hidden';
                    let numberOfErrors = i.numberOfErrors; 
                    if(numberOfErrors === null) {
                        numberOfErrors = 0;
                    }
                    const showErrorBox = (numberOfErrors > 0) ? 'visible' : 'hidden';
                    return(
                        <List key={'file_'+i.id} sx={{ py: 0 }}>
                            <ListItem divider={false}>
                                <Grid container spacing={3}>
                                    <GridTextItem title={t('file_name')} data={i.fileName} md={6} />
                                    <GridTextItem title={t('file_size')} data={StringUtils.convertFileSize(t, i.fileSizeBytes)} md={6} />
                                </Grid>
                            </ListItem>
                            <ListItem divider={false}>
                                <Grid container spacing={3}>
                                    <GridTextItem title={t('scan_upload_status')} data={StringUtils.scanUploadStatus(t, i.uploadStatus)} md={6} />
                                    <GridTextItem title={t('scan_conversion_status')} data={StringUtils.scanConversionStatus(t, i.conversionStatus)} md={6} />
                                </Grid>
                            </ListItem>
                            <ListItem divider={false}>
                                <Grid container spacing={3}>
                                    <GridTextItem title={t('scan_processed_status')} data={StringUtils.scanProcessedStatus(t, i.processStatus)} md={6} />
                                    <GridTextItem title={t('scan_processed_transactions')} data={i.numberOfTransactions} md={6} />
                                </Grid>
                            </ListItem>
                            <ListItem divider={false}>
                                <GridTextItem title={t('scan_statement_id')} data={model.dataOrEmpty(i.statementId)} md={6} />
                                <GridTextItem title={t('scan_statement_created_at')} data={model.formatShortDateNoTime(i.statementCreatedAt)} md={6} />
                            </ListItem>
                            <ListItem divider={false}>
                                <Grid container spacing={3}>
                                    <GridTextItem title={t('scan_statement_from')} data={model.formatShortDateNoTime(i.statementFrom)} md={6} />
                                    <GridTextItem title={t('scan_statement_to')} data={model.formatShortDateNoTime(i.statementTo)} md={6} />
                                </Grid>
                            </ListItem>
                            <ListItem divider={true}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('number_of_errors')}</Typography>
                                            <Box>
                                                {numberOfErrors} 
                                                <IconButton color="secondary" onClick={() => {onShowErrors("errors", data)}} sx={{visibility: showErrorBox}}>
                                                    <Tooltip title={t('show_errors')}>
                                                        <UnorderedListOutlined />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={0.5}>
                                            <Typography color="secondary">{t('number_of_warnings')}</Typography>
                                            <Box>
                                                {numberOfWarnings} 
                                                <IconButton color="secondary" onClick={() => {onShowErrors("warnings", data)}} sx={{visibility: showWarningBox}}>
                                                    <Tooltip title={t('show_warnings')}>
                                                        <UnorderedListOutlined />
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    );
                })
                }
            </DialogContent>
        </Box>
    );
}