import { format, parseISO } from "date-fns";

class Client {

    constructor(d) {
        return Object.assign(this, d);
    }

    getFullName() {
        return this.displayName;
    }

    getCreatedAt() {
        return format(parseISO(this.createdAt), "yyyy. MM. dd. HH:mm:ss");
    }

}

export default Client;