import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import { openSnackbar } from 'store/reducers/snackbar';


import useAuth from 'hooks/useAuth';
import { addUserRequest, editUserRequest } from 'store/reducers/access';
import { useTranslation } from 'react-i18next';

import { ConnectedFocusError } from 'focus-formik-error'

const PRODUCT_MITIGATE = "MITIGATE";
const PRODUCT_RECASH = "RECASH";
const PRODUCT_PROFOUND = "PROFOUND";

// constant
const getInitialValues = (customer) => {
  const newCustomer = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    language: 'hu',
    groupId: 0,
    scopeTemplateIdMitigate: 0,
    scopeTemplateIdRecash: 0,
    scopeTemplateIdProfound: 0,
    password: '',
    passwordagain: '',
  };

  if (customer) {
    newCustomer.id = customer.id;
    if(customer.scopeTemplates) {
      for(let i in customer.scopeTemplates) {
        let d = customer.scopeTemplates[i];
        if(d.product == PRODUCT_MITIGATE) {
          newCustomer.scopeTemplateIdMitigate = d.id;
        }
        else if(d.product == PRODUCT_RECASH) {
          newCustomer.scopeTemplateIdRecash = d.id;
        }
        else if(d.product == PRODUCT_PROFOUND) {
          newCustomer.scopeTemplateIdProfound = d.id;
        }
      }
    }
    if(!customer.groupId) {
      newCustomer.groupId = 0;
      delete customer.groupId;
    }
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

const AddUser = ({ model, customer, onCancel, onSaved }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const isCreating = !customer;
  
  const groupList = model.getGroupList();
  const scopeTemplateList = model.getScopeTemplateList();
  const productList = Object.keys(scopeTemplateList);
  const hasMultipleProducts = productList.length > 1;
  const hasMitigateProduct = productList.includes(PRODUCT_MITIGATE);
  const hasRecashProduct = productList.includes(PRODUCT_RECASH);
  const hasProfoundProduct = productList.includes(PRODUCT_PROFOUND);

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const phoneRules = /^\+(\d).{10}$/;

  const testPairOfTemplates = (a, b) => {
    return a > 0 || b > 0;
  }

  const testTemplate = (hasProduct, hasMultipleProducts) => {
    return {
      is: () => hasProduct == true,
      then: Yup.number().min(1, hasMultipleProducts ? t('at_least_once_template_required') : t('scope_is_required'))
    };
  }

  const scopeConditionalTest = (hasProduct, hasMultipleProducts) => {
    return {
      is: testPairOfTemplates,
      then: Yup.number(),
      otherwise: Yup.number().when([], testTemplate(hasProduct, hasMultipleProducts))
    };
  }

  const ClientSchema = Yup.object().shape({
    email: Yup.string().max(255).required(t('email_is_required')).email(t('must_be_valid_email')),
    phone: Yup.string().max(30).matches(phoneRules, { message: t('phone_format_error') }).nullable(),
    lastName: Yup.string().max(255).required(t('last_name_is_required')),
    firstName: Yup.string().max(255).required(t('first_name_is_required')),
    language: Yup.string().required(t('language_is_required')),
    groupId: Yup.number(),
    scopeTemplateIdMitigate: Yup.number().when(['scopeTemplateIdRecash', 'scopeTemplateIdProfound'], scopeConditionalTest(hasMitigateProduct, hasMultipleProducts)),
    scopeTemplateIdRecash: Yup.number().when(['scopeTemplateIdMitigate', 'scopeTemplateIdProfound'], scopeConditionalTest(hasRecashProduct, hasMultipleProducts)),
    scopeTemplateIdProfound: Yup.number().when(['scopeTemplateIdMitigate', 'scopeTemplateIdRecash'], scopeConditionalTest(hasProfoundProduct, hasMultipleProducts)),
    password: Yup.string()
      .min(8, t('must_be_min_8_characters'))
      .matches(passwordRules, { message: t('password_complexity_error') })
      .max(255)
      .when([], {is: () => isCreating === true, then: Yup.string().required(t('password_is_required'))}),
    passwordagain: Yup.string()
      .max(255)
      .oneOf([Yup.ref("password"), null], t('password_match_error'))
      .when([], {is: () => isCreating === true, then: Yup.string().required(t('password_again_is_required'))}),
  }, [
    ["scopeTemplateIdMitigate", "scopeTemplateIdRecash"],
    ["scopeTemplateIdMitigate", "scopeTemplateIdProfound"],
    ["scopeTemplateIdRecash", "scopeTemplateIdMitigate"],
    ["scopeTemplateIdRecash", "scopeTemplateIdProfound"],
    ["scopeTemplateIdProfound", "scopeTemplateIdMitigate"],
    ["scopeTemplateIdProfound", "scopeTemplateIdRecash"]
  ]);

  const formik = useFormik({
    initialValues: getInitialValues(customer),
    validationSchema: ClientSchema,
    onSubmit: (values, { setSubmitting }) => {
        try {
          values.token = context.user?.token;
          const selectedTemplates = [];
          if(values.scopeTemplateIdMitigate > 0) {
            selectedTemplates.push(values.scopeTemplateIdMitigate);
          }
          delete values.scopeTemplateIdMitigate;
          if(values.scopeTemplateIdRecash > 0) {
            selectedTemplates.push(values.scopeTemplateIdRecash);
          }
          delete values.scopeTemplateIdRecash;
          if(values.scopeTemplateIdProfound > 0) {
            selectedTemplates.push(values.scopeTemplateIdProfound);
          }
          delete values.scopeTemplateIdProfound;
          values.scopeTemplateIds = selectedTemplates;
          if (customer) {
            values.id = customer.id;
            dispatch(editUserRequest(values)).unwrap().then(res => {
              setSubmitting(false);
              if(res.success) {
                dispatch(openSnackbar({open: true, message: t('user_edited'), variant: 'alert', alert: {color: 'success'}, close: false}));
                if(onSaved) {
                  onSaved();
                }
                onCancel();
              }
              else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
              }
            });
          } else {
            dispatch(addUserRequest(values)).unwrap().then(res => {
              setSubmitting(false);
              if(res.success) {
                dispatch(openSnackbar({open: true, message: t('user_added'), variant: 'alert', alert: {color: 'success'}, close: false}));
                if(onSaved) {
                  onSaved();
                }
                onCancel();
              }
              else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
              }
            });
          }
        } catch (error) {
          console.error(error);
        }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{customer ? t('edit_user') : t('new_user')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
          <ConnectedFocusError />
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="client-email">{t('email')}*</InputLabel>
                      <TextField
                        fullWidth
                        id="client-email"
                        placeholder={t('enter_email_address')}
                        {...getFieldProps('email')}
                        disabled={!isCreating}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid>
                  {isCreating && 
                  <>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="client-password">{t('password')}*</InputLabel>
                        <TextField
                          fullWidth
                          id="client-password"
                          type="password"
                          placeholder={t('enter_password')}
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="client-passwordagain">{t('password_again')}*</InputLabel>
                        <TextField
                          fullWidth
                          id="client-passwordagain"
                          type="password"
                          placeholder={t('enter_password_again')}
                          {...getFieldProps('passwordagain')}
                          error={Boolean(touched.passwordagain && errors.passwordagain)}
                          helperText={touched.passwordagain && errors.passwordagain}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="caption">{t('password_complexity_desc')}</Typography>
                    </Grid>
                  </>
                  }
                  <Grid item xs={12}>
                      <Stack spacing={1.25}>
                      <Divider textAlign="left"><Chip label={t('personal_details')} /></Divider>
                      </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="client-first-name">{t('first_name')}*</InputLabel>
                      <TextField
                        fullWidth
                        id="client-first-name"
                        placeholder={t('enter_first_name')}
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="client-last-name">{t('last_name')}*</InputLabel>
                      <TextField
                        fullWidth
                        id="client-last-name"
                        placeholder={t('enter_last_name')}
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="client-phone">{t('phone')}</InputLabel>
                      <TextField
                        fullWidth
                        id="client-phone"
                        placeholder={t('enter_phone')}
                        {...getFieldProps('phone')}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    </Stack>
                  </Grid>
                  
                  <Grid item xs={12}>
                      <Stack spacing={1.25}>
                      <Divider textAlign="left"><Chip label={t('access_settings')} /></Divider>
                      </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="group-label" htmlFor="group">{t('group')}</InputLabel>
                      <Select
                        labelId="group-label"
                        id="group"
                        label="group"
                        {...getFieldProps('groupId')}
                        error={Boolean(touched.groupId && errors.groupId)}
                      >
                        <MenuItem key="group_0" value={0}>-</MenuItem>
                        {groupList.map(item => {
                          return <MenuItem key={'group_'+item.id} value={item.id}>{item.name}</MenuItem>
                        })}
                      </Select>
                    </Stack>
                  </Grid>
                  {hasMitigateProduct && 
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="scopeTemplateIdMitigate-label" htmlFor="scopeTemplateIdMitigate">{hasMultipleProducts ? t('access_scope_mitigate') : t('access_scope')}</InputLabel>
                      <Select
                        labelId="scopeTemplateIdMitigate-label"
                        id="scopeTemplateIdMitigate"
                        label="scopeTemplateIdMitigate"
                        {...getFieldProps('scopeTemplateIdMitigate')}
                        error={Boolean(touched.scopeTemplateIdMitigate && errors.scopeTemplateIdMitigate)}
                      >
                        <MenuItem key={'scope_template_mitigate_empty'} value={0}>-</MenuItem>
                        {scopeTemplateList[PRODUCT_MITIGATE] && scopeTemplateList[PRODUCT_MITIGATE].map(item => {
                          return <MenuItem key={'scope_template_'+item.id} value={item.id}>{item.name}</MenuItem>
                        })}
                      </Select>
                      {formik.touched.scopeTemplateIdMitigate && formik.errors.scopeTemplateIdMitigate ? (
                        <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
                            {formik.touched.scopeTemplateIdMitigate && formik.errors.scopeTemplateIdMitigate}
                        </FormHelperText>
                      ) : null}
                    </Stack>
                  </Grid>
                  }
                  {hasRecashProduct && 
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="scopeTemplateIdRecash-label" htmlFor="scopeTemplateIdRecash">{hasMultipleProducts ? t('access_scope_recash') : t('access_scope')}</InputLabel>
                      <Select
                        labelId="scopeTemplateIdRecash-label"
                        id="scopeTemplateIdRecash"
                        label="scopeTemplateIdRecash"
                        {...getFieldProps('scopeTemplateIdRecash')}
                        error={Boolean(touched.scopeTemplateIdRecash && errors.scopeTemplateIdRecash)}
                      >
                        <MenuItem key={'scope_template_recash_empty'} value={0}>-</MenuItem>
                        {scopeTemplateList[PRODUCT_RECASH] && scopeTemplateList[PRODUCT_RECASH].map(item => {
                          return <MenuItem key={'scope_template_'+item.id} value={item.id}>{item.name}</MenuItem>
                        })}
                      </Select>
                    </Stack>
                  </Grid>
                  }
                  {hasProfoundProduct && 
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="scopeTemplateIdProfound-label" htmlFor="scopeTemplateIdProfound">{hasMultipleProducts ? t('access_scope_profound') : t('access_scope')}</InputLabel>
                      <Select
                        labelId="scopeTemplateIdProfound-label"
                        id="scopeTemplateIdProfound"
                        label="scopeTemplateIdProfound"
                        {...getFieldProps('scopeTemplateIdProfound')}
                        error={Boolean(touched.scopeTemplateIdProfound && errors.scopeTemplateIdProfound)}
                      >
                        <MenuItem key={'scope_template_profound_empty'} value={0}>-</MenuItem>
                        {scopeTemplateList[PRODUCT_PROFOUND] && scopeTemplateList[PRODUCT_PROFOUND].map(item => {
                          return <MenuItem key={'scope_template_'+item.id} value={item.id}>{item.name}</MenuItem>
                        })}
                      </Select>
                    </Stack>
                  </Grid>
                  }

                  <Grid item xs={12}>
                      <Stack spacing={1.25}>
                      <Divider textAlign="left"><Chip label={t('other_settings')} /></Divider>
                      </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="language-label" htmlFor="language">{t('language')}*</InputLabel>
                      <Select
                        labelId="language-label"
                        id="language"
                        label="Language"
                        {...getFieldProps('language')}
                        error={Boolean(touched.language && errors.language)}
                      >
                        <MenuItem value={'hu'}>{t('lang_hu')}</MenuItem>
                        <MenuItem value={'en'}>{t('lang_en')}</MenuItem>
                      </Select>
                    </Stack>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {customer ? t('edit') : t('add')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

AddUser.propTypes = {
  customer: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddUser;
