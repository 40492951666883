import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';

import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { StringUtils } from 'utils/StringUtils';
import GridTextItem from 'components/statosfera/common/GridTextItem';

const ScanDataDetails = ({model, clientId, data, showLoader, onCancel }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DialogTitle>{t('scan_data_status')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <List sx={{ py: 0 }}>
                <ListItem divider={true}>
                    <Grid container spacing={3}>
                        <GridTextItem title={t('id')} data={data?.id} md={6} />
                        <GridTextItem title={t('scan_id')} data={data?.scanId} md={6} />
                    </Grid>
                </ListItem>
                <ListItem divider={true}>
                    <Grid container spacing={3}>
                        <GridTextItem title={t('label')} data={data?.label} md={6} />
                        <GridTextItem title={t('file_name')} data={data?.originalFileName} md={6} />
                    </Grid>
                </ListItem>
                <ListItem divider={true}>
                    <Grid container spacing={3}>
                        <GridTextItem title={t('scan_upload_status')} data={StringUtils.scanUploadStatus(t, data?.uploadStatus)} md={4} />
                        <GridTextItem title={t('scan_conversion_status')} data={StringUtils.scanConversionStatus(t, data?.conversionStatus)} md={4} />
                        <GridTextItem title={t('scan_processed_status')} data={StringUtils.scanProcessedStatus(t, data?.processStatus)} md={4} />
                    </Grid>
                </ListItem>
                <ListItem divider={false}>
                    <Grid container spacing={3}>
                        <GridTextItem title={t('scan_processed_transactions')} data={model.dataOrEmpty(data?.numberOfProcessedTransactions)} md={4} />
                        <GridTextItem title={t('number_of_errors')} data={data?.numberOfErrors} md={4} />
                        <GridTextItem title={t('number_of_warnings')} data={data?.numberOfWarnings} md={4} />
                    </Grid>
                </ListItem>
            </List>
            {showLoader && 
            <Stack direction={"row"} alignItems="center" spacing={2}>
                <CircularProgress color="primary" sx={{ml: 2, mt: 2}} />
                <Typography sx={{pt: 2}}>{t('loading')}</Typography>
            </Stack>
            }
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    {t('close')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>

      </LocalizationProvider>
  );
};

export default ScanDataDetails;
