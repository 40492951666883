import { CloseOutlined } from "@ant-design/icons";
import { Box, DialogTitle, Grid, IconButton, Modal, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

export default({model, onClose, open, transaction}) => {
    const [t, i18n] = useTranslation();
    const theme = useTheme();
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };
    if(!transaction) {
        return "";
    }
    return(
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ borderBottom: `0px solid ${theme.palette.divider}` }}>
                    <Grid item>
                        <DialogTitle>Transaction details</DialogTitle>
                    </Grid>
                    <Grid item sx={{ mr: 1.5 }}>
                    <IconButton color="secondary" onClick={onClose}>
                        <CloseOutlined />
                    </IconButton>
                    </Grid>
                </Grid>
                <Box sx={{p: 2}}>
                    <Typography variant="h6" component="h2"><b>ID:</b> {transaction.id}</Typography>
                    <Typography variant="h6" component="h2"><b>Category:</b> {transaction.category}</Typography>
                    <Typography variant="h6" component="h2"><b>Type:</b> {transaction.type}</Typography>
                    <Typography variant="h6" component="h2" sx={{mt: 2}}><b>Account Id:</b> {transaction.accountId}</Typography>
                    <Typography variant="h6" component="h2"><b>Amount:</b> {model.formatCurrency(transaction.amount, transaction.currency)}</Typography>
                    <Typography variant="h6" component="h2"><b>Status:</b> {transaction.status}</Typography>
                    <Typography variant="h6" component="h2" sx={{mt: 2}}><b>Debtor name:</b> {model.dataOrEmpty(transaction.debtorName)}</Typography>
                    <Typography variant="h6" component="h2"><b>Debtor account:</b> {model.dataOrEmpty(transaction.debtorAccount)}</Typography>
                    <Typography variant="h6" component="h2"><b>Creditor account:</b> {model.dataOrEmpty(transaction.creditorAccount)}</Typography>
                    <Typography variant="h6" component="h2"><b>Creditor name:</b> {model.dataOrEmpty(transaction.creditorName)}</Typography>
                    <Typography variant="h6" component="h2" sx={{mt: 2}}><b>Transaction ID:</b> {transaction.transactionId}</Typography>
                    <Typography variant="h6" component="h2"><b>Transaction Date:</b> {model.formatShortDate(transaction.transactionDate)}</Typography>
                    <Typography variant="h6" component="h2"><b>Transaction Information:</b> {transaction.unstructuredInformation}</Typography>
                </Box>
            </Box>
        </Modal>
    );
}