import { Grid, ListItem } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import GridTextItem from "components/statosfera/common/GridTextItem";

export default({model, data}) => {
    const [t, i18n] = useTranslation();
    const stats = data?.mitigateStats;
    return(
        <MainCard title={t('mitigate_statistics')} sx={{minHeight: '300px'}}>

            <ListItem divider={true}>
                <Grid container spacing={3}>
                    <GridTextItem title={t('all_clients')} data={model.dataOrEmpty(stats.numOfAllClients)} md={6} />
                    <GridTextItem title={t('own_clients')} data={model.dataOrEmpty(stats.numOfOwnClients)} md={6} />
                </Grid>
            </ListItem>

            <ListItem divider={true}>
                <Grid container spacing={3}>
                    <GridTextItem title={t('new_clients')} data={model.dataOrEmpty(stats.newClients)} md={6} />
                    <GridTextItem title={t('processed_clients')} data={model.dataOrEmpty(stats.processedClients)} md={6} />
                </Grid>
            </ListItem>

            <ListItem divider={false}>
                <GridTextItem title={t('last_client_added')} data={model.formatShortDate(stats.lastClientAdded)} />
            </ListItem>

        </MainCard>
    );
}