import { EyeOutlined } from "@ant-design/icons";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default({model, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    const [showState, setShowState] = useState(false);
    const buttonText = !showState? "Show" : "Hide";
    let fraudData = chartData?.data?.fraudData;
    if(!fraudData) {
        return "";
    }
    return(
        <MainCard title="Fraud" contentSX={{padding: 0}}>
            <Button onClick={() => setShowState(!showState)} sx={{marginTop: 2, marginLeft: 2}} variant="shadow" color="info" endIcon={<EyeOutlined />}>{buttonText}</Button>
            <Box sx={{display: showState ? 'block' : 'none'}}>
                <Table>
                    <TableBody>
                        <TableRow>
                        <TableCell>
                            <Typography variant="h5">Számlatuladonos ellenőrzés</Typography>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h5">Egyező számlák</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>
                        {fraudData.matchedBankAccountHolderNames.length == 0 &&
                        <Box>Nincs adat</Box>
                        }
                        {fraudData.matchedBankAccountHolderNames.length > 0 && 
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Számlaszám</TableCell>
                                <TableCell>Számatulajdonos neve (eredeti)</TableCell>
                                <TableCell>Számatulajdonos neve (átalakított)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fraudData.matchedBankAccountHolderNames && fraudData.matchedBankAccountHolderNames.map(i => {
                                    return(
                                        <TableRow key={'fraud_account_'+i.accountNumber}>
                                            <TableCell>{i.accountNumber}</TableCell>
                                            <TableCell>{i.accountHolderNameOriginal}</TableCell>
                                            <TableCell>{i.accountHolderNameMatched}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        }
                        </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h5">Nem egyező számlák</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>
                        {fraudData.notMatchedBankAccountHolderNames.length == 0&&
                        <Box>Nincs adat</Box>
                        }
                        {fraudData.notMatchedBankAccountHolderNames.length > 0 &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                <TableCell>Számlaszám</TableCell>
                                <TableCell>Számatulajdonos neve (eredeti)</TableCell>
                                <TableCell>Számatulajdonos neve (átalakított)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {fraudData.notMatchedBankAccountHolderNames && fraudData.notMatchedBankAccountHolderNames.map(i => {
                                    return(
                                        <TableRow key={'fraud_account_'+i.accountNumber}>
                                            <TableCell>{i.accountNumber}</TableCell>
                                            <TableCell>{i.accountHolderNameOriginal}</TableCell>
                                            <TableCell>{i.accountHolderNameMatched}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {!fraudData.notMatchedBankAccountHolderNames &&
                                <TableRow key={'fraud_account_notmatches_nomatch'}>
                                    <TableCell colspan={3}>No data</TableCell>
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                        }
                        </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </MainCard>
    );
}