import { Box, Tooltip } from "@mui/material";
import { hasStatusScopes, scopes } from "config/scopes";
import { format, parseISO } from "date-fns";
import { StringUtils } from "utils/StringUtils";
import UserModel from "../entity/UserModel";

class BaseViewModel {

    static numberFormat = new Intl.NumberFormat('hu-HU', {
        maximumFractionDigits: 0
    });

    constructor(userModel) {
        /*if(userModel && typeof(userModel) != UserModel) {
            throw new Error("User is not UserModel");
        }*/
        this.user = userModel;
        this.hiddenFields = [];
    }

    getScopes() {
        return (this.user?.scopes) ? this.user.scopes : [];
    }

    hasScope(scope) {
        return this.user?.scopes?.includes(scope);
    }

    hasRightToChangeClientStatus(nextStatuses) {
        if(!this.hasScope(scopes.SCOPE_MITIGATE_CHANGE_STATUS_CLIENT)) {
            return false;
        }
        return hasStatusScopes(this.getScopes(), nextStatuses);
    }

    /*notify(dispatcher, type, message) {
        dispatcher(commonActions.notify({type, message}));
    }*/

    createAction(id, icon, text) {
        let data = {id};
        if(icon) {
            data.icon = icon;
        }
        else if(text) {
            data.text = text;
        }
        return data;
    }

    dataOrEmpty(val) {
        return (val ? val : "-");
    }

    limitData(data, maxLength) {
        if(!data) return "-";
        data = data.trim();
        if(data.length == 0) {
            return "-";
        }
        if(data.length <= maxLength) {
            return data;
        }
        return <Tooltip title={data}>
            <Box>
                {data.substring(0, maxLength - 3)}...
            </Box>
        </Tooltip>
    }

    addLeadingZero(input) {
        if(input < 10) return "0"+input;
        return input;
    }

    formatShortDate(date) {
        if(!date) {
            return "-";
        }
        return format(parseISO(date), "yyyy. MM. dd. HH:mm");
    }

    formatShortDateNoTime(date) {
        if(!date) {
            return "-";
        }
        return format(parseISO(date), "yyyy. MM. dd.");
    }

    formatShorterDate(date) {
        if(!date) {
            return "-";
        }
        return format(parseISO(date), "MM. dd.");
    }

    formatCurrency(amount, currency) {
        if(amount == null || amount === undefined) {
            return "-";
        }
        let formatted = BaseViewModel.numberFormat.format(amount);
        if(currency) {
            formatted = StringUtils.getCurrencyShortName(formatted, currency);
        }
        return formatted;
    }

    setHiddenFields(hiddenFields) {
        this.hiddenFields = hiddenFields;
    }

    isFieldHidden(fieldId) {
        let isHidden = this.hiddenFields.includes(fieldId);
        return {
          display: isHidden ? 'none' : 'block'
        };
      }

      isAllFieldHidden(fieldIds) {
        let isHidden = false;
        for(let i in fieldIds) {
          if(!this.hiddenFields.includes(fieldIds[i])) {
            isHidden = true;
          }
        }
        return {
          display: !isHidden ? 'none' : 'block'
        };
      }

    setNativeValue(el, value) {
        if(!el) {
            return;
        }
        const previousValue = el.value;
        if (el.type === 'radio') {
          if ((!!value && !el.checked) || (!!value && el.checked)) {
            el.click();
          }
        } else el.value = value;
      
        const tracker = el._valueTracker;
        if (tracker) {
          tracker.setValue(previousValue);
        }
      
        // 'change' instead of 'input', see https://github.com/facebook/react/issues/11488#issuecomment-381590324
        el.dispatchEvent(new Event('change', { bubbles: true }));
      }
}

export default BaseViewModel;