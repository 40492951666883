class DashboardConfigurationData {
    
    static CLIENT_TYPE_PERSONAL = "PERSONAL";
    static CLIENT_TYPE_BUSINESS = "BUSINESS";
    static CLIENT_TYPE_BOTH = "BOTH";

    static SETTING_ALLOWED_CLIENT_TYPES = "allowedClientTypes";
    static SETTING_DEFAULT_CLIENT_TYPE = "defaultClientType";
    static SETTING_DEFAULT_CURRENCY = "defaultClientCurrency";
    static SETTING_DEFAULT_LANGUAGE = "defaultClientLanguage";

    static SETTING_DEFAULT_CLIENT_ADDRESS_COUNTRY_ID = "defaultAddressCountryId";
    static SETTING_DEFAULT_CLIENT_CITIZENSHIP_COUNTRY_ID = "defaultCitizenshipCountryId";
    static SETTING_DEFAULT_CLIENT_TAXRESIDENCY_COUNTRY_ID = "defaultTaxResidencyCountryId";

    static LIST_ID_CLIENTS = "client_list";
    static VIEW_ID_CLIENTS_VIEW = "client_view";

    static FORM_ID_ADD_CLIENT = "add_client_form";

    static FORM_CONFIGURATION_DEFAULT_CLIENT_CURRENCY = "defaultClientCurrency";

    static FORM_CONFIGURATION_DEFAULT_MONTHLY_PAYMENT = "defaultMonthlyPayment";
    static FORM_CONFIGURATION_MIN_MONTHLY_PAYMENT = "minMonthlyPayment";
    static FORM_CONFIGURATION_MAX_MONTHLY_PAYMENT = "maxMonthlyPayment";

    static FORM_CONFIGURATION_DEFAULT_MONTHLY_DEDUCTION = "defaultMonthlyDeduction";
    static FORM_CONFIGURATION_MIN_MONTHLY_DEDUCTION = "minMonthlyDeduction";
    static FORM_CONFIGURATION_MAX_MONTHLY_DEDUCTION = "maxMonthlyDeduction";

    static FORM_CONFIGURATION_DEFAULT_MONTHLY_ADDITION = "defaultMonthlyAddition";
    static FORM_CONFIGURATION_MIN_MONTHLY_ADDITION = "minMonthlyAddition";
    static FORM_CONFIGURATION_MAX_MONTHLY_ADDITION = "maxMonthlyAddition";

    constructor(config, clienFormId, clientViewId) {
        this.config = config;
        this.clienFormId = clienFormId;
        this.clientViewId = clientViewId;
    }

    getSetting(fieldId, defaultValue) {
        if(!fieldId) {
            return defaultValue;
        }
        if(!this.config?.globalSettings) {
            return defaultValue;
        }
        if(!Object.keys(this.config?.globalSettings).includes(fieldId)) {
            return defaultValue;
        }
        return this.config?.globalSettings[fieldId];
    }

    getViewSetting(fieldId, defaultValue) {
        if(!fieldId) {
            return defaultValue;
        }
        if(!this.config?.views) {
            return defaultValue;
        }
        let viewData = this.getViewById(this.clientViewId);
        if(!viewData) {
            return defaultValue;
        }
        if(!Object.keys(viewData).includes(fieldId)) {
            return defaultValue;
        }
        return viewData[fieldId];
    }

    getFormFields(fieldId, clientType, defaultValue) {
        if(!fieldId || !clientType) {
            return defaultValue;
        }
        if(!this.config?.forms) {
            return defaultValue;
        }
        let formData = this.getFormById(this.clienFormId);
        if(!formData) {
            return defaultValue;
        }
        let clientTypeInternal = (clientType == "PERSONAL") ? "personalFields" : "businessFields";
        if(!formData[clientTypeInternal] || !Object.keys(formData[clientTypeInternal]).includes(fieldId)) {
            return defaultValue;
        }
        return formData[clientTypeInternal][fieldId];
    }

    getFormSetting(fieldId, clientType, defaultValue) {
        if(!fieldId || !clientType) {
            return defaultValue;
        }
        if(!this.config?.forms) {
            return defaultValue;
        }
        let formData = this.getFormById(this.clienFormId);
        if(!formData) {
            return defaultValue;
        }
        let clientTypeInternal = (clientType == "PERSONAL") ? "personalSettings" : "businessSettings";
        if(!formData[clientTypeInternal] || !Object.keys(formData[clientTypeInternal]).includes(fieldId)) {
            return defaultValue;
        }
        return formData[clientTypeInternal][fieldId];
    }

    isRequiredField(fieldId, clientType, defaultState = true) {
        return this.testField(this.clienFormId, fieldId, clientType, "requiredFields", defaultState);
    }

    isHiddenField(fieldId, clientType, defaultState = false) {
        return this.testField(this.clienFormId, fieldId, clientType, "hiddenFields", defaultState);
    }

    //Internal
    testField(id, fieldId, clientType, bundle, defaultState = true) {
        if(!id || !fieldId || !clientType || !bundle) {
            return defaultState;
        }
        if(!this.config?.forms) {
            return defaultState;
        }
        let formData = this.getFormById(id);
        if(!formData) {
            return defaultState;
        }
        let clientTypeInternal = (clientType == "PERSONAL") ? "personalFields" : "businessFields";
        if(!formData[clientTypeInternal] || !formData[clientTypeInternal][bundle]) {
            return defaultState;
        }
        let filteredField = formData[clientTypeInternal][bundle].filter(i => i.id == fieldId);
        if(!filteredField || filteredField.length < 1) {
            return defaultState;
        }
        return true;
    }

    getViewById(id) {
        let filtered = this.config.views.filter(i => i.id == id);
        return (filtered && filtered.length > 0) ? filtered[0] : null;
    }

    getFormById(id) {
        let filtered = this.config.forms.filter(i => i.id == id);
        return (filtered && filtered.length > 0) ? filtered[0] : null;
    }

}

export default DashboardConfigurationData;