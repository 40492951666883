import { format, parseISO } from "date-fns";

class Group {

    constructor(d) {
        return Object.assign(this, d);
    }

    getCreatedAt() {
        return format(parseISO(this.createdAt), "yyyy. MM. dd. HH:mm:ss");
    }

}

export default Group;