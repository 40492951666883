import { useMemo, useState, useCallback } from 'react';
import { scopes, hasScope } from '../../config/scopes';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Chip,
  Dialog,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Box,
  Button,
  Select,
  MenuItem
} from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';

// project import
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { IndeterminateCheckbox } from 'components/third-party/ReactTable';

// assets
import { CloseOutlined, PlusOutlined, EyeTwoTone, EditTwoTone, DeleteTwoTone, UserDeleteOutlined, UserAddOutlined, DownloadOutlined } from '@ant-design/icons';
import AddUser from './AddUser';
import { useSelector } from 'store';
import { useDispatch } from 'store';
import { useNavigate } from 'react-router';
import ReactTable from 'components/statosfera/table/ReactTable';
import { resetClient } from 'store/reducers/client';
import useAuth from 'hooks/useAuth';
import { format, parseISO } from 'date-fns';
import UserListViewModel from './model/UserListViewModel';
import DeleteUser from './DeleteUser';
import FastUserView from './FastUserView';
import BlockUser from './BlockUser';
import { useTranslation } from 'react-i18next';
import ClientListPaginationData from 'pages/clients/model/ClientListPaginationData';
import useLocalStorage from 'hooks/useLocalStorage';
import ExportUser from './ExportUser';

export default() => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const userList = useSelector(state => UserListViewModel.loadFromStore(state));
    const model = new UserListViewModel(userList, context.user);
    const canManageUsers = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_USERS);
    const canDeleteUsers = model.hasScope(scopes.SCOPE_MITIGATE_DELETE_USERS);
    const canSeeAllUsers = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_ALL_USERS);
    const canSeeOwnUsers = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_OWN_USERS);
    const canManageGroups = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_GROUPS);
    const canExportUsers = true;

    const [listType, setListType] = useLocalStorage("user_page_list_type", canSeeAllUsers ? "ALL" : "OWN");
    const [listSize, setListSize] = useLocalStorage("user_page_list_size", 10);
    const [filter, setFilter] = useState(model.getDefaultFilter(listSize, listType));
  
    useMemo(() => {
      model?.getData(dispatcher, true, filter);
    }, [dispatcher, filter]);
    const data = model.getDefaultList();

    const [customer, setCustomer] = useState(null);
    const [add, setAdd] = useState(false);
    const [del, setDel] = useState(false);
    const [block, setBlock] = useState(false);
    const [exp, setExport] = useState(false);

    let handleAdd = () => {
      setAdd(!add);
      if (customer && !add) setCustomer(null);
    };
    if(!canManageUsers) {
      handleAdd = null;
    }

    const handleDelete = () => {
      setDel(!del);
      if (customer && !del) setCustomer(null);
    }

    const handleDeleteCancel = () => {
      setDel(!del);
      if (customer && !del) setCustomer(null);
      filter.page = 0;
      setFilter(ClientListPaginationData.from(filter));
    }

  const handleBlock = () => {
    setBlock(!block);
    if (customer && !block) setCustomer(null);
  }

  const handleBlockCancel = () => {
    setBlock(!block);
    if (customer && !block) setCustomer(null);
    filter.page = 0;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleAddCancel = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setAdd(!add);
    if (customer && !add) setCustomer(null);
    filter.page = 0;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handlePageChange = (data) => {
    filter.page = data;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handlePageSizeChange = (data) => {
    filter.limit = data;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleSort = (id, desc) => {
    let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
    if(filter.orderBy == id && sameDir) {
      return;
    }
    filter.orderBy = id;
    filter.order = (desc) ? "desc" : "asc";
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleSearch = (data) => {
    filter.search = data;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleGroups = (data) => {
    dispatcher(resetClient());
    navigate("/groups");
  }

  const handleChangeListType = (e) => {
    setListType(e.target.value);
    filter.listType = e.target.value;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleExportUsers = () => {
    setExport(true);
  }

  const handleExportCancel = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setExport(false);
  }

  const columns = useMemo(
    () => [
      /*{
        title: 'Row Selection',
        // eslint-disable-next-line
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'selection',
        // eslint-disable-next-line
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },*/
      {
        Header: '#',
        accessor: 'id',
        className: 'cell-center'
      },
      {
        Header: t('group'),
        accessor: 'groupName',
        Cell: ({ value }) => {
          return model.limitData(value, 16);
        }
      },
      {
        Header: t('email'),
        accessor: 'email',
        Cell: ({ value }) => {
          return model.limitData(value, 32);
        }
      },
      {
        Header: t('name'),
        accessor: 'displayName',
        Cell: ({ value }) => {
          return model.limitData(value, 32);
        }
      },
      /*{
        Header: t('phone'),
        accessor: 'phone',
        // eslint-disable-next-line
        Cell: ({ value }) => {
          if(!value) return "-";
          return <PatternFormat displayType="text" format="+## (##) ## ## ###" mask="" defaultValue={value} />;
        }
      },*/
      {
        Header: t('created'),
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return format(parseISO(value), "yyyy. MM. dd. HH:mm:ss")
        }
      },
      {
        Header: t('status'),
        accessor: 'status',
        // eslint-disable-next-line
        Cell: ({ value }) => {
          switch (value) {
            default:
            case 'CREATED':
              return <Chip color="secondary" label={t('created')} size="small" variant="light" />;
            case 'BANNED':
              return <Chip color="error" label={t('banned')} size="small" variant="light" />;
            case 'VERIFIED':
              return <Chip color="success" label={t('verified')} size="small" variant="light" />;
            case 'ACTIVE':
              return <Chip color="primary" label={t('active')} size="small" variant="light" />;
          }
        }
      },
      {
        Header: t('actions'),
        className: 'cell-center',
        disableSortBy: true,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          const theme = useTheme();
          // eslint-disable-next-line
          const { values, isExpanded, toggleRowExpanded } = row;
          const collapseIcon = isExpanded ? (
            <CloseOutlined style={{ color: theme.palette.error.main }} />
          ) : (
            <EyeTwoTone twoToneColor={theme.palette.secondary.main} />
          );
          const blockIcon = (values.status == "BANNED") ? (
            <UserDeleteOutlined />
          ) : (
            <UserAddOutlined />
          );
          const blockText = (values.status == "BANNED") ? t('unblock') : t('block');
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t('view')}>
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleRowExpanded();
                  }}
                >
                  {collapseIcon}
                </IconButton>
              </Tooltip>
              {canManageUsers && 
              <Tooltip title={t('edit')}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomer(row.original);
                    handleAdd(values.id);
                  }}
                >
                  <EditTwoTone twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              }
              {canManageUsers && 
              <Tooltip title={blockText}>
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomer(row.original);
                    handleBlock(values.id);
                  }}
                >
                  {blockIcon}
                </IconButton>
              </Tooltip>
              }
              {canDeleteUsers && 
              <Tooltip title={t('delete')}>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomer(row.original);
                    handleDelete(values.id);
                  }}
                >
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                </IconButton>
              </Tooltip>
              }
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const renderRowSubComponent = useCallback(({ row }) => <FastUserView model={model} data={data[row.id]} />, [data]);
  const sortBy = { id: 'createdAt', desc: true };

  const buttonArea = function() {
    return(<>
      <Stack direction={'row'} alignItems="center" spacing={1}>
      {canSeeAllUsers && canSeeOwnUsers && 
        <Stack spacing={1.25}>
          <Select sx={{width: '200px'}} labelId="list-filter-label" id="list-filter" label="Filter" value={listType} onChange={handleChangeListType}>
            <MenuItem value={'ALL'}>{t('all_users')}</MenuItem>
            <MenuItem value={'OWN'}>{t('own_users')}</MenuItem>
          </Select>
        </Stack>
        }
        {canExportUsers && 
        <Button variant="contained" startIcon={<DownloadOutlined />} onClick={handleExportUsers}>
          {t('export_users')}
        </Button>
        }
        {canManageGroups && 
        <Button variant="contained" onClick={handleGroups}>{t('groups')}</Button>
        }
        {canManageUsers && 
        <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAdd}>
            {t('add_user')}
        </Button>
        }
      </Stack>
    </>);
  }

  return (
    <Box>
      <Grid item sx={{ mt: 0.25, mb: 2 }}>
          <Typography variant="h2">{t('access')}</Typography>
      </Grid>
      <MainCard content={false}>
      <ScrollX>
        <ReactTable
          columns={columns}
          data={data}
          page={filter.page}
          pageSize={filter.limit}
          sortBy={sortBy}
          search={''}
          rowCountTotal={model.getNumOfItems()} 
          handleAdd={null}
          buttonArea={buttonArea}
          getHeaderProps={(column) => column.getSortByToggleProps()}
          renderRowSubComponent={renderRowSubComponent}
          handlePageChange={handlePageChange} 
          handlePageSizeChange={handlePageSizeChange}
          handleSort={handleSort}
          handleSearch={handleSearch} />
      </ScrollX>

      {/* add customer dialog */}
      <Dialog maxWidth="sm" fullWidth onClose={handleAddCancel} open={add} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {add && <AddUser model={model} customer={customer} onCancel={handleAddCancel} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleDeleteCancel} open={del} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {del && <DeleteUser customer={customer} onCancel={handleDeleteCancel} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleBlockCancel} open={block} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {block && <BlockUser customer={customer} onCancel={handleBlockCancel} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleExportCancel} open={exp} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {exp && <ExportUser model={model} paginationData={ClientListPaginationData.from(filter)} onCancel={handleExportCancel} />}
      </Dialog>
    </MainCard>
    </Box>
  );
}