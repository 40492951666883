import { useMemo, useState, useCallback } from 'react';
import { scopes, hasScope, hasStatusScopes } from '../../config/scopes';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Dialog,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Box,
  Button,
  Select,
  MenuItem
} from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';

// project import
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';

// assets
import { CloseOutlined, PlusOutlined, EyeTwoTone, EditTwoTone, DeleteTwoTone, DownloadOutlined } from '@ant-design/icons';
import AddClient from './AddClient';
import ClientListViewModel from './model/ClientListViewModel';
import { useSelector } from 'store';
import { useDispatch } from 'store';
import { useNavigate } from 'react-router';
import ReactTable from 'components/statosfera/table/ReactTable';
import { resetClient } from 'store/reducers/client';
import useAuth from 'hooks/useAuth';
import { format, parseISO } from 'date-fns';
import DeleteClient from './DeleteClient';
import { useTranslation } from 'react-i18next';
import ClientListPaginationData from './model/ClientListPaginationData';
import useLocalStorage from 'hooks/useLocalStorage';
import { StringUtils } from 'utils/StringUtils';
import ExportClient from './ExportClient';
import ChangeClientStatus from './ChangeClientStatus';
import DashboardConfigurationData from 'model/dto/DashboardConfigurationData';

const dialogStyle = { '& .MuiDialog-paper': { p: 0 } };

const filterByStatus = ({t, statusType, handleChangeStatusType}) => {
  return(
    <Select sx={{width: '200px'}} id="list-filter-status" label="Filter by status" value={statusType} onChange={handleChangeStatusType}>
      <MenuItem value={'ALL'}>{t('status_types_all')}</MenuItem>
      <MenuItem value={'ACTIVE'}>{t('status_types_active')}</MenuItem>
      <MenuItem value={'INACTIVE'}>{t('status_types_inactive')}</MenuItem>
      <MenuItem value={'NEW'}>{StringUtils.clientStatusChip(t, 'NEW', null, null)}</MenuItem>
      <MenuItem value={'LINK_GENERATED'}>{StringUtils.clientStatusChip(t, 'LINK_GENERATED', null, null)}</MenuItem>
      <MenuItem value={'PORTAL_OPENED'}>{StringUtils.clientStatusChip(t, 'PORTAL_OPENED', null, null)}</MenuItem>
      <MenuItem value={'CONSENT_ADDED'}>{StringUtils.clientStatusChip(t, 'CONSENT_ADDED', null, null)}</MenuItem>
      <MenuItem value={'IN_PROGRESS'}>{StringUtils.clientStatusChip(t, 'IN_PROGRESS', null, null)}</MenuItem>
      <MenuItem value={'APPROVED'}>{StringUtils.clientStatusChip(t, 'APPROVED', null, null)}</MenuItem>
      <MenuItem value={'REJECTED'}>{StringUtils.clientStatusChip(t, 'REJECTED', null, null)}</MenuItem>
      <MenuItem value={'ERROR'}>{StringUtils.clientStatusChip(t, 'ERROR', null, null)}</MenuItem>
      <MenuItem value={'FINISHED'}>{StringUtils.clientStatusChip(t, 'FINISHED', null, null)}</MenuItem>
      <MenuItem value={'CLOSED'}>{StringUtils.clientStatusChip(t, 'CLOSED', null, null)}</MenuItem>
    </Select>
  )
}

const CustomerList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const context = useAuth();
  const [t, i18n] = useTranslation();

  const configuration = new DashboardConfigurationData(context.user?.configuration, null, DashboardConfigurationData.LIST_ID_CLIENTS);
  const clientTypePersonal = DashboardConfigurationData.CLIENT_TYPE_PERSONAL;
  const clientTypeBusiness = DashboardConfigurationData.CLIENT_TYPE_BUSINESS;
  const hiddenFields = configuration.getViewSetting("personalHiddenFields", ['phone','clientType','maxAllowedMonthlyPayment','externalId','updatedAt']);

  const clientList = useSelector(state => ClientListViewModel.loadFromStore(state));
  const model = new ClientListViewModel(clientList, context.user);

  const canManageClients = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_CLIENT);
  const canDeleteClients = model.hasScope(scopes.SCOPE_MITIGATE_DELETE_CLIENT);
  const canEditClients = model.hasScope(scopes.SCOPE_MITIGATE_EDIT_CLIENT);
  const canSeeAllClients = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_ALL_CLIENTS);
  const canSeeOwnClients = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_OWN_CLIENTS);
  const canExportClients = model.hasScope(scopes.SCOPE_MITIGATE_EXPORT_CLIENTS);
  
  const [listType, setListType] = useLocalStorage("client_page_list_type", canSeeAllClients ? "ALL" : "OWN");
  const [listSize, setListSize] = useLocalStorage("client_page_list_size", configuration.getViewSetting("pageSize", 10));
  const [statusType, setStatusType] = useLocalStorage("client_page_list_status_type", "ALL");
  const [listSortDir, setListSortDir] = useLocalStorage("client_page_list_sort_dir", configuration.getViewSetting("sortDir", "desc"));
  const [listSortField, setListSortField] = useLocalStorage("client_page_list_field", configuration.getViewSetting("sortField", "createdAt"));
  const [filter, setFilter] = useState(model.getDefaultFilter(listSize, listType, statusType, listSortDir, listSortField));
  useMemo(() => {
    model?.getData(dispatcher, true, filter);
  }, [dispatcher, filter]);
  const data = model.getDefaultList();

  const [customer, setCustomer] = useState(null);
  const [add, setAdd] = useState(false);
  const [del, setDel] = useState(false);
  const [exp, setExport] = useState(false);
  const [stat, setStat] = useState(false);

  let handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  const handleDelete = () => {
    setDel(!del);
    if (customer && !del) setCustomer(null);
  }

  const handleDeleteCancel = () => {
    setDel(!del);
    if (customer && !del) setCustomer(null);
    filter.page = 0;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleAddCancel = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setAdd(!add);
    if (customer && !add) setCustomer(null);
    filter.page = 0;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handlePageChange = (data) => {
    filter.page = data;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handlePageSizeChange = (data) => {
    filter.limit = data;
    setFilter(ClientListPaginationData.from(filter));
    setListSize(data);
  }

  const handleSort = (id, desc) => {
    let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
    if(filter.orderBy == id && sameDir) {
      return;
    }
    filter.orderBy = id;
    filter.order = (desc) ? "desc" : "asc";
    setFilter(ClientListPaginationData.from(filter));
    setListSortDir(filter.order);
    setListSortField(filter.orderBy);
  }

  const handleSearch = (data) => {
    filter.search = data;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleClientSelected = (data) => {
    dispatcher(resetClient());
    navigate("/mitigate/client/"+data);
  }

  const navigateToClient = (id) => {
    if(!id) return;
    navigate("/mitigate/client/"+id);
  } 

  const onNewClientSaved = (id) => {
    if(!id) return;
    navigate("/mitigate/client/"+id+"#tab-bank-access");
  }

  const handleChangeListType = (e) => {
    setListType(e.target.value);
    filter.listType = e.target.value;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleChangeStatusType = (e) => {
    setStatusType(e.target.value);
    filter.statusType = e.target.value;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleExportClients = () => {
    setExport(true);
  }

  const handleExportCancel = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setExport(false);
  }

  const handleChangeStatusCancel = (e) => {
    setStat(false);
  }

  const handleChangeStatusSaved = () => {
    setStat(false);
    filter.page = 0;
    setFilter(ClientListPaginationData.from(filter));
  }

  const handleCustomerEdit = async (customer) => {
    let clientData = await model.fetchDetails(dispatcher, customer.id).unwrap();
    if(clientData.success) {
      setCustomer(clientData.data);
      handleAdd(clientData.data?.id);
    }
  }

  const openIcon = <EyeTwoTone twoToneColor={theme.palette.secondary.main} />;
  const closeIcon = <CloseOutlined style={{ color: theme.palette.error.main }} />;
  const editIcon = <EditTwoTone twoToneColor={theme.palette.primary.main} />;
  const deleteIcon = <DeleteTwoTone twoToneColor={theme.palette.error.main} />;

  const columns = useMemo(
    () => [
      /*{
        title: 'Row Selection',
        // eslint-disable-next-line
        Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
        accessor: 'selection',
        // eslint-disable-next-line
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        disableSortBy: true
      },*/
      {
        Header: '#',
        accessor: 'id',
        className: 'cell-center'
      },
      {
        Header: t('email'),
        accessor: 'email',
        Cell: ({ value }) => {
          return model.limitData(value, 32);
        }
      },
      {
        Header: t('name'),
        accessor: 'displayName',
        Cell: ({ value }) => {
          return model.limitData(value, 32);
        }
      },
      {
        Header: t('phone'),
        accessor: 'phone',
        // eslint-disable-next-line
        Cell: ({ value }) => {
          if(!value) return "-";
          return <PatternFormat displayType="text" format="+## (##) ## ## ###" mask="" defaultValue={value} />;
        }
      },
      {
        Header: t('status'),
        id: 'status',
        disableSortBy: true,
        //accessor: 'status',
        // eslint-disable-next-line
        Cell: ({ row }) => {
          const { values, isExpanded, toggleRowExpanded } = row;
          return StringUtils.clientStatusChip(t, row.original.status, row.original.adminComment, (e) => {
            if(!model.hasRightToChangeClientStatus(row.original?.nextStatuses)) return;
            e.stopPropagation();
            setCustomer(row.original);
            setStat(true);
          });
        }
      },
      {
        Header: t('client_type'),
        accessor: 'clientType',
        disableSortBy: false,
        Cell: ({ value }) => {
          return (value == clientTypePersonal) ? t('client_type_personal') : t('client_type_business');
        },
        show: false
      },
      {
        Header: t('last_score'),
        accessor: 'lastScore',
        disableSortBy: true,
        Cell: ({ row }) => {
          const { values, isExpanded, toggleRowExpanded } = row;
          const toolTipText = values?.lastScore ? values?.lastScore.totalScore+'/100' : "-";
          return <Tooltip title={toolTipText}><Box>{StringUtils.getRiskRating(t, values?.lastScore?.scoreClass)}</Box></Tooltip>
        }
      },
      {
        Header: t('monthly_payment_title'),
        accessor: 'maxAllowedMonthlyPayment',
        disableSortBy: false,
        Cell: ({ row }) => {
          const { values, isExpanded, toggleRowExpanded } = row;
          return model.formatCurrency(row.original?.maxAllowedMonthlyPayment, row.original?.currency);
        }
      },
      {
        Header: t('external_id'),
        accessor: 'externalId',
        Cell: ({ value }) => {
          return model.limitData(value, 24);
        }
      },
      {
        Header: t('created'),
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return format(parseISO(value), "yyyy. MM. dd. HH:mm:ss")
        }
      },
      {
        Header: t('updated'),
        accessor: 'updatedAt',
        Cell: ({ value }) => {
          return format(parseISO(value), "yyyy. MM. dd. HH:mm:ss")
        }
      },
      {
        Header: t('actions'),
        className: 'cell-center',
        disableSortBy: true,
        // eslint-disable-next-line
        Cell: ({ row }) => {
          // eslint-disable-next-line
          const { values, isExpanded, toggleRowExpanded } = row;
          const collapseIcon = isExpanded ? closeIcon : openIcon;
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t('view')}>
                <IconButton
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateToClient(values.id);
                    //toggleRowExpanded();
                  }}
                >
                  {collapseIcon}
                </IconButton>
              </Tooltip>
              {canEditClients && 
              <Tooltip title={t('edit')}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCustomerEdit(row.original);
                    //setCustomer(row.original);
                    //handleAdd(values.id);
                  }}
                >
                  {editIcon}
                </IconButton>
              </Tooltip>
              }
              {canDeleteClients && 
              <Tooltip title={t('delete')}>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCustomer(row.original);
                    handleDelete(values.id);
                  }}
                >
                  {deleteIcon}
                </IconButton>
              </Tooltip>
              }
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme]
  );

  const sortBy = { id: filter.orderBy, desc: filter.order == "desc" };

  const buttonArea = function() {
    return(<>
      <Stack direction={'row'} alignItems="center" spacing={1}>
        {canSeeAllClients && canSeeOwnClients && 
        <Stack spacing={1.25}>
          <Select sx={{width: '200px'}} labelId="list-filter-label" id="list-filter" label="Filter by type" value={listType} onChange={handleChangeListType}>
            <MenuItem value={'ALL'}>{t('all_clients')}</MenuItem>
            <MenuItem value={'OWN'}>{t('own_clients')}</MenuItem>
          </Select>
        </Stack>
        }
        <Stack spacing={1.25}>
          {filterByStatus({t, statusType, handleChangeStatusType})}
        </Stack>
        <>
         {canExportClients && 
          <Button variant="contained" startIcon={<DownloadOutlined />} onClick={handleExportClients}>
            {t('export_clients')}
          </Button>
          }
          {canManageClients && 
          <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAdd}>
            {t('add_client')}
          </Button>
          }
        </>
      </Stack>
    </>);
  }

  return (
    <Box>
      <Grid item sx={{ mt: 0.25, mb: 2 }}>
          <Typography variant="h2">{t('clients')}</Typography>
      </Grid>
      <MainCard content={false}>
      <ScrollX>
        <ReactTable
          columns={columns}
          data={data}
          page={filter.page}
          pageSize={filter.limit}
          sortBy={sortBy}
          search={''}
          rowCountTotal={model.getNumOfItems()} 
          handleAdd={null}
          hiddenColumns={hiddenFields}
          buttonArea={buttonArea}
          getHeaderProps={(column) => column.getSortByToggleProps()}
          handlePageChange={handlePageChange} 
          handlePageSizeChange={handlePageSizeChange}
          handleSort={handleSort}
          handleSearch={handleSearch} />
      </ScrollX>
      <Dialog maxWidth="sm" fullWidth onClose={handleAddCancel} open={add} sx={dialogStyle}>
        {add && <AddClient model={model} customer={customer} onCancel={handleAddCancel} onSaved={onNewClientSaved} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleDeleteCancel} open={del} sx={dialogStyle}>
        {del && <DeleteClient customer={customer} onCancel={handleDeleteCancel} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleExportCancel} open={exp} sx={dialogStyle}>
        {exp && <ExportClient model={model} paginationData={ClientListPaginationData.from(filter)} onCancel={handleExportCancel} />}
      </Dialog>
      <Dialog maxWidth="sm" fullWidth onClose={handleChangeStatusCancel} open={stat} sx={dialogStyle}>
        {stat && <ChangeClientStatus model={model} customer={customer} onCancel={handleChangeStatusCancel} onSaved={handleChangeStatusSaved} />}
      </Dialog>
    </MainCard>
    </Box>
  );
};

export default CustomerList;
