import { config as apiConfig, selectedProfile } from '../config/config';

class UrlUtilsC {

    getImageUrl(path) {
        /*if(path.startsWith("/") || path.startsWith('\\')) {
            path = path.substring(1, path.length);
        }*/
        return apiConfig[selectedProfile].STATIC_API_BASE_URL+path;
    }

    getImageUrlFromId(path) {
        if(!path) return null;
        return apiConfig[selectedProfile].STATIC_API_BASE_URL+"/image/"+path;
    }

    getBankGuideUrl(bankId) {
        const baseUrl = "https://doc.statosfera.com/global/doc/bank/";
        switch(bankId) {
            default:
                return null;
            case 14:
                return baseUrl+"revolut.html";
            case 9:  
                return baseUrl+"otp.html";
            case 17:
                return baseUrl+"raiffeisen.html";
            case 15:
                return baseUrl+"unicredit.html";
            case 19:
                return baseUrl+"kh.html";
            case 13:
                return baseUrl+"granit.html";
            case 21:
                return baseUrl+"cib.html";
            
        }
    }

}

const UrlUtils = new UrlUtilsC();

export {
    UrlUtils
}