class PaginationData {

    static from(item) {
        return new PaginationData(item.page, item.limit, item.order, item.orderBy, item.search);
    }

    constructor(page, limit, order, orderBy, search) {
        this.page = page;
        this.limit = limit;
        this.order = order;
        this.orderBy = orderBy;
        this.search = search;
        this.reload = false;
    }

    getQueryData() {
        let url = "?" + "page=" + (this.page+1) + "&limit=" + this.limit + "&order=" + this.order + "&sortBy=" + this.orderBy;
        if(this.search) {
            url += "&search=" + this.search;
        }
        return url;
    }

    getHeaderData() {
        return {
            page: this.page,
            limit: this.limit,
            search: this.search,
            order: this.order,
            orderBy: this.orderBy
        };
    }

}

export default PaginationData;