import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({model, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    //Income
    if(!chartData?.data?.incomeData) {
      return "";
    }
    const greenIncomeSmallest = chartData?.data.incomeData.greenIncomeSmallest ? model.formatCurrency(parseFloat(chartData?.data.incomeData.greenIncomeSmallest.amount), personalData?.currency) : "n.a.";
    const yellowIncomeSmallest = chartData?.data.incomeData.yellowIncomeSmallest ? model.formatCurrency(parseFloat(chartData?.data.incomeData.yellowIncomeSmallest.amount), personalData?.currency) : "n.a.";
    const totalGreenIncome = chartData?.data.incomeData.totalGreenIncome;
    const totalYellowIncome = chartData?.data.incomeData.totalYellowIncome;
    const totalRedIncome = chartData?.data.incomeData.totalRedIncome;
    const totalUnderLimitIncome = chartData?.data.incomeData.totalUnderLimitIncome;
    return(
        <MainCard title={t('income_analysis')} sx={{minHeight:'360px'}} contentSX={{padding: 0}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="div">{t('income_type')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="div">{t('monthly_income')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="div">{t('total_income')}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="div" style={{color: '#00A854', fontWeight: 'bold'}}>{t('income_green')}</Typography>
              </TableCell>
              <TableCell>
                {greenIncomeSmallest}
              </TableCell>
              <TableCell>
              {model.formatCurrency(totalGreenIncome, personalData?.currency)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="div" style={{color: '#FFBF00', fontWeight: 'bold'}}>{t('income_yellow')}</Typography>
              </TableCell>
              <TableCell>
                {yellowIncomeSmallest}
              </TableCell>
              <TableCell>
              {model.formatCurrency(totalYellowIncome, personalData?.currency)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="div" style={{color: '#F04134', fontWeight: 'bold'}}>{t('income_red')}</Typography>
              </TableCell>
              <TableCell>
                -
              </TableCell>
              <TableCell>
              {model.formatCurrency(totalRedIncome, personalData?.currency)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
              <Typography variant="div" style={{fontWeight: 'bold'}}>{t('income_under_limit')}</Typography>
              </TableCell>
              <TableCell>
                -
              </TableCell>
              <TableCell>
              {model.formatCurrency(totalUnderLimitIncome, personalData?.currency)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </MainCard>        
    );
}