import { Grid, Table, TableBody, TableCell, TableRow, TableHead, Chip, Box, Link, Stack } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";
import {UrlUtils} from 'utils/UrlUtils';

export default({model, banks}) => {
    const [t, i18n] = useTranslation();
    const hasAnyBanks = banks?.length > 0;
    return(
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard title={t('banks')}>
                    {!hasAnyBanks && 
                        <>{t('no_data')}</>
                    }
                    {hasAnyBanks && 
                         <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '10%'}}>{t('logo')}</TableCell>
                                    <TableCell>{t('name')}</TableCell>
                                    <TableCell>{t('api_status')}</TableCell>
                                    <TableCell>{t('bank_tutorial')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {banks?.map(i => {
                                const logoImageUrl = UrlUtils.getImageUrl(i.logo?.url);
                                const statusChip = (i.status == "ACTIVE") ? 
                                    <Chip color="success" label={t('active')} size="small" variant="light" /> : 
                                    <Chip color="error" label={t('error')} size="small" variant="light" />;
                                const scannerStatusChip = (i.scannerStatus == "ACTIVE") ? 
                                    <Chip color="success" label={t('active')} size="small" variant="light" /> : 
                                    <Chip color="error" label={t('error')} size="small" variant="light" />;
                                let bankGuideUrl = UrlUtils.getBankGuideUrl(i.id);
                                let otpUrls = null;
                                if(i.id == 9) {
                                    bankGuideUrl = null;
                                    otpUrls = <Stack direction={"row"} spacing={2}>
                                            <Link href="https://doc.statosfera.com/global/doc/bank/otp_direkt.html" underline="always" target="_blank" rel="noopener noreferrer">Link (OTP Direkt)</Link>
                                            <Link href="https://doc.statosfera.com/global/doc/bank/otp_mobil.html" underline="always" target="_blank" rel="noopener noreferrer">Link (OTP Mobil)</Link>
                                        </Stack>
                                }
                                return(
                                    <TableRow key={'bank_'+i.id}>
                                        <TableCell sx={{width: '10%'}}>
                                            <Box component="img" src={logoImageUrl} alt={i.name+" logo"} sx={{ height: "50px", width: "auto" }} />
                                        </TableCell>
                                        <TableCell>{i.name}</TableCell>
                                        <TableCell>{statusChip}</TableCell>
                                        <TableCell>
                                            {bankGuideUrl && 
                                                <Link href={bankGuideUrl} underline="always" target="_blank" rel="noopener noreferrer">Link</Link>
                                            }
                                            {!otpUrls && !bankGuideUrl && 
                                                <>-</>
                                            }
                                            {otpUrls && 
                                            otpUrls
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    }
                  </MainCard>
                </Grid>
            </Grid>
        </Grid>
    );
}