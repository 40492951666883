import Client from "model/entity/Client";
import TableViewModel from "model/viewmodel/TableViewModel";
import { exportClientsRequest, getClientDetailsEditRequest, getClientsRequest } from "store/reducers/client";
import ClientListPaginationData from "./ClientListPaginationData";

class ClientListViewModel extends TableViewModel {

    static loadFromStore(state) {
        return state.client.clients;
    }

    constructor(listData, userModel) {
        super(userModel);
        this.data = listData;
        this.numOfItems = 0;
        this.clients = [];
        if(listData && listData.data) {
            this.initClients(listData.data.items);
            this.numOfItems = listData.data.numOfItems;
        }
    }

    initClients(data) {
        data.forEach(i => {
            this.clients.push(new Client(i));
        });
    }

    getData(dispatcher, reset, paginationData) {
        if(!this.data || reset) {
            const request = {token: this.user.token, paginationData, timestamp: Date.now()};
            return dispatcher(getClientsRequest(request));
        }
    }

    fetchDetails(dispatcher, clientId) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now()};
        return dispatcher(getClientDetailsEditRequest(request));
    }

    createExportToken(dispatcher, paginationData) {
        const request = {token: this.user.token, paginationData, timestamp: Date.now()};
        return dispatcher(exportClientsRequest(request));
    }

    getDefaultList() {
        return this.clients;
    }

    getNumOfItems() {
        return this.numOfItems;
    }

    getDefaultFilter(defaultPageLength = 50, listType = 'OWN', statusType = 'ALL', sortDir = "desc", sortBy = "createdAt") {
        return new ClientListPaginationData(0, defaultPageLength, sortDir, sortBy, null, listType, statusType);
    }

}

export default ClientListViewModel;