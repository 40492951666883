import ScrollX from "components/ScrollX";
import ReactTable from "components/statosfera/table/ReactTable";
import useAuth from "hooks/useAuth";
import PaginationData from "model/dto/PaginationData";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ClientDetailsViewModel from "../model/ClientDetailsViewModel";

export default({clientId}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const model = new ClientDetailsViewModel(null, context.user);
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getFieldFilter(10, "createdAt"));
    useEffect(() => {
        if(data) {
          setLoaded(false);
          model.fetchClientEvents(dispatcher, clientId, filter).unwrap().then(res => {
            setLoaded(true);
          });
        }
      }, [data, dispatcher, clientId, filter]);
    if(!data) {
        return "";
    }
    const columns = useMemo(
      () => [
        {
          Header: '#',
          accessor: 'id',
          disableSortBy: true
        },
        {
          Header: t('type'),
          accessor: 'type',
          disableSortBy: true
        },
        {
          Header: t('data'),
          accessor: 'data',
          disableSortBy: true
        },
        {
          Header: t('date'),
          accessor: 'createdAt'
        }
      ]);
    const handlePageChange = (data) => {
      filter.page = data;
      setFilter(PaginationData.from(filter));
    }

    const handlePageSizeChange = (data) => {
      filter.limit = data;
      setFilter(PaginationData.from(filter));
    }

    const handleSort = (id, desc) => {
      let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
      if(filter.orderBy == id && sameDir) {
        return;
      }
      filter.orderBy = id;
      filter.order = (desc) ? "desc" : "asc";
      setFilter(PaginationData.from(filter));
    }

    const eventData = useSelector(state => state.client.clientEvents);
    if(!eventData) {
      return "";
    }
    let items = [];
    eventData?.data?.items.forEach(i => {
      items.push({
        id: i.id,
        type: i.type,
        data: i.data,
        createdAt: model.formatShortDate(i.created)
      });
    });
    const sortBy = { id: 'createdAt', desc: true };
    return(
      <>
        <ScrollX>
          <ReactTable
            columns={columns}
            data={items}
            page={filter.page}
            pageSize={filter.limit}
            sortBy={sortBy}
            search={''}
            rowCountTotal={eventData?.data?.numOfItems} 
            getHeaderProps={(column) => column.getSortByToggleProps()}
            handlePageChange={handlePageChange} 
            handlePageSizeChange={handlePageSizeChange}
            handleSort={handleSort}
            handleSearch={null}
            topSpacing={0} />
        </ScrollX>
      </>
    );
}