const selectedProfile = "production";

const config = {

    local: {
        AUTH_API_BASE_URL: "http://localhost:8080",
        PUBLIC_API_BASE_URL: "http://localhost:8081",
        STATIC_API_BASE_URL: "http://localhost:3006", 
        MITIGATE_CLIENT_PORTAL_URL: "http://localhost:3003", 
        OAUTH_CLIENT_ID: "mitigate-dashboard-test-client-id"
    },

    test: {
        AUTH_API_BASE_URL: "https://auth.recash.support",
        PUBLIC_API_BASE_URL: "https://public.recash.support",
        STATIC_API_BASE_URL: "https://static.recash.support", 
        MITIGATE_CLIENT_PORTAL_URL: "https://mitigate.recash.support", 
        OAUTH_CLIENT_ID: "mitigate-dashboard-test-client-id"
    },

    production: {
        AUTH_API_BASE_URL: "https://auth.statosfera.com",
        PUBLIC_API_BASE_URL: "https://public.statosfera.com",
        STATIC_API_BASE_URL: "https://static.statosfera.com", 
        MITIGATE_CLIENT_PORTAL_URL: "https://mitigate.statosfera.com", 
        OAUTH_CLIENT_ID: "mitigate-dashboard-test-client-id"
    }

};

const I18_LANGUAGE = "18Language";

export {
    config, selectedProfile, I18_LANGUAGE
}
export default() => {
    return true;
}