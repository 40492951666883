import TableViewModel from "model/viewmodel/TableViewModel";
import { getDashboardRequest } from "store/reducers/dashboard";

class DashboardViewModel extends TableViewModel {

    static loadFromStore(state) {
        return state.dashboard.dashboard;
    }

    constructor(listData, userModel) {
        super(userModel);
        this.data = listData;
    }

    getData(dispatcher, reset) {
        if(!this.data || reset) {
            const request = {token: this.user.token, timestamp: Date.now()};
            return dispatcher(getDashboardRequest(request));
        }
    }

    getDefaultList() {
        return this.data;
    }
}

export default DashboardViewModel;