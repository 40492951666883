// material-ui
import { useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  Box
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import { useTranslation } from 'react-i18next';
import GridTextItem from 'components/statosfera/common/GridTextItem';

export default({model, data}) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));  
    const [t, i18n] = useTranslation();
    return(
        <TableRow sx={{ '&:hover': { bgcolor: `transparent !important` } }}>
            <TableCell colSpan={8} sx={{ p: 2.5 }}>
                <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 } }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Stack spacing={2.5}>
                            <MainCard title={t('products')} sx={{display: 'none'}}>
                                {data.products && 
                                    <List sx={{ py: 0 }}>
                                    {data.products.map((item, index) => {
                                        return(
                                            <ListItem key={'product_'+index}>
                                                {t('product_'+item.toLowerCase())}
                                            </ListItem>
                                        );
                                    })}
                                    </List>
                                }
                                {!data.products && 
                                    <>-</>
                                }
                            </MainCard>
                            <MainCard title={t('access_rights')}>
                                {data.scopes && 
                                    <List sx={{ py: 0 }}>
                                    {data.scopes.map((item, index) => {
                                        return(
                                            <ListItem key={'access_right_'+index}>
                                                <Tooltip title={item}><Box>{t('scope_'+item)}</Box></Tooltip>
                                            </ListItem>
                                        );
                                    })}
                                    </List>
                                }
                                {!data.scopes && 
                                    <>-</>
                                }
                            </MainCard>
                            <MainCard title={t('personal_details')}>
                                <List sx={{ py: 0 }}>
                                    <ListItem divider={true}>
                                        <GridTextItem title={t('email')} data={data.email} />
                                    </ListItem>
                                    <ListItem divider={true}>
                                        <Grid container spacing={3}>
                                            <GridTextItem title={t('first_name')} data={data.firstName} md={6} />
                                            <GridTextItem title={t('last_name')} data={data.lastName} md={6} />
                                        </Grid>
                                    </ListItem>
                                    <ListItem divider={true}>
                                        <GridTextItem title={t('phone')} data={model.dataOrEmpty(data.phone)} />
                                    </ListItem>
                                    <ListItem divider={false}>
                                        <GridTextItem title={t('language')} data={data.language} />
                                    </ListItem>
                                </List>
                            </MainCard>
                        </Stack>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

}