import TableViewModel from "model/viewmodel/TableViewModel";
import User from "model/entity/User";
import ClientListPaginationData from "pages/clients/model/ClientListPaginationData";

const { getUsersRequest, exportUsersRequest } = require("store/reducers/access");

class UserListViewModel extends TableViewModel {

    static loadFromStore(state) {
        return state.access.users;
    }

    constructor(listData, userModel) {
        super(userModel);
        this.data = listData;
        this.numOfItems = 0;
        this.users = [];
        if(listData && listData.data) {
            this.initUsers(listData.data.items);
            this.numOfItems = listData.data.numOfItems;
        }
    }

    initUsers(data) {
        data.forEach(i => {
            this.users.push(new User(i));
        });
    }

    getData(dispatcher, reset, paginationData) {
        if(!this.data || reset) {
            const request = {token: this.user.token, paginationData, timestamp: Date.now()};
            return dispatcher(getUsersRequest(request));
        }
    }

    createExportToken(dispatcher, paginationData) {
        const request = {token: this.user.token, paginationData, timestamp: Date.now()};
        return dispatcher(exportUsersRequest(request));
    }

    getDefaultList() {
        return this.users;
    }

    getNumOfItems() {
        return this.numOfItems;
    }

    getDefaultFilter(defaultPageLength = 50, listType = "ALL") {
        return new ClientListPaginationData(0, defaultPageLength, "desc", "id", null, listType);
    }

    getGroupList() {
        return this.data?.data?.groups;
    }

    getScopeTemplateList() {
        let byProduct = {};
        for(let i in this.data?.data?.scopeTemplates) {
            let d = this.data?.data?.scopeTemplates[i];
            if(!byProduct[d.product]) {
                byProduct[d.product] = [];
            }
            byProduct[d.product].push(d);
        }
        return byProduct;
    }

}

export default UserListViewModel;