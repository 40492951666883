const scopes = {
    SCOPE_PROFOUND_ADMIN: "profound_admin",
    SCOPE_MITIGATE_ADMIN: "mitigate_admin",
    SCOPE_RECASH_ADMIN: "recash_admin",
    SCOPE_MITIGATE_MANAGE_USERS: 'mitigate_manage_users',
    SCOPE_MITIGATE_DELETE_USERS: 'mitigate_delete_users',
    SCOPE_MITIGATE_MANAGE_ALL_USERS: 'mitigate_manage_all_users',
    SCOPE_MITIGATE_DELETE_ALL_USERS: 'mitigate_delete_all_users',
    SCOPE_MITIGATE_MANAGE_OWN_USERS: 'mitigate_manage_own_users',
    SCOPE_MITIGATE_DELETE_OWN_USERS: 'mitigate_delete_own_users',
    SCOPE_MITIGATE_MANAGE_CLIENT: "mitigate_manage_client",
    SCOPE_MITIGATE_MANAGE_ALL_CLIENTS: "mitigate_manage_all_clients",
    SCOPE_MITIGATE_MANAGE_OWN_CLIENTS: "mitigate_manage_own_clients",
    SCOPE_MITIGATE_VIEW_OWN_CLIENT_DATA: "mitigate_view_own_client_data",
    SCOPE_MITIGATE_VIEW_ALL_CLIENT_DATA: "mitigate_view_all_client_data",
    SCOPE_MITIGATE_VIEW_OWN_CLIENT_DATA_DETAILS: "mitigate_view_own_client_data_details",
    SCOPE_MITIGATE_VIEW_ALL_CLIENT_DATA_DETAILS: "mitigate_view_all_client_data_details",
    SCOPE_MITIGATE_EDIT_CLIENT: "mitigate_edit_client",
    SCOPE_MITIGATE_EDIT_ALL_CLIENTS: "mitigate_edit_all_clients",
    SCOPE_MITIGATE_EDIT_OWN_CLIENTS: "mitigate_edit_own_clients",
    SCOPE_MITIGATE_CHANGE_STATUS_CLIENT: "mitigate_change_status_client",
    SCOPE_MITIGATE_DELETE_CLIENT: "mitigate_delete_client",
    SCOPE_MITIGATE_DELETE_ALL_CLIENTS: "mitigate_delete_all_clients",
    SCOPE_MITIGATE_DELETE_OWN_CLIENTS: "mitigate_delete_own_clients",
    SCOPE_MITIGATE_EXPORT_CLIENTS: "mitigate_export_clients",
    SCOPE_MITIGATE_CAN_APPROVE_CLIENT: "mitigate_can_approve_client",
    SCOPE_MITIGATE_CAN_REJECT_CLIENT: "mitigate_can_reject_client",
    SCOPE_MITIGATE_CAN_FINISH_CLIENT: "mitigate_can_finish_client",
    SCOPE_MITIGATE_CAN_CLOSE_CLIENT: "mitigate_can_close_client",
    SCOPE_MITIGATE_MANAGE_GROUPS: "mitigate_manage_groups",
    SCOPE_MITIGATE_DELETE_GROUPS: "mitigate_delete_groups",
    SCOPE_MITIGATE_ASSESSMENT_VIEW: "mitigate_assessment_view",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_ALL: "mitigate_assessment_view_all",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_SUMMARY_STATS: "mitigate_assessment_view_summary_stats",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_INCOME: "mitigate_assessment_view_income",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_FRAUD: "mitigate_assessment_view_fraud",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_ACCOUNTS: "mitigate_assessment_view_accounts",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_STATS: "mitigate_assessment_view_stats",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_TRANSACTIONS: "mitigate_assessment_view_transactions",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_CERTIFICATE: "mitigate_assessment_view_certificate",
    SCOPE_MITIGATE_ASSESSMENT_VIEW_EXPORT: "mitigate_assessment_view_export",
    SCOPE_MITIGATE_ASSESSMENT_HISTORY: "mitigate_assessment_history",
    SCOPE_MITIGATE_BANK_ACCOUNTS: "mitigate_bank_accounts",
    SCOPE_MITIGATE_BANK_ACCOUNT_DETAILS: "mitigate_bank_account_details",
    SCOPE_MITIGATE_TRANSACTIONS: "mitigate_transactions",
    SCOPE_MITIGATE_BANK_ACCESS: "mitigate_bank_access",
    SCOPE_MITIGATE_MONITORING: "mitigate_monitoring",
    SCOPE_MITIGATE_COMMUNICATIONS: "mitigate_communications",
    SCOPE_MITIGATE_EVENTS: "mitigate_events",
    SCOPE_MITIGATE_LOGS: "mitigate_logs",
    SCOPE_MITIGATE_SCORE_DETAILS: "mitigate_score_details",
    SCOPE_MITIGATE_REQUEST_DATA: "mitigate_request_data",
    SCOPE_MITIGATE_SCANS: "mitigate_scans",
    SCOPE_MITIGATE_MANAGE_SCANS: "mitigate_manage_scans"
};

function hasScope(userScopes, type) {
    if(!userScopes || !scopes[type]) {
        return false;
    }
    const scopeName = scopes[type];
    return userScopes.includes(scopeName);
}

function hasStatusScopes(userScopes, nextClientStatuses) {
    if(!userScopes || !nextClientStatuses) {
        return false;
    }
    let hasAnyRights = false;
    for(let i in nextClientStatuses) {
        let d = nextClientStatuses[i];
        if(d == "APPROVE" && model.hasScope(scopes.SCOPE_MITIGATE_CAN_APPROVE_CLIENT)) {
            hasAnyRights = true;
            break;
        }
        else if(d == "REJECT" && model.hasScope(scopes.SCOPE_MITIGATE_CAN_REJECT_CLIENT)) {
            hasAnyRights = true;
            break;
        }
        else if(d == "FINISH" && model.hasScope(scopes.SCOPE_MITIGATE_CAN_FINISH_CLIENT)) {
            hasAnyRights = true;
            break;
        }
        else if(d == "CLOSE" && model.hasScope(scopes.SCOPE_MITIGATE_CAN_CLOSE_CLIENT)) {
            hasAnyRights = true;
            break;
        }
        else {
            hasAnyRights = true;
        }
    }
    return hasAnyRights;
}

export {
    scopes, hasScope, hasStatusScopes
}