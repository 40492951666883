import { DeleteTwoTone, EyeTwoTone, FileSearchOutlined, PlusOutlined } from "@ant-design/icons";
import { Box, Button, Dialog, Grid, IconButton, Modal, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import ScrollX from "components/ScrollX";
import ReactTable from "components/statosfera/table/ReactTable";
import { hasScope, scopes } from "config/scopes";
import useAuth from "hooks/useAuth";
import PaginationData from "model/dto/PaginationData";
import ClientDetailsViewModel from "pages/clients/model/ClientDetailsViewModel";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StringUtils } from "utils/StringUtils";
import AddScan from "./AddScan";
import DeleteScan from "./DeleteScan";
import ScanDataDetails from "./ScanDataDetails";
import ScanDetails from "./ScanDetails";
import ScanErrorList from "./ScanErrorList";

let queryDataDetailsTimeout = null;

export default({model, clientId}) => {
    const theme = useTheme();
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const canManageScans = model.hasScope(scopes.SCOPE_MITIGATE_MANAGE_SCANS);

    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getDefaultFilter(10, "createdAt"));
    const [selectedScan, setSelectedScan] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedScanData, setSelectedScanData] = useState(null);
    const [showScanDataLoader, setShowScanDataLoader] = useState(false);
    const [openDataDetails, setOpenDataDetails] = useState(false);
    const [openDataDetailsErrors, setOpenDataDetailsErrors] = useState(false);
    const [selectedErrorData, setSelectedErrorData] = useState(null);
    const [selectedErrorType, setSelectedErrorType] = useState(null);
    const [add, setAdd] = useState(false);
    const [del, setDel] = useState(false);
    
    const showDetails = (item) => {
      setSelectedScan(item);
      setOpenDetails(true);
    }
    const hideDetails = () => {
      setOpenDetails(false);
    }

    const showDelete = (e, item) => {
      e.stopPropagation();
      setSelectedScan(item);
      handleDelete(item.id);
    }

    const handleDelete = () => {
      setDel(true);
    }
  
    const handleDeleteCancel = () => {
      setDel(false);
      setSelectedScan(null);
      filter.page = 0;
      setFilter(PaginationData.from(filter));
    }

    const handleScanDetailsCancel = () => {
      setOpenDetails(false);
      setSelectedScan(null);
    }
    
    useEffect(() => {
        if(data) {
          setLoaded(false);
          model.fetchClientScans(dispatcher, clientId, filter).unwrap().then(res => {
            setLoaded(true);
          });
        }
      }, [data, dispatcher, clientId, filter]);
    if(!data) {
        return "";
    }
    const columns = useMemo(
      () => [
        {
          Header: t('id'),
          accessor: 'id'
        },
        {
          Header: t('label'),
          accessor: 'label'
        },
        {
          Header: t('bank'),
          accessor: 'bankName',
          disableSortBy: true
        },
        {
          Header: t('created'),
          accessor: 'createdAt'
        },
        {
          Header: t('status'),
          accessor: 'status'
        },
        {
          Header: t('actions'),
          className: 'cell-center',
          accessor: 'actions',
          disableSortBy: true
        }
      ]);
    const handlePageChange = (data) => {
      filter.page = data;
      setFilter(PaginationData.from(filter));
    }

    const handlePageSizeChange = (data) => {
      filter.limit = data;
      setFilter(PaginationData.from(filter));
    }
  
    const handleSearch = (data) => {
      filter.search = data;
      setFilter(PaginationData.from(filter));
    }

    const handleSort = (id, desc) => {
      let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
      if(filter.orderBy == id && sameDir) {
        return;
      }
      filter.orderBy = id;
      filter.order = (desc) ? "desc" : "asc";
      setFilter(PaginationData.from(filter));
    }

    const handleAdd = () => {
      setSelectedScan(null);
      setAdd(true);
    }

    const handleAddCancel = (event, reason) => {
      if (reason && reason == "backdropClick") {
        return;
      }
      setAdd(false);
    }

    const handleAddSaved = (result) => {
      filter.page = 0;
      setFilter(PaginationData.from(filter));
      if(result?.success) {
        setShowScanDataLoader(true);
        queryScanDataStatus(result?.data?.scanId, result?.data?.scanDataId);
      }
    }

    const queryScanDataStatus = (scanId, dataId) => {
      if(!scanId || !dataId) {
        return;
      }
      model.fetchClientScanDataDetails(dispatcher, clientId, scanId, dataId).unwrap().then(result => {
        if(result?.success && result.data) {
          showDataDetails(result.data);
          queryDataDetailsTimeout = setTimeout(() => {
            queryScanDataStatus(scanId, dataId);
          }, 1000);
        }
      });
    }

    const showDataDetails = (data) => {
      setSelectedScanData(data);
      if(!openDataDetails) {
        setOpenDataDetails(true);
      }
      if(data?.processStatus != "NONE") {
        if(queryDataDetailsTimeout) {
          clearTimeout(queryDataDetailsTimeout);
          queryDataDetailsTimeout = null;
        }
        setShowScanDataLoader(false);
      }
    }

    const handleScanDataDetailsCancel = (event, reason) => {
      if (reason && reason == "backdropClick") {
        return;
      }
      setOpenDataDetails(false);
      setSelectedScanData(null);
      location.reload();
      if(queryDataDetailsTimeout) {
        clearTimeout(queryDataDetailsTimeout);
        queryDataDetailsTimeout = null;
      }
    }

    const handleShowScanErrors = (type, data) => {
      setSelectedErrorData(data);
      setSelectedErrorType(type);
      setOpenDataDetailsErrors(true);
    }

    const handleShowScanErrorsCancel = (event, reason) => {
      setOpenDataDetailsErrors(false);
      setSelectedErrorType(null);
      setSelectedErrorData(null);
    }

    const scansData = useSelector(state => state.client.clientScans);
    if(!scansData) {
      return "";
    }
    let items = [];
    scansData?.data?.items.forEach(i => {
      items.push({
        id: i.id,
        label: i.label,
        bankName: i.bankName,
        createdAt: model.formatShortDate(i.createdAt),
        status: StringUtils.scanStatus(t, i.status),
        actions:
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
            <Tooltip title={t('view')}><IconButton color="secondary" onClick={() => {showDetails(i)}}><EyeTwoTone twoToneColor={theme.palette.secondary.main} /></IconButton></Tooltip>
            {canManageScans && 
            <Tooltip title={t('delete')}><IconButton color="error" onClick={(e) => showDelete(e, i)}><DeleteTwoTone twoToneColor={theme.palette.error.main} /></IconButton></Tooltip>
            }
            </Stack>
      });
    });
    const sortBy = { id: 'createdAt', desc: true };

    const buttonArea = function() {
      return(<>
        <Stack direction={'row'} alignItems="center" spacing={1}>
          {canManageScans && 
          <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAdd}>
              {t('add_scan')}
          </Button>
          }
        </Stack>
      </>);
    }

    return(
      <>
        <ScrollX>
          <ReactTable
            columns={columns}
            data={items}
            page={filter.page}
            pageSize={filter.limit}
            sortBy={sortBy}
            search={''}
            buttonArea={buttonArea}
            rowCountTotal={scansData?.data?.numOfItems} 
            getHeaderProps={(column) => column.getSortByToggleProps()}
            handlePageChange={handlePageChange} 
            handlePageSizeChange={handlePageSizeChange}
            handleSort={handleSort}
            handleSearch={handleSearch}
            topSpacing={0} />
        </ScrollX>
        <Dialog maxWidth="sm" fullWidth onClose={handleDeleteCancel} open={del} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          {del && <DeleteScan clientId={clientId} selectedScan={selectedScan} onCancel={handleDeleteCancel} />}
        </Dialog>
        <Dialog maxWidth="lg" fullWidth onClose={handleScanDetailsCancel} open={openDetails} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          {openDetails && <ScanDetails model={model} clientId={clientId} selectedScan={selectedScan} onCancel={handleScanDetailsCancel} onShowErrors={handleShowScanErrors} />}
        </Dialog>
        <Dialog maxWidth="sm" fullWidth onClose={handleAddCancel} open={add} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          {add && <AddScan model={model} customer={null} clientId={clientId} banks={scansData?.data?.scannableBanks} onCancel={handleAddCancel} onSaved={handleAddSaved} />}
        </Dialog>
        <Dialog maxWidth="sm" fullWidth onClose={handleScanDataDetailsCancel} open={openDataDetails} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          {openDataDetails && <ScanDataDetails model={model} clientId={clientId} data={selectedScanData} showLoader={showScanDataLoader} onCancel={handleScanDataDetailsCancel} />}
        </Dialog>
        <Dialog maxWidth="lg" fullWidth onClose={handleShowScanErrorsCancel} open={openDataDetailsErrors} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          {openDataDetailsErrors && <ScanErrorList model={model} clientId={clientId} data={selectedErrorData} type={selectedErrorType} />}
        </Dialog>
      </>
    );
}