// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  KeyOutlined,
  DotChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  KeyOutlined,
  DotChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const main = {
  id: 'main_menu',
  title: <FormattedMessage id="main_menu" />,
  type: 'group',
  children: [
    {
        id: 'home',
        title: <FormattedMessage id="home" />,
        type: 'item',
        url: '/dashboard',
        icon: icons.ChromeOutlined
    },
    {
      id: 'settings',
      title: <FormattedMessage id="settings" />,
      type: 'item',
      url: '/settings',
      icon: icons.GatewayOutlined
    },
    {
      id: 'access',
      title: <FormattedMessage id="access" />,
      type: 'item',
      url: '/access',
      icon: icons.KeyOutlined
    }
  ]
};

export default main;
