import { generateClientAccessTokenRequest, getClientAccountsRequest, getClientAssessmentHistoryRequest, getClientCommunicationsRequest, getClientDataRequest, getClientDetailsRequest, getClientEventsRequest, getClientScanDataDetailsRequest, getClientScanDetailsRequest, getClientScansRequest, getClientSessionLogsRequest, getClientSessionsRequest, getClientTransactionsRequest, requestAssessmentDataRequest } from "store/reducers/client";
import { format, parseISO } from "date-fns";
import TableViewModel from "model/viewmodel/TableViewModel";
import PaginationData from "model/dto/PaginationData";
import { Chip } from "@mui/material";
import { getBankListRequest } from "store/reducers/dashboard";

class ClientDetailsViewModel extends TableViewModel {

    static numberFormat = new Intl.NumberFormat('hu-HU', {
    maximumFractionDigits: 0
    });

    static loadFromStore(state) {
        return state.client.selectedClientData;
    }

    constructor(data, userModel) {
        super(userModel);
        this.clientDetailsData = data;
        this.periodTotals = null;
        this.commonDebits = null;
        this.commonCredits = null;
        this.combinedTop10Debits = null;
        this.filteredDebits = null;
        this.combinedTop10Credits = null;
        this.filteredCredits = null;
        this.navTransactions = null;
        this.badNavTransactions = null;
        this.numberFormat = new Intl.NumberFormat('hu-HU', {
            maximumFractionDigits: 0
        });
    }

    fetchDetails(dispatcher, clientId) {
        //if(!this.clientDetailsData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now()};
        return dispatcher(getClientDetailsRequest(request));
        //}
    }

    fetchClientData(dispatcher, clientId, periodType, assessmentId) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), period: periodType, assessmentId: assessmentId};
        return dispatcher(getClientDataRequest(request));
    }

    fetchClientTransactions(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientTransactionsRequest(request));
    }

    fetchClientScans(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientScansRequest(request));
    }

    fetchClientScanDataDetails(dispatcher, clientId, scanId, dataId) {
        const request = {token: this.user?.token, clientId: clientId, dataId: dataId, timestamp: Date.now(), scanId: scanId};
        return dispatcher(getClientScanDataDetailsRequest(request));
    }

    fetchClientScanDetails(dispatcher, clientId, scanId) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), scanId: scanId};
        return dispatcher(getClientScanDetailsRequest(request));
    }

    fetchClientAssessmentHistory(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientAssessmentHistoryRequest(request));
    }

    fetchClientEvents(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientEventsRequest(request));
    }

    fetchClientCommunications(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientCommunicationsRequest(request));
    }

    fetchClientSessions(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientSessionsRequest(request));
    }

    fetchClientSessionLogs(dispatcher, clientId, sessionId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, sessionId: sessionId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientSessionLogsRequest(request));
    }

    fetchClientAccounts(dispatcher, clientId, paginationData) {
        const request = {token: this.user?.token, clientId: clientId, timestamp: Date.now(), paginationData};
        return dispatcher(getClientAccountsRequest(request));
    }

    fetchClientAccessToken(dispatcher, clientId, sendEmail, sendSms) {
        const request = {token: this.user?.token, clientId: clientId, sendEmail: sendEmail, sendSms: sendSms, timestamp: Date.now()};
        return dispatcher(generateClientAccessTokenRequest(request));
    }

    fetchBankList(dispatcher) {
        const request = {token: this.user?.token, timestamp: Date.now()};
        return dispatcher(getBankListRequest(request));
    }

    sendCreateAssessmentRequest(dispatcher, values) {
        return dispatcher(requestAssessmentDataRequest(values));
    }

    setClientData(data) {
        this.clientDataData = data;
    }

    getDetailsData() {
        return this.clientDetailsData;
    }

    getDataData() {
        return this.clientDataData;
    }

    getNetTotal() {
        if(!this.clientDataData) return 0;
        return Math.ceil(Math.abs(this.clientDataData?.totals?.statistics?.totalCreditAmount) - Math.abs(this.clientDataData?.totals?.statistics?.totalDebitAmount));
    }

    getTotalCount() {
        if(!this.clientDataData) return 0;
        let total = 0;
        this.clientDataData?.trancheData?.forEach((i, j) => {
            if(i.type == "GLOBAL") return;
            total += i.statistics.creditCount;
            total += i.statistics.debitCount;
        });
        return total;
    }

    getPeriod() {
        let data = {from: "-", to: "-"};
        data.from = this.formatShortDate(this.clientDataData?.periodFrom);
        data.to = this.formatShortDate(this.clientDataData?.periodTo);
        return data;
    }

    getPeriodTotals() {
        if(this.periodTotals) {
            return this.periodTotals;
        }
        this.periodTotals = [];
        this.clientDataData?.trancheData?.forEach((i, j) => {
            if(i.type == "GLOBAL") return;
            let total = Math.ceil(Math.abs(i.statistics.totalCreditAmount) - Math.abs(i.statistics.totalDebitAmount));
            this.periodTotals.push({
                index: j, 
                from: i.from, 
                to: i.to, 
                netTotal: total,
                creditCount: i.statistics.creditCount, 
                debitCount: i.statistics.debitCount,
                creditAmount: Math.abs(i.statistics.totalCreditAmount),
                debitAmount: Math.abs(i.statistics.totalDebitAmount)
            });
        });
        return this.periodTotals;
    }

    getCommonCredits() {
        if(this.commonCredits) {
            return this.commonCredits;
        }
        this.commonCredits = [];
        this.clientDataData?.commonCredits?.forEach((i, j) => {
            this.commonCredits.push({
                index: j,
                name: i.psd2Name,
                count: i.count,
                sum: Math.abs(i.sum)
            });
        });
        return this.commonCredits;
    }

    getCommonDebits() {
        if(this.commonDebits) {
            return this.commonDebits;
        }
        this.commonDebits = [];
        this.clientDataData?.commonDebits?.forEach((i, j) => {
            this.commonDebits.push({
                index: j,
                name: i.psd2Name,
                count: i.count,
                sum: Math.abs(i.sum)
            });
        });
        return this.commonDebits;
    }

    getCombinedTop10Debits() {
        if(this.combinedTop10Debits) {
            return this.combinedTop10Debits;
        }
        this.combinedTop10Debits = [];
        this.clientDataData?.trancheData?.forEach(i => {
            i.top10DebitTransactions.forEach(j => {
                this.combinedTop10Debits.push(j);
            });
        });
        this.combinedTop10Debits = this.combinedTop10Debits.sort((a, b) => {
            return -1*(Math.abs(a.amount) - Math.abs(b.amount));
        });
        this.combinedTop10Debits = this.combinedTop10Debits.slice(0, 10);
        return this.combinedTop10Debits;
    }

    getFilteredDebits() {
        if(this.filteredDebits) {
            return this.filteredDebits;
        }
        this.filteredDebits = [];
        this.clientDataData?.filteredDebitTransactions?.forEach(i => {
            this.filteredDebits.push(i);
        });
        this.filteredDebits = this.filteredDebits.sort((a, b) => {
            return -1*(Math.abs(a.amount) - Math.abs(b.amount));
        });
        this.filteredDebits = this.filteredDebits.slice(0, 50);
        return this.filteredDebits;
    }

    getCombinedTop10Credits() {
        if(this.combinedTop10Credits) {
            return this.combinedTop10Credits;
        }
        this.combinedTop10Credits = [];
        this.clientDataData?.trancheData?.forEach(i => {
            i.top10CreditTransactions.forEach(j => {
                this.combinedTop10Credits.push(j);
            });
        });
        this.combinedTop10Credits = this.combinedTop10Credits.sort((a, b) => {
            return -1*(Math.abs(a.amount) - Math.abs(b.amount));
        });
        //this.combinedTop10Credits = this.combinedTop10Credits.slice(0, 10);
        return this.combinedTop10Credits;
    }

    getFilteredCredits() {
        if(this.filteredCredits) {
            return this.filteredCredits;
        }
        this.filteredCredits = [];
        this.clientDataData?.filteredCreditTransactions?.forEach(i => {
            this.filteredCredits.push(i);
        });
        this.filteredCredits = this.filteredCredits.sort((a, b) => {
            return -1*(Math.abs(a.amount) - Math.abs(b.amount));
        });
        //this.filteredCredits = this.filteredCredits.slice(0, 50);
        return this.filteredCredits;
    }

    getNavTransactions() {
        if(this.navTransactions) {
            return this.navTransactions;
        }
        this.navTransactions = [];
        this.clientDataData?.navTransactions?.forEach(i => {
            this.navTransactions.push(i);
        });
        this.navTransactions = this.navTransactions.sort((a, b) => {
            return -1*(Math.abs(a.amount) - Math.abs(b.amount));
        });
        return this.navTransactions;
    }

    getBadNavTransactions() {
        if(this.badNavTransactions) {
            return this.badNavTransactions;
        }
        this.badNavTransactions = [];
        this.clientDataData?.badNavTransactions?.forEach(i => {
            this.badNavTransactions.push(i);
        });
        this.badNavTransactions = this.badNavTransactions.sort((a, b) => {
            return -1*(Math.abs(a.amount) - Math.abs(b.amount));
        });
        return this.badNavTransactions;
    }

    getChartHeaders(section, type, periodType) {
        let chartHeaders = [];
        let j = 1;
        this.clientDataData?.trancheData.forEach(i => {
            if(i.type == "GLOBAL") {
                chartHeaders.push("Avg");
            }
            else if(periodType == "MONTHLY") {
                chartHeaders.push(format(parseISO(i.from), "MMM"));
            }
            else if(periodType == "DAY30") {
                chartHeaders.push(format(parseISO(i.from), "MM/dd")+" - "+format(parseISO(i.to), "MM/dd"));
            }
            else if(periodType == "WEEKLY") {
                chartHeaders.push(format(parseISO(i.from), "MM/dd")+" - "+format(parseISO(i.to), "MM/dd"));
            }
            else {
                chartHeaders.push(j);
            }
            j++;
        });
        return chartHeaders;
    }

    getChartCurrency(section, type) {
        const trancheData = this.clientDataData?.trancheData;
        if(!trancheData) {
            return "";
        }
        if(!trancheData[0].currency) {
            return "";
        }
        
        return trancheData[0].currency;
    }

    getChartData(section, type) {
        let chartData = null;
        if(this.clientDataData) {
            chartData = [];
        }
        this.clientDataData?.trancheData.forEach(i => {
            switch(section) {
                default:
                case "inflow":
                    switch(type) {
                        default: 
                        case "total": chartData.push(this.formatValue(Math.abs(i.statistics.totalCreditAmount), type)); break;
                        case "count": chartData.push(this.formatValue(i.statistics.creditCount, type)); break;
                        case "average": chartData.push(this.formatValue(Math.abs(i.statistics.averageCreditAmount), type)); break;
                        case "median": chartData.push(this.formatValue(Math.abs(i.statistics.medianCreditAmount), type)); break;
                    }
                break;
                case "outflow":
                    switch(type) {
                        default: 
                        case "total": chartData.push(this.formatValue(Math.abs(i.statistics.totalDebitAmount), type)); break;
                        case "count": chartData.push(this.formatValue(i.statistics.debitCount, type)); break;
                        case "average": chartData.push(this.formatValue(Math.abs(i.statistics.averageDebitAmount), type)); break;
                        case "median": chartData.push(this.formatValue(Math.abs(i.statistics.medianDebitAmount), type)); break;
                    }
                break;
            }
        });
        return chartData;
    }

    formatValue(val, type) {
        if(!val) {
            return 0;
        }
        if(val == -1) {
            return 0;
        }
        return val;
    }

    getEducationLevel(t, level) {
        if(!t || !level) {
            return "-";
        }
        return t('education_level_'+level.toLowerCase());
    }

    getFamilyStatus(t, status) {
        if(!t || !status) {
            return "-";
        }
        return t('family_status_'+status.toLowerCase());
    }

    getDefaultFilter(defaultPageLength = 10) {
        return new PaginationData(0, defaultPageLength, "desc", "transactionDate", null);
    }

    getFieldFilter(defaultPageLength = 10, field) {
        return new PaginationData(0, defaultPageLength, "desc", field, null);
    }

    getFieldAndDirFilter(defaultPageLength = 10, field, dir) {
        return new PaginationData(0, defaultPageLength, dir, field, null);
    }

}

export default ClientDetailsViewModel;