import PropTypes from 'prop-types';
import { useEffect, useMemo, useState, Fragment, useCallback } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination, useAsyncDebounce } from 'react-table';

// project import
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';
import { HeaderSort, IndeterminateCheckbox, SortingSelect, TablePagination, TableRowSelection } from 'components/third-party/ReactTable';

// assets
import { CloseOutlined, PlusOutlined, EyeTwoTone, EditTwoTone, DeleteTwoTone, SearchOutlined } from '@ant-design/icons';
import CustomTableSearchComponent from 'components/statosfera/table/CustomTableSearchComponent';
import { useTranslation } from 'react-i18next';

function ReactTable({ columns, data, sortBy: sortByState, search, page: pageIndexControl, pageSize: pageSizeControl, rowCountTotal, getHeaderProps, renderRowSubComponent, handleAdd, handlePageChange, handlePageSizeChange, handleSort, handleSearch, topSpacing = 3, buttonArea, hiddenColumns = [] }) {
    const theme = useTheme();
    const [t, i18n] = useTranslation();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  
    const filterTypes = useMemo(() => renderFilterTypes, []);
    
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setHiddenColumns,
      allColumns,
      visibleColumns,
      // @ts-ignore
      // eslint-disable-next-line no-unused-vars
      rows,
      // @ts-ignore
      page,
      // @ts-ignore
      gotoPage,
      // @ts-ignore
      setPageSize,
      // @ts-ignore
      state: { globalFilter, selectedRowIds, pageIndex, pageSize, sortBy },
      // @ts-ignore
      preGlobalFilteredRows,
      // @ts-ignore
      setGlobalFilter,
      // @ts-ignore
      setSortBy
    } = useTable(
      {
        columns,
        data,
        // @ts-ignore
        filterTypes,
        // @ts-ignore
        initialState: { pageIndex: 0, pageSize: pageSizeControl, hiddenColumns: hiddenColumns, sortBy: [sortByState] },
        manualPagination: true,
        pageCount: pageIndexControl,
        manualSortBy: true
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect
    );

    const handlePageChangeInternal = (val) => {
      handlePageChange?.(val);
    }

    const handlePageSizeChangeInternal = (val) => {
      handlePageSizeChange?.(val);
    }

    const handleSortChangeInternal = (id, desc) => {
      handleSort?.(id, desc);
    }

    useEffect(() => {
      if(!sortBy || sortBy.length == 0) return;
      handleSortChangeInternal(sortBy[0].id, sortBy[0].desc);
    }, [sortBy]);
  
    /*useEffect(() => {
      if (matchDownMD) {
        setHiddenColumns(['phone', 'createdS']);
      } else {
        setHiddenColumns([]);
      }
      // eslint-disable-next-line
    }, [matchDownMD]);*/
    return (
      <>
        <TableRowSelection selected={Object.keys(selectedRowIds).length} />
        <Stack spacing={1}>
          <Stack
            direction={matchDownSM ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{ p: topSpacing, pb: 0 }}
          >
            {handleSearch && 
                <CustomTableSearchComponent title={t('search')} handleSearch={handleSearch} />
            }
            {buttonArea && 
              buttonArea()
            }
            {handleAdd && 
                <>
                <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
                <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleAdd}>
                    Add Client
                </Button>
                </Stack>
            </>
            }
            </Stack>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <TableCell key={index} {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}>
                      <HeaderSort column={column} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            {page && page.length == 0 && 
            <TableBody {...getTableBodyProps()}>
              <TableRow>
                <TableCell colSpan={columns.length}>{t('no_data')}</TableCell>
              </TableRow>
            </TableBody>
            }
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
  
                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}

                      sx={{
                        cursor: 'pointer',
                        bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit'
                      }}
                    >
                      {row.cells.map((cell, index) => (
                        <TableCell key={index} {...cell.getCellProps([{ className: cell.column.className }])}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </Fragment>
                );
              })}
              <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                <TableCell sx={{ p: 2, py: 3 }} colSpan={10}>
                  <TablePagination gotoPage={handlePageChangeInternal} rowCountTotal={rowCountTotal} setPageSize={handlePageSizeChangeInternal} pageSize={pageSizeControl} pageIndex={pageIndexControl} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </>
    );
  }

  ReactTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    getHeaderProps: PropTypes.func,
    handleAdd: PropTypes.func,
    renderRowSubComponent: PropTypes.any
  };

  export default ReactTable;