import { CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
import { Stack, Typography, useTheme } from "@mui/material";
import MainCard from "components/MainCard";
import { useTranslation } from "react-i18next";

export default({model, chartData}) => {
    const theme = useTheme();
    const [t, i18n] = useTranslation();
    let fraudData = chartData?.data?.data?.fraudData;
    if(!fraudData) {
        return "";
    }
    let hasAccountMatch = fraudData?.hasOneMatchedAccount;
    let background = '';
    let desc = t('account_holder_name_check_desc_unknown');
    if(hasAccountMatch === true) {
        desc = t('account_holder_name_check_desc');
        background = "#EDFFEF";
    }
    else if(hasAccountMatch === false) {
        desc = t('account_holder_name_check_desc_nomatch');
        background = "#FDF4F4";
    }
    return(
        <MainCard title={t('account_holder_name_check_title')} style={{background: background}}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                {hasAccountMatch === true && 
                <CheckCircleTwoTone twoToneColor={theme.palette.success.main} style={{ fontSize: '30px'}} />
                }
                {hasAccountMatch === false &&
                <CloseCircleTwoTone twoToneColor={theme.palette.error.main} style={{ fontSize: '30px'}} />
                }
                {hasAccountMatch === null &&
                <QuestionCircleTwoTone twoToneColor={theme.palette.warning.main} style={{ fontSize: '30px'}} />
                }
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>{desc}</Typography>
                
            </Stack>
        </MainCard>
    )
}